<template>
    <div class="codics-admin-product-body">
        <div class="codics-admin-product-control">
            <el-button class="codics-admin-product-control-search" type="success" icon="el-icon-plus"
                @click="addProductDialogVisibility = true;">添加商品</el-button>
            <!-- 添加表单 -->
            <el-dialog title="添加商品" :visible.sync="addProductDialogVisibility" width="80%" :before-close="closeAdd">
                <el-form :model="form">
                    <el-form-item label="商品名称" :label-width="formLabelWidth">
                        <el-input v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品介绍" :label-width="formLabelWidth">
                        <el-input v-model="form.detail" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品封面" :label-width="formLabelWidth">
                        <el-upload action="files.kylebing.cn" multiple list-type="picture-card"
                            :on-preview="handlePictureCardPreview" :http-request="uploadFile" :on-remove="handleRemove"
                            ref="fmUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="介绍图片" :label-width="formLabelWidth">
                        <el-upload action="files.kylebing.cn" multiple list-type="picture-card"
                            :on-preview="handlePictureCardPreview2" :http-request="uploadFile2"
                            :on-remove="handleRemove2" ref="jsUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="商品品牌" :label-width="formLabelWidth">
                        <el-cascader :options="productBrandList" v-model="form.brand" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="商品现价" :label-width="formLabelWidth">
                        <el-input v-model="form.price" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品原价" :label-width="formLabelWidth">
                        <el-input v-model="form.oprice" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品数量" :label-width="formLabelWidth">
                        <el-input-number v-model="form.inventory" :min="1" :max="9999" label="描述文字"></el-input-number>
                    </el-form-item>
                    <el-form-item label="商品分类" :label-width="formLabelWidth">
                        <el-cascader :options="productTypeList" v-model="form.type" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="商品状态" :label-width="formLabelWidth">
                        <el-select v-model="form.state" placeholder="请选择商品状态" value-key="value">
                            <el-option
                                v-for="(item, index) in [{ value: 0, label: '上架' }, { value: 1, label: '下架' }, { value: 2, label: '冻结' }, { value: 3, label: '封禁' }]"
                                :key="index" :label="item.label" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addProductDialogVisibility = false;">取 消</el-button>
                    <el-button type="primary" @click="addProductFuc();">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 更新表单 -->
            <el-dialog title="添加商品" :visible.sync="updateProductDialogVisibility" width="80%" :before-close="closeAdd">
                <el-form :model="updateForm">
                    <el-form-item label="商品名称" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品介绍" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.detail" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品封面" :label-width="formLabelWidth">
                        <el-upload action="files.kylebing.cn" multiple list-type="picture-card"
                            :on-preview="handlePictureCardPreview" :http-request="uploadFile" :on-remove="handleRemove"
                            ref="fmUpload2">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="介绍图片" :label-width="formLabelWidth">
                        <el-upload action="files.kylebing.cn" multiple list-type="picture-card"
                            :on-preview="handlePictureCardPreview2" :http-request="uploadFile2"
                            :on-remove="handleRemove2" ref="jsUpload2">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="商品品牌" :label-width="formLabelWidth">
                        <el-cascader :options="productBrandList" v-model="updateForm.brand" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="商品现价" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.price" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品原价" :label-width="formLabelWidth">
                        <el-input v-model="updateForm.oprice" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品分类" :label-width="formLabelWidth">
                        <el-cascader :options="productTypeList" v-model="updateForm.type" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="商品状态" :label-width="formLabelWidth">
                        <el-select v-model="updateForm.state" placeholder="请选择商品状态" value-key="value">
                            <el-option
                                v-for="(item, index) in [{ value: 0, label: '上架' }, { value: 1, label: '下架' }, { value: 2, label: '冻结' }, { value: 3, label: '封禁' }]"
                                :key="index" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="ceshi">ceshi</el-button>
                    <el-button @click="addProductDialogVisibility = false;">取 消</el-button>
                    <el-button type="primary" @click="addProductFuc();">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-admin-product-control-input" style="width: 260px !important;"
                placeholder="请输入要搜索的内容..." v-model="searchTxt" clearable @input="searchChange">
                <el-button
                    @click="searchPage = 1; searchMax = 0; searchData = []; nowSearchTxt = searchTxt; getProductListData(searchPage, searchSize, searchTxt, true);"
                    slot="append" icon="el-icon-search" style="padding: 0 25px 0 25px !important;"></el-button>
            </el-input>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="delProductsFuc">
                <el-button class="codics-admin-product-control-search" slot="reference" type="danger"
                    icon="el-icon-delete">批量删除</el-button>
            </el-popconfirm>
        </div>
        <el-table stripe class="codics-admin-product-table" :data="searchTxt == '' ? tableData : searchData" border
            style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="name" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="detail" label="介绍" width="180">
            </el-table-column>
            <el-table-column prop="img" label="封面" width="180">
                <el-carousel slot-scope="scope" height="100px" width="150px" indicator-position="none">
                    <el-carousel-item v-for="(item, index) in scope.row.img.split(',')" :key="index">
                        <el-image style="width: 100%; height: 100%" :src="'https://cdn.codics.cn/' + item" fit="cover"
                            :preview-src-list="scope.row.img.split(',').map(item2 => 'https://cdn.codics.cn/' + item2)"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </el-table-column>
            <el-table-column prop="itimg" label="详情" width="180">
                <el-carousel slot-scope="scope" height="100px" width="150px" indicator-position="none">
                    <el-carousel-item v-for="(item, index) in scope.row.itimg.split(',')" :key="index">
                        <el-image style="width: 100%; height: 100%" :src="'https://cdn.codics.cn/' + item" fit="cover"
                            :preview-src-list="scope.row.itimg.split(',').map(item2 => 'https://cdn.codics.cn/' + item2)"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </el-table-column>
            <el-table-column prop="brand" label="品牌" width="70">
            </el-table-column>
            <el-table-column prop="price" label="价格" width="70">
            </el-table-column>
            <el-table-column prop="oprice" label="原价" width="70">
            </el-table-column>
            <el-table-column prop="inventory" label="库存" width="80">
            </el-table-column>
            <el-table-column prop="type" label="类型" width="80">
            </el-table-column>
            <!-- <el-table-column prop="sid" label="商户" width="80">
            </el-table-column> -->
            <el-table-column prop="state" label="状态" width="80"
                :filters="[{ text: '上架', value: 0 }, { text: '下架', value: 1 }, { text: '冻结', value: 2 }, { text: '封禁', value: 3 }]"
                :filter-method="filterTag" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.state == 0 ? 'success' : (scope.row.state == 1 ? 'warning' : (scope.row.state == 2 ? 'info' : 'danger'))"
                        disable-transitions>{{ scope.row.state == 0 ? '上架' : (scope.row.state == 1 ? '下架' :
                    (scope.row.state == 2 ? '冻结' : '封禁')) }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button size="mini" @click="showAddI(scope.$index, scope.row)"
                        style="margin-right: 10px;">补货</el-button>
                    <!-- <el-button size="mini"
                        @click="updateProductDialogVisibility = true; updateForm = scope.row; editProduct(scope.row)"
                        style="margin-right: 10px;">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delProductsFuc([scope.row.id])">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-product-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
            layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
        <el-dialog title="添加库存" :visible.sync="addInventoryVisible">
            <el-input-number v-model="addForm.count" :min="1" :max="10000" label="补货数量"
                style="margin-bottom: 15px;"></el-input-number>
            <el-input type="textarea" :rows="2" placeholder="请输入补货的备注信息" v-model="addForm.remark">
            </el-input>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addInventoryVisible = false">取 消</el-button>
                <el-button type="primary" @click="addInventoryVisible = false; addInventoryFuc();">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getProductList2, getQiNiuToken, getProductTypeList, getProductBrandList, addProduct, delProducts, addInventory, searchUars } from '@/api';
import * as qiniu from 'qiniu-js';
import Sortable from 'sortablejs';
export default {
    data() {
        return {
            searchTxt: '',
            nowSearchTxt: '',
            addProductDialogVisibility: false,
            updateProductDialogVisibility: false,
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            form: {
                "name": "",
                "detail": "",
                "img": "",
                "itimg": "",
                "brand": [],
                "price": 99.99,
                "oprice": 99.99,
                "inventory": 1,
                "type": [],
                "sid": 0,
                "state": { value: 0, label: '上架' },
                "createby": 0,
                "createtime": "2024-02-28T07:01:17.000+00:00",
                "updateby": 0,
                "updatetime": "2024-02-28T07:01:17.000+00:00",
                "like": 0,
                "view": 0,
                "sell": 0,
                "follow": false
            },
            updateForm: {

            },
            qiNiuInfo: {
                token: '',
                fmList: [],
                jsList: []
            },
            formLabelWidth: '120px',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            productType: '',
            productTypes: [
                {
                    value: '选项1',
                    label: '黄金糕'
                },
                {
                    value: '选项2',
                    label: '双皮奶'
                },
                {
                    value: '选项3',
                    label: '蚵仔煎'
                },
                {
                    value: '选项4',
                    label: '龙须面'
                },
                {
                    value: '选项5',
                    label: '北京烤鸭'
                }
            ],
            productState: '',
            productStates: [
                {
                    value: '选项1',
                    label: '黄金糕'
                },
                {
                    value: '选项2',
                    label: '双皮奶'
                },
                {
                    value: '选项3',
                    label: '蚵仔煎'
                },
                {
                    value: '选项4',
                    label: '龙须面'
                },
                {
                    value: '选项5',
                    label: '北京烤鸭'
                }
            ],
            productTypeList: [],
            productBrandList: [],
            selectData: [],
            fmList: [],
            jsList: [],
            addInventoryVisible: false,
            nowIndex: null,
            nowRow: null,
            addForm: {
                count: 0,
                remark: ''
            }
        }
    },
    mounted() {
        this.$emit('check-mem');
        this.getProductListData(1, this.dataSize);
        this.getTokenFuc();
        this.getProductTypesData();
        this.getProductBrandsData();
    },
    methods: {
        ceshi() {
            this.$refs['fmUpload2'].uploadFiles.push({
                name: '',
                percentage: '',
                raw: '',
                size: '',
                status: '',
                uid: new Date().getTime(),
                url: 'https://cp-static.wangcaio2o.com/Public/Image/cp_index/banner_02/2024-01/ele_02.png'
            })
        },
        async getProductTypesData() {

            let data = await getProductTypeList();

            if (data.code == 200) {

                this.productTypeList = data.data;

            } else {

                this.$message.error(data.message);

            }

        },
        async getProductBrandsData() {

            let data = await getProductBrandList();

            if (data.code == 200) {

                this.productBrandList = data.data;

            } else {

                this.$message.error(data.message);

            }

        },
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        async getProductListData(pge, psz, key, state) {

            if (state && key == '') {

                this.$message({
                    showClose: true,
                    message: '请先输入要搜索的内容！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await getProductList2({ 'pge': this.searchTxt == '' ? this.currentPage : this.searchPage, 'psz': this.searchTxt == '' ? this.dataSize : this.searchSize, 'key': this.nowSearchTxt });

            loading.close();

            if (data.code == 200) {

                if (this.searchTxt == '') {

                    this.tableData = data.data;

                    this.maxSize = data.count;

                } else {

                    this.searchData = data.data;

                    this.searchMax = data.count;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.nowSearchTxt == '') {
                this.dataSize = val;
            } else {
                this.searchSize = val;
            }
            this.getProductListData();
        },
        handleCurrentChange(val) {
            if (this.nowSearchTxt == '') {
                this.currentPage = val;
            } else {
                this.searchPage = val;
            }
            this.getProductListData();
        },
        // 上传方法
        uploadFile(uploadInfo) {

            this.initDragSort();

            this.fmList[this.fmList.length] = 'huayuan/img/' + uploadInfo.file.name.replace(',', '');

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.qiNiuInfo.fmList[_that.qiNiuInfo.fmList.length] = res.key;
                }
            }
            // console.log(uploadInfo.file);
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name.replace(',', ''), this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            subscription.unsubscribe() // 上传取消
        },
        // 图片删除
        handleRemove(file, fileList) {
            // console.log("123123123:",file, fileList);
            this.fmList = this.fmList.filter(item => item.indexOf(file.name.replace(',', '')) == -1);

        },
        // 图片点击预览
        handlePictureCardPreview(file) {

        },
        // 上传方法
        uploadFile2(uploadInfo) {

            this.initDragSort2();

            this.jsList[this.jsList.length] = 'huayuan/img/' + uploadInfo.file.name.replace(',', '');

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.qiNiuInfo.jsList[_that.qiNiuInfo.jsList.length] = res.key;
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        // 图片删除
        handleRemove2(file, fileList) {
            // console.log("123123123:",file, fileList);

            this.fmList = this.fmList.filter(item => item.indexOf(file.name.replace(',', '')) == -1);
        },
        // 图片点击预览
        handlePictureCardPreview2(file) {

        },
        filterTag(v, r, c) {

            return r.state === v;

        },
        closeAdd(done) {
            let t = ['1', '2', '3'];
            let str = t.join(",");
            console.log(str.split(','));
            this.$refs['fmUpload'].clearFiles();
            this.$refs['jsUpload'].clearFiles();
            this.qiNiuInfo.fmList = [];
            this.qiNiuInfo.jsList = [];
            this.fmList = [];
            done();
        },
        async addProductFuc() {

            this.form.img = this.fmList.join(",");

            this.form.itimg = this.jsList.join(",");

            this.form.brand = this.form.brand.length == 0 ? 0 : this.form.brand[this.form.brand.length - 1];

            this.form.type = this.form.type.length == 0 ? 0 : this.form.type[this.form.type.length - 1];

            this.form.state = this.form.state.value;

            console.log(this.form);

            let data = await addProduct(this.form);

            if (data.code == 200) {

                this.$message({
                    message: '添加商品成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        async delProductsFuc(t) {
            if (t == null) {
                if (this.selectData.length == 0) {
                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });
                    return true;
                }
                let t = [];
                this.selectData.forEach(item => {
                    t[t.length] = item.id;
                });
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await delProducts({ ids: t });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        initDragSort() {
            // 支持拖拽排序
            const el = this.$refs.fmUpload.$el.querySelectorAll('.el-upload-list')[0];
            Sortable.create(el, {
                onEnd: ({ oldIndex, newIndex }) => {
                    // 交换位置
                    const arr = this.fmList;
                    const page = arr[oldIndex];
                    arr.splice(oldIndex, 1);
                    arr.splice(newIndex, 0, page);
                }
            });
        },
        initDragSort2() {
            // 支持拖拽排序
            const el = this.$refs.jsUpload.$el.querySelectorAll('.el-upload-list')[0];
            Sortable.create(el, {
                onEnd: ({ oldIndex, newIndex }) => {
                    // 交换位置
                    const arr = this.jsList;
                    const page = arr[oldIndex];
                    arr.splice(oldIndex, 1);
                    arr.splice(newIndex, 0, page);
                }
            });
        },
        showAddI(idx, row) {

            this.nowIndex = idx;

            this.nowRow = row;

            this.addForm.id = row.id;

            this.addInventoryVisible = true;

        },
        async addInventoryFuc() {
            if (this.addForm.id == null) {

                this.$message.error("请选中商品再进行该操作！");

                return;

            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await addInventory(this.addForm);
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: "商品库存补充成功！",
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }

        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
                this.nowSearchTxt = '';
            }
        },
        editProduct(row) {



        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-product-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-product-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-admin-product-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-product-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-product-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-product-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>