<template>
    <div class="codics-firm-staff-body">
        <div class="codics-firm-staff-control">
            <el-button class="codics-firm-staff-control-search" type="success" icon="el-icon-plus"
                @click="addProductDialogVisibility = true;">绑定员工</el-button>
            <!-- 添加企业员工 -->
            <el-dialog title="添加企业员工" :visible.sync="addProductDialogVisibility" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="员工账号" :label-width="formLabelWidth">
                        <el-select v-model="addForm.uid" clearable filterable remote reserve-keyword
                            placeholder="请输入账号/邮箱/手机号..." :remote-method="searchUsersFuc" :loading="userLoading">
                            <el-option v-for="item in usersOptions" :key="item.id" :label="item.account"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="对应角色" :label-width="formLabelWidth">
                        <el-select v-model="addForm.rid" clearable filterable remote reserve-keyword
                            placeholder="请输入角色名称/备注..." :remote-method="searchRolesFuc" :loading="roleLoading">
                            <el-option v-for="item in rolesOptions" :key="item.id" :label="item.role" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addProductDialogVisibility = false;">取 消</el-button>
                    <el-button type="primary" @click="bindUarFuc">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 修改企业员工 -->
            <el-dialog :title="'修改企业员工：' + nowStaff" :visible.sync="updateVisibility" width="80%">
                <el-form :model="updateForm">
                    <el-form-item label="对应角色" :label-width="formLabelWidth">
                        <el-select v-model="updateForm.rid" clearable filterable remote reserve-keyword
                            placeholder="请输入角色名称/备注..." :remote-method="searchRolesFuc" :loading="roleLoading">
                            <el-option v-for="item in rolesOptions" :key="item.id" :label="item.role" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="updateVisibility = false;">取 消</el-button>
                    <el-button type="primary" @click="updateUarFuc">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-firm-staff-control-input" style="width: 260px !important;"
                placeholder="请输入要搜索的内容..." v-model="searchTxt" clearable @input="searchChange">
                <el-button slot="append" icon="el-icon-search"
                    @click="searchPage = 1; searchMax = 0; searchData = []; nowSearchTxt = searchTxt; listUarsFuc(true);"></el-button>
            </el-input>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteUarsFuc();">
                <el-button class="codics-firm-staff-control-search" slot="reference" type="danger"
                    icon="el-icon-delete">批量删除</el-button>
            </el-popconfirm>
        </div>
        <el-table stripe class="codics-firm-staff-table" :data="searchTxt == '' ? tableData : searchData" border
            style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" :selectable="handleSelect">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="account.nickname" label="账号" width="180">
            </el-table-column>
            <el-table-column prop="role.role" label="角色" width="180">
                <template slot-scope="scope">
                    {{ scope.row.rid == -1 ? '超级管理员' : scope.row.role.role }}
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button v-show="scope.row.rid != -1" size="mini"
                        @click="nowStaff = scope.row.account.account; updateForm.uid = scope.row.uid; updateForm.rid = scope.row.rid; rolesOptions = [scope.row.role], updateVisibility = true;"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm v-show="scope.row.rid != -1" title="这是一段内容确定删除吗？"
                        @confirm="deleteUarsFuc([scope.row.id])">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-firm-staff-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
            layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
    </div>
</template>

<script>
import { searchUsers, searchRoles2, bindUar, searchUars, deleteUars, updateUar } from '@/api';
export default {
    data() {
        return {
            addProductDialogVisibility: false,
            updateVisibility: false,
            addForm: {
                uid: null,
                rid: null
            },
            updateForm: {
                uid: null,
                rid: null
            },
            searchTxt: '',
            formLabelWidth: '120px',
            searchType: '名称',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            userLoading: false,
            roleLoading: false,
            rolesOptions: [],
            searchTxt: '',
            usersOptions: [],
            selectData: [],
            nowStaff: '',
            nowSearchTxt: ''
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.listUarsFuc();

    },
    methods: {
        async listUarsFuc(state) {

            if (state && this.searchTxt == '') {

                this.$message({
                    showClose: true,
                    message: '搜索内容不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await searchUars({ key: this.nowSearchTxt, pge: this.searchTxt == '' ? this.currentPage : this.searchPage, psz: this.searchTxt == '' ? this.dataSize : this.searchSize });

            loading.close();

            if (data.code == 200) {

                if (this.searchTxt == '') {

                    this.tableData = data.data;

                    this.maxSize = data.count;

                } else {

                    this.searchData = data.data;

                    this.searchMax = data.count;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.searchTxt == '') {
                this.dataSize = val;
                this.listUarsFuc();
            } else {
                this.searchSize = val;
                this.listUarsFuc();
            }
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
                this.listUarsFuc();
            } else {
                this.searchPage = val;
                this.listUarsFuc();
            }
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async bindUarFuc() {

            if (this.addForm.uid == null || this.addForm.rid == null) {

                this.$notify({
                    title: '警告',
                    message: '员工及角色不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindUar(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '添加员工成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async searchUsersFuc(query) {
            if (query !== '') {
                this.userLoading = true;
                let data = await searchUsers({ key: query });
                this.userLoading = false;
                if (data.code == 200) {
                    this.usersOptions = data.data;
                } else {
                    this.$message.error(data.message);
                }
            } else {
                this.usersOptions = [];
            }
        },
        async searchRolesFuc(query) {
            if (query !== '') {
                this.roleLoading = true;
                let data = await searchRoles2({ key: query });
                this.roleLoading = false;
                if (data.code == 200) {
                    this.rolesOptions = data.data;
                } else {
                    this.$message.error(data.message);
                }
            } else {
                this.rolesOptions = [];
            }
        },
        async deleteUarsFuc(t) {

            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await deleteUars({ ids: t });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        searchChange(val) {

            if (val == '') {

                this.searchData = [];

                this.maxSearchSize = 0;

                this.nowSearchTxt = '';

            }

        },
        handleSelect(a) {
            return a.rid != -1;
        },
        async updateUarFuc() {

            if (this.updateForm.rid == null || this.updateForm.uid == null) {

                this.$notify({
                    title: '警告',
                    message: '员工及角色不能为空！',
                    type: 'warning'
                });

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await updateUar(this.updateForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '修改员工信息成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-firm-staff-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-firm-staff-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-firm-staff-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-firm-staff-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-firm-staff-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-firm-staff-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>