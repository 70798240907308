<template>
    <div class="codics-firm-log-body">
        <div class="codics-firm-log-control">
            <el-input class="codics-firm-log-control-input" style="width: 360px !important;"
                placeholder="请输入要员工信息/方法/参数进行搜索..." v-model="searchTxt" clearable @input="searchChange">
                <el-button
                    @click="searchPage = 1; searchMax = 0; searchData = []; nowSearchTxt = searchTxt; listLogsFuc();"
                    slot="append" icon="el-icon-search" style="padding: 0 25px 0 25px !important;"></el-button>
            </el-input>
            <!-- <el-button class="codics-firm-log-control-search" type="danger"
                icon="el-icon-plus">批量删除</el-button> -->
            <el-button class="codics-firm-log-control-search"
                style="float: right !important;margin-right: 0 !important;" icon="el-icon-download"
                @click="tableToExcel">导出数据</el-button>
        </div>
        <el-table stripe class="codics-firm-log-table" :data="searchTxt == '' ? logList : searchData" border
            style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="主键" width="80" style="user-select: none;">
            </el-table-column>
            <el-table-column prop="label" label="员工账号" width="180">
                <template slot-scope="scope">
                    <el-image style="height: 30px;width: 30px;position: relative;float: left;"
                        :src="scope.row?.account?.himg == null ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : scope.row?.account?.himg"
                        fit="fill">
                    </el-image>
                    <div style="margin-top: 5px;position: relative;float: left;margin-left: 5px;">{{
                    scope.row?.account?.nickname }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="operation" label="员工操作" width="220">
            </el-table-column>
            <el-table-column prop="method" label="调用方法" width="180">
            </el-table-column>
            <el-table-column prop="params" label="方法参数" width="104">
                <template slot-scope="scope">
                    <el-popover placement="right" width="200" style="height: fit-content;" trigger="click">
                        <tbody class="codics-log-params-info">
                            <tr>
                                <td>属性名</td>
                                <td>属性值</td>
                            </tr>
                            <tr v-for="(item, index) in paramsData" :key="index">
                                <td>{{ item.key }}</td>
                                <td>{{ item.value }}</td>
                            </tr>
                        </tbody>
                        <el-button slot="reference" @click="handleParams(scope.row.params)" type="mini">查看参数</el-button>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="ip" label="IP地址" width="230">
            </el-table-column>
            <el-table-column align="right" prop="createtime" label="操作时间" min-width="250">
            </el-table-column>
        </el-table>
        <el-pagination class="codics-firm-log-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
            layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
    </div>
</template>

<script>
import { listLogs } from '@/api';
import { exportExcel } from '@/utils/myutils';
export default {
    data() {
        return {
            searchTxt: '',
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            formLabelWidth: '120px',
            searchTxt: '',
            nowSearchTxt: '',
            currentPage: 1,
            searchPage: 1,
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            logList: [],
            paramsData: [],
            selectData: []
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.listLogsFuc();

    },
    methods: {
        async listLogsFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listLogs({ key: this.nowSearchTxt, pge: this.currentPage, psz: this.dataSize });

            loading.close();

            if (data.code == 200) {

                if (this.nowSearchTxt == '') {

                    this.logList = data.data;

                    this.maxSize = data.count;

                } else {

                    this.searchData = data.data;

                    this.searchMax = data.count;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.listLogsFuc();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.listLogsFuc();
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductBrandFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '品牌名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductBrand(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: '添加商品品牌成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        hasChildren(list) {

            for (var i = 0; i < list.length; i++) {

                if (list[i]?.children != null) {

                    // list[i].hasChildren = true;

                    this.hasChildren(list[i].children);

                } else {

                    // delete list[i].children

                }

            }

        },
        handleParams(data) {
            let json = JSON.parse(data);
            let t = [];
            for (let item in json) {

                t.push({
                    key: item,
                    value: json[item]
                });

            }

            this.paramsData = t;
        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
                this.nowSearchTxt = '';
                this.changePassState(this.passState);
            }
        },
        tableToExcel() {
            if (this.selectData.length == 0) {
                this.$message({
                    message: '请至少选中一条数据再进行该操作！',
                    type: 'warning'
                });
                return true;
            }
            const now = new Date();
            const year = now.getFullYear(); // 获取当前年份
            const month = now.getMonth() + 1; // 获取当前月份，注意getMonth()返回的是0-11，所以需要加1
            const day = now.getDate(); // 获取当前日期
            let title = ['序号', '员工操作', '调用方法', "方法参数", "IP地址", "操作时间", '操作账号', '操作昵称'];
            let filter = ['sid', 'uid', 'account.id', 'account.sex', 'account.state', 'account.vip', 'account.member'];  //不导出的字段
            exportExcel(this.selectData, '操作日志_' + year + month + day, title, filter);
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-firm-log-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-firm-log-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 后台管理页面主页控制栏 */
.codics-firm-log-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-firm-log-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-firm-log-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-firm-log-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-log-params-info table {
    line-height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.codics-log-params-info table,
.codics-log-params-info td,
.codics-log-params-info th {
    border-collapse: collapse;
    border-spacing: 10px;
    border: 1px solid #DDD;
    min-width: 45px;
}

.codics-log-params-info td {
    padding-left: 10px;
    width: 100px !important;
    height: 30px !important;
}

.codics-log-params-info table tr:nth-child(even) {
    background: #f8f8f8;
}
</style>