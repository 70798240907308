<template>
    <div class="codics-admin-sys-body">
        <div class="codics-admin-sys-himg">
            
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
/* 个人中心页面体 */
.codics-admin-sys-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}
</style>