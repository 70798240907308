<template>
    <div class="codics-txt-to-qr-body">
        <div class="codics-txt-to-qr-title">二维码转换</div>
        <div class="codics-txt-to-qr-content">
            <textarea class="codics-txt-to-qr-textarea" placeholder="请输入二维码要包含的文本或者链接..." cols="30" rows="6"
                v-model="qrcodeTxt"></textarea>
            <div class="codics-txt-to-qr-img-box">
                <el-image :src="qrcodeData" fit="fill"></el-image>
            </div>
            <el-button class="codics-txt-to-qr-btn codics-txt-to-qr-upload" type="success" icon="el-icon-upload2"
                plain>上传识别</el-button>
            <el-button class="codics-txt-to-qr-btn codics-txt-to-qr-create" type="primary" icon="el-icon-magic-stick"
                plain>生成二维码</el-button>
            <el-button class="codics-txt-to-qr-btn codics-txt-to-qr-copy" type="warning" plain
                icon="el-icon-document-copy" @click="copyTxtFuc(qrcodeTxt)">复制文本</el-button>
        </div>
    </div>
</template>

<script>
import { copyTxt } from '@/utils/myutils';
export default {
    data() {
        return {
            qrcodeTxt: '',
            qrcodeData: 'https://cdn.codics.cn/huayuan/img/%E5%9B%BE%E7%89%87%E5%8A%A0%E8%BD%BD%E5%A4%B1%E8%B4%A5.svg'
        }
    },
    methods: {
        copyTxtFuc(txt) {
            this.$message({
                message: '文本复制成功！',
                type: 'success'
            });
            copyTxt(txt);
        }
    }
}
</script>

<style>
.codics-txt-to-qr-body {
    min-height: calc(100% - 120px);
    height: fit-content;
    width: 100%;
    max-width: 1450px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
}

.codics-txt-to-qr-title {
    height: 30px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 30px;
    font-size: 14px;
    padding: 0 0 0 15px;
    border-left: 1px solid #E6A23C;
    margin-top: 15px;
    margin-bottom: 15px;
}

.codics-txt-to-qr-content {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

.codics-txt-to-qr-textarea {
    height: fit-content;
    width: calc(100% - 260px);
    position: relative;
    float: left;
    border: 1px dashed #d9d9d9 !important;
    outline: none !important;
    background: none !important;
    resize: none !important;
    line-height: 40px;
    font-size: 13px;
    box-sizing: border-box;
    padding: 0 15px 0 15px;
}

.codics-txt-to-qr-btn {
    position: absolute;
    bottom: 10px;
    right: 270px;
    opacity: 0;
    transition: .4s;
}

.codics-txt-to-qr-content:hover .codics-txt-to-qr-btn {
    opacity: 1;
}

.codics-txt-to-qr-create {
    right: 395px;
}

.codics-txt-to-qr-upload {
    right: 534px;
}

.codics-txt-to-qr-textarea:focus {
    border: 1px dashed rgb(230, 162, 60) !important;
}

.codics-txt-to-qr-img-box {
    height: 242px;
    width: 242px;
    position: relative;
    float: left;
    border: 1px dashed #d9d9d9 !important;
    box-sizing: border-box;
    padding: 70px;
    margin-left: 18px;
    cursor: pointer;
}

.codics-txt-to-qr-img-box::after {
    content: '此处预览二维码';
    height: 50px;
    width: 1005;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 13px;
    color: #333;
    text-align: center;
    line-height: 50px;
}

.codics-txt-to-qr-img-box:hover {
    border: 1px dashed rgb(230, 162, 60) !important;
}

@media (max-width:768px) {
    .codics-txt-to-qr-textarea {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .codics-txt-to-qr-btn {
        position: relative;
        float: left;
        right: 0 !important;
        opacity: 1 !important;
        width: 100% !important;
        margin-left: 0 !important;
        margin-top: 15px !important;
    }
}
</style>