<template>
    <div class="codics-admin-lottery-body">
        <div class="codics-admin-lottery-control">
            <el-button class="codics-admin-lottery-control-search" type="success" icon="el-icon-plus"
                @click="addLotteryDialog = true; getTokenFuc();">添加</el-button>
            <!-- 添加表单 -->
            <el-dialog title="添加抽奖" :visible.sync="addLotteryDialog" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="对应商品" :label-width="formLabelWidth">
                        <el-select v-model="addSelect" filterable remote reserve-keyword placeholder="请输入关键词"
                            :remote-method="queryProductData" :loading="shopLoad" @change="selectChange"
                            @clear="clearSelect" clearable>
                            <el-option v-for="item in productOptions" :key="item.id" :label="item.name" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="奖品名称" :label-width="formLabelWidth">
                        <el-input v-model="addForm.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="奖品图片" :label-width="formLabelWidth">
                        <el-upload list-type="picture-card" :on-preview="handlePictureCardPreview"
                            :http-request="uploadFile" :on-remove="handleRemove" ref="fmUpload">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="奖品概率" :label-width="formLabelWidth">
                        <el-input-number v-model="addForm.odds" :min="0" :max="10000000"
                            label="设置中奖概率"></el-input-number>
                    </el-form-item>
                    <el-form-item label="奖品数量" :label-width="formLabelWidth">
                        <el-input-number :disabled="addSelect == ''" v-model="addForm.count" :min="0"
                            :max="addSelect.inventory" label="设置中奖概率"></el-input-number>
                    </el-form-item>
                    <el-form-item label="奖品状态" :label-width="formLabelWidth">
                        <el-select v-model="addForm.state" placeholder="请选择">
                            <el-option label="上架" value='0'></el-option>
                            <el-option label="下架" value='1'></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addProductDialogVisibility = false">取 消</el-button>
                    <el-button type="primary" @click="addLotteryFuc">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-admin-lottery-control-input" :placeholder="'请输入' + searchBrand" v-model="searchTxt"
                clearable>
                <el-select style="width: 40px !important;" v-model="searchBrand" slot="prepend" placeholder="">
                    <el-option label="ID" value="ID"></el-option>
                    <el-option label="名称" value="名称"></el-option>
                    <el-option label="详情" value="详情"></el-option>
                    <el-option label="厂商" value="厂商"></el-option>
                </el-select>
            </el-input>
            <el-button class="codics-admin-lottery-control-search" type="warning" icon="el-icon-search">搜索</el-button>
            <el-button class="codics-admin-lottery-control-search" type="danger" icon="el-icon-plus">删除</el-button>
            <div
                style="height: 40px;width: fit-content;position: relative;float: left;text-align: center;font-size: 14px;">
                <a href="https://katihuo.com/#/shop/lottery" style="line-height: 40px;"
                    target="_blank">抽奖地址：https://katihuo.com/shop/lottery</a></div>
        </div>
        <el-table stripe class="codics-admin-lottery-table" :data="lotteryData" border style="width: 100%">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="name" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="tag" label="状态" width="70"
                :filters="[{ text: '上架', value: 0 }, { text: '下架', value: 1 }]" :filter-method="filterTag"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state === 0 ? 'success' : 'danger'" disable-transitions>{{
                    scope.row.state == 0 ? '上架' : '下架' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="img" label="图片" width="60">
                <template slot-scope="scope">
                    <el-image style="top:3.5px"
                        :src="scope.row.img == null ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : ('https://cdn.codics.cn/' + scope.row.img)"
                        fit="fill">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm title="这是一段内容确定删除吗？">
                        <el-button size="mini" type="danger" slot="reference"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-lottery-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
            :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="maxSize">
        </el-pagination>
    </div>
</template>

<script>
import * as qiniu from 'qiniu-js';
import { listLottery, addLottery, queryProduct, getQiNiuToken } from '@/api';
export default {
    data() {
        return {
            searchTxt: '',
            addLotteryDialog: false,
            addForm: {
                pid: '',
                name: '',
                img: '',
                odds: 0,
                count: 0,
                state: '0'
            },
            qiNiuInfo: {
                token: ''
            },
            formLabelWidth: '120px',
            searchBrand: '名称',
            currentPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            lotteryData: [],
            shopLoad: false,
            productOptions: [],
            addSelect: '',
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.getLotteryData();

    },
    methods: {
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        async getLotteryData() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listLottery({ pge: this.currentPage, psz: this.dataSize });

            loading.close();

            if (data.code == 200) {

                this.lotteryData = data.data;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getUserListData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserListData(val, this.dataSize);
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async queryProductData(query) {

            if (query !== '') {
                this.shopLoad = true;
                let data = await queryProduct({ key: query });
                this.shopLoad = false;
                if (data.code == 200) {

                    this.$message({
                        message: '查询商品成功！',
                        type: 'success'
                    });

                    this.productOptions = data.data;

                } else {

                    this.$message.error(data.message);

                    this.productOptions = [];

                }
            } else {
                this.productOptions = [];
            }

        },
        selectChange(key) {
            this.addForm.pid = key.id;
            this.addForm.name = key.name;
        },
        // 图片点击预览
        handlePictureCardPreview(file) {

        },
        // 上传方法
        uploadFile(uploadInfo) {

            this.addForm.img = 'huayuan/img/' + uploadInfo.file.name.replace(',', '');

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                },
                error(err) {
                    // console.log(err)
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.addForm.img = res.key;
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        // 图片删除
        handleRemove(file, fileList) {
            // console.log("123123123:",file, fileList);

            this.fmList = this.fmList.filter(item => item.indexOf(file.name.replace(',', '')) == -1);
        },
        clearSelect() {
            this.addForm.pid = '';
            this.addForm.count = 0;
        },
        async addLotteryFuc() {
            if (this.addForm.pid == '' || this.addForm.name == '' || this.addForm.img == '') {

                this.$message({
                    showClose: true,
                    message: '请先选中合适的奖品并上传名称及图片！',
                    type: 'warning'
                });

                return true;

            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await addLottery(this.addForm);
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '数据添加成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            } else {
                this.$message.error(data.message);
            }
        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-lottery-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-lottery-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-admin-lottery-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-lottery-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-lottery-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-lottery-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>