import { pdfInfo } from "@/api";
import { jumpTo } from "./myutils";

export default function getFileFromUrl(url, fileName) {
    return new Promise((resolve, reject) => {
        var blob = null;
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader('Accept', 'image/png');
        xhr.responseType = "blob";
        // 加载时处理
        xhr.onload = () => {
            // 获取返回结果
            blob = xhr.response;
            let file = new File([blob], fileName, { type: 'image/png' });
            // 返回结果
            resolve(file);
        };
        xhr.onerror = (e) => {
            reject(e)
        };
        // 发送
        xhr.send();
    });
}

function loadJs(href, b) {
    const link = document.createElement('script');
    if (!b) {
        link.src = href;
    } else {
        link.innerHTML = href;
    }
    document.body.appendChild(link);
}

function renderImg(_this, pdfFile, pageNumber, canvasContext, canvas) {
    pdfFile.getPage(pageNumber).then(function (page) { //逐页解析PDF
        // console.log(page)
        var viewport = page.getViewport(_this.scaleNum); // 页面缩放比例
        var newcanvas = canvasContext.canvas;
        //设置canvas真实宽高
        newcanvas.width = page._pageInfo.view[2]//viewport.width;
        newcanvas.height = page._pageInfo.view[3]//viewport.height;
        window.pdfWidth = page._pageInfo.view[2];
        window.pdfHeight = page._pageInfo.view[3];
        // console.log(window.pdfWidth,window.pdfHeight)
        canvasContext.translate(newcanvas.width / 2, newcanvas.height / 2);
        // canvasContext.rotate(Math.PI);
        canvasContext.scale(1, -1);
        canvasContext.translate(-newcanvas.width / 2, -newcanvas.height / 2);
        //设置canvas在浏览中宽高
        newcanvas.style.width = "100%";
        newcanvas.style.height = "100%";
        var renderContext = {
            canvasContext: canvasContext,
            viewport: viewport
        };
        page.render(renderContext).promise.then(function () {
            canvasContext.translate(newcanvas.width / 2, newcanvas.height / 2);
            // canvasContext.rotate(Math.PI);
            canvasContext.scale(1, -1);
            canvasContext.translate(-newcanvas.width / 2, -newcanvas.height / 2);
            // 添加水印
            imgWatermark(canvas, "华远创智-卡提货")
            _this.loadProgress += 1 / _this.totalPages * 10;
            _this.pdfData[pageNumber - 1] = canvas.toDataURL("image/png", 1.0);
            // _this.pdfData[_this.pdfData.length] = ;
            _this.nowPages++;
            if (_this.nowPages == _this.totalPages) {
                _this.loadProgress = 100;
                _this.loadState = false;
                window.pdfData = _this.pdfData;
                // const link = document.createElement('iframe');
                // link.src = './book.html';
                // link.height = '100%';
                // link.width = '100%';
                // link.style = 'overflow:hidden;border: none;';
                // let iframe = document.getElementById('pdfIframe');
                // iframe.appendChild(link);

                document.head.innerHTML += `<link rel="stylesheet" class='pdf-view-yichu' href="../../css/style.css" />
                <link rel="stylesheet" class='pdf-view-yichu' href="../../css/player.css" />
                <link rel="stylesheet" class='pdf-view-yichu' href="../../css/phoneTemplate.css" />
                <link rel="stylesheet" class='pdf-view-yichu' href="../../css/template.css" />`;

                // loadJs('./js/jquery-1.9.1.min.js');
                // window.$ = window.jQuery = $;
                loadJs('../../js/config.js');
                loadJs('../../js/bookmark_config.js');
                loadJs('../../js/LoadingJS.js');
                loadJs('../../js/main.js');
                loadJs('var sendvisitinfo = function (type, page) { };', true);
            }
        });; //渲染生成
    });
    return;
}

function imgWatermark(canvas, watermarkText) {
    return new Promise((resolve, reject) => {
        let ctx = canvas.getContext('2d')
        ctx.fillStyle = 'rgb(0.08, 0.08, 0.2)'
        ctx.globalAlpha = 0.3
        ctx.font = '14px microsoft yahei'
        ctx.rotate(Math.PI / 180 * -15)
        let lineNumX = 0 // X轴行号
        let lineNumY = 0 // Y轴行号
        let tempX = 0
        let targetX = 0 // 水印写入的X轴位置
        let targetY = 0 // 水印写入的Y轴位置
        for (let ix = 20; ix < canvas.width; ix += 180) { // 水印横向间隔
            lineNumX++
            lineNumY = 0
            for (let iy = 10; iy <= canvas.height; iy += 110) { // 水印纵向间隔
                lineNumY++
                tempX = lineNumY * 110 * Math.sin(Math.PI / 180 * 15) // 由于canvas被旋转，所以需要计算偏移量
                targetX = lineNumY & 1 ? ix - tempX : ix - tempX + 60
                targetY = iy + lineNumX * 180 * Math.tan(Math.PI / 180 * 15)
                ctx.fillText(watermarkText, targetX, targetY)
            }
        }
        ctx.rotate(Math.PI / 180 * 15)
        canvas.toBlob(blob => resolve(blob))

    })
}

function saveFile(file) {

    var reader = new FileReader();
    // 文件读取成功完成后的处理
    reader.onload = function (event) {
        // 创建一个下载链接元素
        var link = document.createElement('a');
        link.href = event.target.result;
        link.download = file.name; // 下载文件名
        // 模拟点击触发下载
        link.click();
    };
    // 以DataURL的形式读取文件内容
    reader.readAsDataURL(file);

}

function getFileNameWithoutExtension(filename) {
    // 正则表达式匹配文件名，不包括后缀
    const match = filename.split('.')
    return match ? match[0] : filename;
}

export const doThat = async (_this) => {

    _this.loadState = true;

    _this.loadProgress = 1;

    let shujuT = [];

    let data = await pdfInfo(_this.$route.params.id, {});

    // console.log(data.data.name)

    _this.pdfInfo = data.data;

    window.pdfName = data.data.name;

    _this.$route.meta.metaInfo.title = getFileNameWithoutExtension(data.data.name);
    // 设置新的标题
    document.title = _this.$route.meta.metaInfo.title;

    if (data.code == 200) {

        _this.loadProgress = 10;

        getFileFromUrl("https://cdn.codics.cn/" + data.data.url, "a.pdf").then((fileData) => {

            var fileSize = fileData.size;

            var mb;

            if (fileSize) {// 限制上传文件尺寸

                mb = fileSize / 1048576;
                // if(mb > 10) {
                //   alert("文件大小不能>10M");
                //   return;
                // }
            }

            _this.loadProgress = 25;

            var reader = new FileReader();

            reader.readAsDataURL(fileData); //将文件读取为 DataURL

            reader.onload = function (e) { //文件读取成功完成时触发

                _this.loadProgress = 30;

                pdfjsLib.getDocument(reader.result).promise.then(function (pdf) { //调用pdf.js获取文件

                    _this.loadProgress = 35;

                    if (pdf) {

                        _this.totalPages = pdf.numPages;
                        // $("#currentPages").text("1/");
                        // $("#totalPages").text(totalPages);
                        // var imgShow = document.getElementById("imgShow");
                        //遍历动态创建canvas
                        for (var i = 1; i <= _this.totalPages; i++) {

                            var canvas = document.createElement('canvas');

                            canvas.id = "pageNum" + i;

                            var context = canvas.getContext('2d');

                            // imgShow.appendChild(canvas);

                            renderImg(_this, pdf, i, context, canvas);

                        }

                    }

                });

            };

        }).catch((e) => {
            // console.error(e)
        });

    } else {

        jumpTo(_this, "网站首页", {}, {});

    }

}

