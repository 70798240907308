<template>
    <div class="codics-admin-warehouse-body">
        <div class="codics-admin-warehouse-control-bar">
            <el-date-picker class="codics-admin-warehouse-date-select" style="margin-right: 15px;" v-model="timeData"
                type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" :picker-options="pickerOptions" value-format="timestamp">
            </el-date-picker>
            <el-cascader ref="proPick" class="codics-admin-warehouse-pro-select" style="margin-right: 15px;"
                :options="proOptions" :props="{ multiple: true }" collapse-tags clearable></el-cascader>
            <el-button class="codics-admin-warehouse-search" icon="el-icon-search" type="warning" plain
                @click="wareListFuc">搜索</el-button>
            <el-button class="codics-admin-warehouse-reload" icon="el-icon-refresh" type="info" plain>刷新</el-button>
            <el-button class="codics-admin-warehouse-download" style="float: right !important;" icon="el-icon-download"
                plain @click="exportData">导出数据</el-button>
        </div>
        <el-table ref="customerReportDateTable" class="codics-admin-warehouse-table" :data="tableData" border
            height="calc(100% - 55px)" show-summary style="width: 100%; margin-top: 20px;">
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="did" label="订单号" width="210">
            </el-table-column>
            <el-table-column prop="type" label="业务类型" width="80">
            </el-table-column>
            <el-table-column prop="sid" label="商品" width="100">
            </el-table-column>
            <el-table-column prop="sname" label="商品名称">
            </el-table-column>
            <el-table-column prop="num" label="商品数量">
            </el-table-column>
            <el-table-column prop="unit" label="商品单价">
            </el-table-column>
            <el-table-column prop="total" label="商品总价">
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { wareList, proList } from '@/api';
import { exportExcel,timestampToDate } from '@/utils/myutils';
export default {
    data() {
        return {
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
            },
            proOptions: [],
            tableData: [],
            timeData: []
        }
    },
    mounted() {
        this.proListFuc();
    },
    updated() {

        this.$nextTick(() => {

            this.$refs.customerReportDateTable.doLayout();

        });

    },
    methods: {
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总价';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] += ' 元';
                } else {
                    sums[index] = 'N/A';
                }
            });

            return sums;
        },
        async proListFuc() {
            let data = await proList();
            if (data.code == 200) {
                this.proOptions = [data.data];
            } else {
                this.$message.error(data.message);
            }

        },
        async wareListFuc() {
            let t = [];
            if (this.$refs.proPick.$data.checkedValue != null) {
                this.$refs.proPick.$data.checkedValue.forEach(item => {
                    t[t.length] = item[item.length - 1];
                });
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await wareList({ from: this.timeData==null ? null : this.timeData[0], to: this.timeData==null ? null : this.timeData[1], sid: t });
            loading.close();
            if (data.code == 200) {
                this.tableData = data.data;
            } else {
                this.$message.error(data.message);
            }
        },
        exportData(){
            if(this.tableData.length==0){

                this.$message({
                    message: '请先查询数据再进行该操作！',
                    type: 'warning'
                });
                return true;

            }
            let title = ['ID', '订单号', '业务类型', "商品", "商品名称", "商品数量","商品单价","商品总价","创建时间","修改时间"];
            let filter = ['createby','updateby'];  //不导出的字段
            exportExcel(this.tableData, '入库明细数据_' + ((this.timeData==null || this.timeData.length==0 ) ? "全部" : (timestampToDate(this.timeData[0]) + "_" + timestampToDate(this.timeData[1]))), title, filter);

        }
    }
}
</script>

<style>
/* 入库页面体 */
.codics-admin-warehouse-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 入库控制栏 */
.codics-admin-warehouse-control-bar {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 15px;
}

.codics-admin-warehouse-pro-select .el-tag {
    max-width: 70% !important;
    cursor: pointer !important;
}

@media (max-width:768px) {
    .codics-admin-warehouse-date-select {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .codics-admin-warehouse-pro-select {
        width: calc(70% - 15px) !important;
        margin-bottom: 15px;
    }

    .codics-admin-warehouse-search {
        width: 30% !important;
    }

    .codics-admin-warehouse-download,
    .codics-admin-warehouse-reload {
        margin-left: unset !important;
        width: calc(50% - 7.5px) !important;
    }

    .el-date-range-picker .el-picker-panel__body {
        min-width: 100%;
    }

    .el-date-range-picker__content {
        width: 100% !important;
    }

    .el-date-range-picker {
        width: 80% !important;
    }

    .el-date-range-picker__content {
        margin: 0px;
        padding: 5px;
    }

    .el-date-range-picker__content.is-left {
        padding-bottom: 0px;
    }

    .el-date-range-picker__content.is-right {
        padding-top: 0px;
    }

    .el-date-table th {
        padding: 0px;
    }

    .el-date-table td {
        padding: 0px;
    }

    .el-picker-panel__body {
        min-width: unset !important;
        margin-left: unset !important;
    }

    .el-picker-panel__sidebar {
        display: none !important;
    }

    .codics-admin-warehouse-table {
        height: calc(100% - 170px) !important;
    }
}
</style>