// 生成随机字符串的函数
export const randomString = (len) => {
    len = len || 32;
    var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    var maxPos = $chars.length;
    var pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}
// 跳转页面
export const jumpTo = (t, y, p, q) => {
    t.$router.push({ name: y, params: p, query: q }, () => { }, () => { });
}

export const currentDate = (fmt) => {
    var date = fmt != null ? fmt : new Date();
    var year = date.getFullYear(); //月份从0~11，所以加一
    let month = date.getMonth();
    // console.log("month",month);
    var dateArr = [
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ];
    //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
    for (var i = 0; i < dateArr.length; i++) {
        if (dateArr[i] >= 1 && dateArr[i] <= 9) {
            dateArr[i] = "0" + dateArr[i];
        }
    }
    var strDate =
        year +
        "/" +
        dateArr[0] +
        "/" +
        dateArr[1] +
        " " +
        dateArr[2] +
        ":" +
        dateArr[3] +
        ":" +
        dateArr[4];
    //此处可以拿外部的变量接收  strDate:2022-05-01 13:25:30
    //this.date = strDate;
    return strDate;
}

export const dateDiff = (date1, date2) => {
    const diff = date2.getTime() - date1.getTime();
    const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
    const days = diff / oneDay;

    // 计算小时数
    const hours = (diff % oneDay) / (60 * 60 * 1000);
    // 计算分钟数
    const minutes = (diff % (60 * 60 * 1000)) / (60 * 1000);
    // 计算秒数
    const seconds = (diff % (60 * 1000)) / 1000;

    return Math.floor(days) + '天' + Math.floor(hours) + '小时' + Math.floor(minutes) + '分钟' + Math.floor(seconds) + '秒';

}

export const exportExcel = (JSONData, FileName, title, filter) => {
    if (!JSONData) return;
    //转化json为object
    var arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    console.log(arrData);
    var excel = "<table>";
    //设置表头
    var row = "<tr style='height:50px;'>";
    if (title) { //使用标题项
        for (var i in title) {
            row += "<th align='center'>" + title[i] + "</th>";
        }
    } else {//不使用标题项
        for (var i in arrData[0]) {
            row += "<th align='center'>" + i + "</th>";
        }
    }
    excel += row + "</tr>";
    for (var i = 0; i < arrData.length; i++) {
        var row = "<tr style='height:50px;'>";
        row += processData(arrData[i], filter, '');
        //设置数据
        excel += row + "</tr>";
    }
    excel += "</table>";
    var excelFile =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>";
    excelFile +=
        '<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
    excelFile +=
        '<meta http-equiv="content-type" content="application/vnd.ms-excel';
    excelFile += '; charset=UTF-8">';
    excelFile += "<head>";
    excelFile += "<!--[if gte mso 9]>";
    excelFile += "<xml>";
    excelFile += "<x:ExcelWorkbook>";
    excelFile += "<x:ExcelWorksheets>";
    excelFile += "<x:ExcelWorksheet>";
    excelFile += "<x:Name>";
    excelFile += "数据表";
    excelFile += "</x:Name>";
    excelFile += "<x:WorksheetOptions>";
    excelFile += "<x:DisplayGridlines/>";
    excelFile += "</x:WorksheetOptions>";
    excelFile += "</x:ExcelWorksheet>";
    excelFile += "</x:ExcelWorksheets>";
    excelFile += "</x:ExcelWorkbook>";
    excelFile += "</xml>";
    excelFile += "<![endif]-->";
    excelFile += "</head>";
    excelFile += "<body>";
    excelFile += excel;
    excelFile += "</body>";
    excelFile += "</html>";
    var uri =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(excelFile);
    var link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = FileName + ".xls";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function processData(t, f, p) {//t:表数据，f过滤器，p：父键
    let d = '';
    //设置数据
    for (var index in t) {
        //判断是否有过滤行
        if (f) {
            if (f.indexOf(p == '' ? index : (p + '.' + index)) == -1) {
                if (typeof t[index] != 'object' || t[index] == null) {
                    var value = t[index] == null ? "" : t[index];
                    d += (value.toString().indexOf('data:image/png') == -1 ? ("<td align='center' style=\"mso-number-format:'\@';\">" + value + "</td>") : ("<td align='center' style='height:100px;width:100px;'><img src='" + value + "'></img></td>"));
                } else {
                    d += processData(t[index], f, p == '' ? index : (p + '.' + index));
                }
            }
        } else {
            if (typeof t[index] != 'object' || t[index] == null) {
                var value = t[index] == null ? "" : t[index];
                d += (value.toString().indexOf('data:image/png') == -1 ? ("<td align='center' style=\"mso-number-format:'\@';\">" + value + "</td>") : ("<td align='center' style='height:100px;width:100px;'><img src='" + value + "'></img></td>"));
            } else {
                d += processData(t[index], f, p == '' ? index : (p + '.' + index));
            }
        }
    }
    return d;
}

export const timestampToDate = (timestamp, s) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const hour = ("0" + date.getHours()).slice(-2);
    const minute = ("0" + date.getMinutes()).slice(-2);
    const second = ("0" + date.getSeconds()).slice(-2);
    return year + "-" + month + "-" + day + (s ? (' ' + hour + ':' + minute + ':' + second) : '');
}

function getLocalFilePath(path) {
    if (path.indexOf('_www') === 0 || path.indexOf('_doc') === 0 || path.indexOf('_documents') === 0 || path.indexOf('_downloads') === 0) {
        return path
    }
    if (path.indexOf('file://') === 0) {
        return path
    }
    if (path.indexOf('/storage/emulated/0/') === 0) {
        return path
    }
    if (path.indexOf('/') === 0) {
        var localFilePath = plus.io.convertAbsoluteFileSystem(path)
        if (localFilePath !== path) {
            return localFilePath
        } else {
            path = path.substr(1)
        }
    }
    return '_www/' + path
}

function dataUrlToBase64(str) {
    var array = str.split(',')
    return array[array.length - 1]
}

var index = 0
function getNewFileId() {
    return Date.now() + String(index++)
}

function biggerThan(v1, v2) {
    var v1Array = v1.split('.')
    var v2Array = v2.split('.')
    var update = false
    for (var index = 0; index < v2Array.length; index++) {
        var diff = v1Array[index] - v2Array[index]
        if (diff !== 0) {
            update = diff > 0
            break
        }
    }
    return update
}

export const pathToBase64 = (path) => {
    return new Promise(function (resolve, reject) {
        if (typeof window === 'object' && 'document' in window) {
            if (typeof FileReader === 'function') {
                var xhr = new XMLHttpRequest()
                xhr.open('GET', path, true)
                xhr.responseType = 'blob'
                xhr.onload = function () {
                    if (this.status === 200) {
                        let fileReader = new FileReader()
                        fileReader.onload = function (e) {
                            resolve(e.target.result)
                        }
                        fileReader.onerror = reject
                        fileReader.readAsDataURL(this.response)
                    }
                }
                xhr.onerror = reject
                xhr.send()
                return
            }
            var canvas = document.createElement('canvas')
            var c2x = canvas.getContext('2d')
            var img = new Image
            img.onload = function () {
                canvas.width = img.width
                canvas.height = img.height
                c2x.drawImage(img, 0, 0)
                resolve(canvas.toDataURL())
                canvas.height = canvas.width = 0
            }
            img.onerror = reject
            img.src = path
            return
        }
        if (typeof plus === 'object') {
            plus.io.resolveLocalFileSystemURL(getLocalFilePath(path), function (entry) {
                entry.file(function (file) {
                    var fileReader = new plus.io.FileReader()
                    fileReader.onload = function (data) {
                        resolve(data.target.result)
                    }
                    fileReader.onerror = function (error) {
                        reject(error)
                    }
                    fileReader.readAsDataURL(file)
                }, function (error) {
                    reject(error)
                })
            }, function (error) {
                reject(error)
            })
            return
        }
        if (typeof wx === 'object' && wx.canIUse('getFileSystemManager')) {
            wx.getFileSystemManager().readFile({
                filePath: path,
                encoding: 'base64',
                success: function (res) {
                    resolve('data:image/png;base64,' + res.data)
                },
                fail: function (error) {
                    reject(error)
                }
            })
            return
        }
        reject(new Error('not support'))
    })
}

export function base64ToPath(base64) {
    return new Promise(function (resolve, reject) {
        if (typeof window === 'object' && 'document' in window) {
            base64 = base64.split(',')
            var type = base64[0].match(/:(.*?);/)[1]
            var str = atob(base64[1])
            var n = str.length
            var array = new Uint8Array(n)
            while (n--) {
                array[n] = str.charCodeAt(n)
            }
            return resolve((window.URL || window.webkitURL).createObjectURL(new Blob([array], { type: type })))
        }
        var extName = base64.split(',')[0].match(/data\:\S+\/(\S+);/)
        if (extName) {
            extName = extName[1]
        } else {
            reject(new Error('base64 error'))
        }
        var fileName = getNewFileId() + '.' + extName
        if (typeof plus === 'object') {
            var basePath = '_doc'
            var dirPath = 'uniapp_temp'
            var filePath = basePath + '/' + dirPath + '/' + fileName
            if (!biggerThan(plus.os.name === 'Android' ? '1.9.9.80627' : '1.9.9.80472', plus.runtime.innerVersion)) {
                plus.io.resolveLocalFileSystemURL(basePath, function (entry) {
                    entry.getDirectory(dirPath, {
                        create: true,
                        exclusive: false,
                    }, function (entry) {
                        entry.getFile(fileName, {
                            create: true,
                            exclusive: false,
                        }, function (entry) {
                            entry.createWriter(function (writer) {
                                writer.onwrite = function () {
                                    resolve(filePath)
                                }
                                writer.onerror = reject
                                writer.seek(0)
                                writer.writeAsBinary(dataUrlToBase64(base64))
                            }, reject)
                        }, reject)
                    }, reject)
                }, reject)
                return
            }
            var bitmap = new plus.nativeObj.Bitmap(fileName)
            bitmap.loadBase64Data(base64, function () {
                bitmap.save(filePath, {}, function () {
                    bitmap.clear()
                    resolve(filePath)
                }, function (error) {
                    bitmap.clear()
                    reject(error)
                })
            }, function (error) {
                bitmap.clear()
                reject(error)
            })
            return
        }
        if (typeof wx === 'object' && wx.canIUse('getFileSystemManager')) {
            var filePath = wx.env.USER_DATA_PATH + '/' + fileName
            wx.getFileSystemManager().writeFile({
                filePath: filePath,
                data: dataUrlToBase64(base64),
                encoding: 'base64',
                success: function () {
                    resolve(filePath)
                },
                fail: function (error) {
                    reject(error)
                }
            })
            return
        }
        reject(new Error('not support'))
    })
}


export const getRandomChineseCharacters = (count) => {
    const min = 0x4E00;
    const max = 0x9FA5;
    const chars = [];
    for (let i = 0; i < count; i++) {
        const randomCode = min + Math.floor(Math.random() * (max - min + 1));
        chars.push(String.fromCharCode(randomCode));
    }
    return chars.join('');
}

export const dateToTime = (date) => {
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
}

export const copyTxt = (txt) => {

    const input = document.createElement('input');

    input.value = txt;

    // 将输入框添加到页面，但不显示
    document.body.appendChild(input);

    input.select(); // 选择输入框的内容

    // 执行复制命令
    document.execCommand('copy');

    // 移除临时输入框
    document.body.removeChild(input);

}


export const isEmail = (email) => {
    var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
}

export const loadJs = (href, b) => {
    const link = document.createElement('script');
    if (!b) {
        link.src = href;
    } else {
        link.innerHTML = href;
    }
    document.body.appendChild(link);
    return;
}