<template>
    <div class="codics-shop-lottery-body">
        <el-image class="codics-shop-lottery-bimg"
            src="https://cdn.codics.cn/huayuan/img/yasuo_%E5%AD%A3%E5%BF%B5%E7%B4%A0%E6%9D%90-%2812%29.webp"
            fit="fill"></el-image>
        <el-image class="codics-shop-lottery-top" src="https://cdn.codics.cn/huayuan/img/yasuo_%E7%BB%84%209.webp"
            fit="fill"></el-image>
        <div class="codics-shop-lottery-tip">您还有{{ '1' }}次抽奖机会</div>
        <div class="codics-shop-lottery-content">
            <el-image lazy class="codics-shop-lottery-img"
                src="http://demo.sucaihuo.com/jquery/35/3586/demo/images/zhuanpan-bg.png" fit="fill"></el-image>
            <el-image lazy :src="imgData" class="codics-shop-lottery-zp"
                :style="{ 'transform': 'rotate(' + zpDeg + 'deg)' }" fit="fill"></el-image>
            <el-image lazy class="codics-shop-lottery-content-start"
                src="http://demo.sucaihuo.com/jquery/35/3586/demo/images/start-btn.png" fit="fill"
                @click="zpDeg += 1080 + (360 / cjData.length) * lotteryFuc() + (360 - zpDeg % 360);"></el-image>
            <canvas v-if="imgData == ''" class="codics-shop-lottery-zp" ref="zpViw" height="67vw" width="67%"></canvas>
        </div>
        <div class="codics-shop-lottery-button-box">
            <div class="codics-shop-lottery-button-card">
                <div class="codics-shop-lottery-button-viw"></div>
                <div class="codics-shop-lottery-button iconfont">&#xe7af; 兑换机会</div>
                <div class="codics-shop-lottery-button iconfont" style="margin-left: 10px;">&#xe7b2; 我的奖品</div>
                <div class="codics-shop-lottery-wdjp">抽奖记录</div>
            </div>
        </div>
        <div class="codics-shop-lottery-users-title">中奖用户名单</div>
        <div class="codics-shop-lottery-users-box">
            <div class="codics-shop-lottery-users-card">
                <ul>
                    <li v-for="item in 17" :key="item">{{ randomChar(1) }}**{{ randomChar(1) }}<div>2024-03-29 11:00:56
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="codics-shop-lottery-users-title">抽奖活动规则</div>
        <div class="codics-shop-lottery-users-box" style="height: fit-content !important;">
            <div class="codics-shop-lottery-users-card codics-shop-lottery-users-card2">
                1、活动期间，单笔投资金九盈产品大于等于1000元即可获得2次抽奖机会，每日每人最多可获得2次抽奖机会;当日未使用的抽奖机会，第二日作废;<br>2、如获得加息劵，加息券直接发放至“我的福利”;<br>3、如获得现金奖励，现金奖励以现金形式直接发放至您的财金圈“账户”中;<br>4、如获得实物礼品，活动结束后客服会主动与您联系并索取快递信息，由于实物礼品涉及采购时间，所以会在活动结束后20个工作日内寄出，实物礼品(颜色随机发放);
            </div>
        </div>
    </div>
</template>

<script>
import { pathToBase64, getRandomChineseCharacters } from '@/utils/myutils';
import { listLottery2 } from '@/api';
export default {
    data() {
        return {
            imgData: '',
            zpDeg: 0,
            cjData: [

            ]
        }
    },
    mounted() {

        this.listLotteryFuc();

    },
    methods: {
        async listLotteryFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listLottery2({ sid: 4 });

            loading.close();

            if (data.code == 200) {

                this.cjData = data.data;

                this.drawZp();

            } else {

                this.$message.error(data.message);

            }
        },
        setupCanvas(canvas) {
            var dpr = (window.devicePixelRatio || 1);
            var rect = canvas.getBoundingClientRect();
            canvas.width = rect.width * dpr;
            canvas.height = rect.height * dpr;
            canvas.style.width = rect.width + "px"
            canvas.style.height = rect.height + "px"
            return canvas;
        },
        async drawZp() {
            // cnavas画块
            let oCanvas = document.querySelector("canvas");
            // 2.从canvas中拿到绘图工具
            let oCtx = oCanvas.getContext("2d");
            //绘制个数
            let len = this.cjData.length
            //每个扇形占的份额,一个圆的总额是2，看下图
            let portion = 180 / len;

            this.setupCanvas(oCanvas);

            for (var i = 0; i < len; i++) {

                oCtx.beginPath();
                //绘制的起点
                oCtx.moveTo(oCanvas.width / 2, oCanvas.height / 2);
                //填充颜色
                oCtx.fillStyle = i % 2 == 0 ? '#E5E5EF' : '#FFFFFF';
                //arc的4个参数分别是（x,y,起始角度,结束角度）
                //oCtx.arc(oCanvas.width / 2, oCanvas.height / 2, oCanvas.width / 2, (Math.PI / 180) * (60*i), (Math.PI / 180) * ((60*i) + 60), false);
                oCtx.arc(oCanvas.width / 2, oCanvas.height / 2, oCanvas.width / 2, Math.PI * (270 + portion) / 180, Math.PI * (270 - portion) / 180, true);
                //关闭路径
                oCtx.closePath();
                oCtx.fill();
                oCtx.save();
                oCtx.fillStyle = "#333333";
                oCtx.textBaseline = "middle";
                oCtx.font = "25px Arial";
                oCtx.fillText(this.cjData[i].name, (oCanvas.width - oCtx.measureText(this.cjData[i].name).width) / 2, oCanvas.height / 12);
                await pathToBase64('https://cdn.codics.cn/' + this.cjData[i].img).then(base64 => {
                    let image = new Image();
                    image.src = base64;
                    image.onload = function () {
                        oCtx.drawImage(image, oCanvas.width / 5 * 2, oCanvas.height / 4 - oCanvas.width / 10, oCanvas.width / 5, oCanvas.width / 5);
                    };
                })
                oCtx.translate(oCanvas.width / 2, oCanvas.height / 2);
                oCtx.rotate(Math.PI * 2 / len);
                oCtx.translate(-oCanvas.width / 2, -oCanvas.height / 2);

            }

            setTimeout(() => {

                let image = new Image();

                image.src = oCanvas.toDataURL('image/png', 1);

                image.onload = () => {
                    // 当图片加载完成后，将其设置为el-image的src
                    this.imgData = image.src;

                };

            }, 1000);

        },
        randomChar(num) {
            return getRandomChineseCharacters(num);
        },
        lotteryFuc() {
            let t = [];
            this.cjData.forEach(item => {
                t[t.length] = item.odds;
            });
            let total = t.reduce((acc, curr) => acc + curr, 0);//数据求和
            for (let i = 0; i < t.length; i++) {
                let random = parseInt(Math.random() * total);       //获取 0-总数 之间的一个随随机整数
                if (random < t[i]) {
                    return i;                                   //如果在当前的概率范围内,得到的就是当前概率
                } else {
                    total -= t[i];                                 //否则减去当前的概率范围,进入下一轮循环
                }
            }
        },
        test() {
            let t = [0, 0, 0, 0, 0, 0]
            for (let i = 0; i < 100; i++) {
                t[this.lotteryFuc()]++;
            }
            console.log(t);
        }
    }

}
</script>

<style>
.codics-shop-lottery-body {
    height: fit-content;
    width: 100%;
    min-height: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    background-image: linear-gradient(to bottom, #812BE2, #5001A8);
    overflow-y: scroll;
}

.codics-shop-lottery-bimg {
    height: 550vw;
    width: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
}

.codics-shop-lottery-top {
    height: 24vw;
    width: 100%;
    position: relative;
    float: left;
    margin-top: 35px;
    margin-bottom: 35px;
    transform: scale(0.75);
}

.codics-shop-lottery-tip {
    height: 35px;
    width: fit-content;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    line-height: 35px;
    box-sizing: border-box;
    text-align: center;
    color: #FFF;
    font-size: 14px;
    padding: 0 20px 0 20px;
    background: linear-gradient(to left, #F537FF, #6405A7);
    border-radius: 17.5px;
    margin-bottom: 18px;
    border: 1px solid #8787FF;
}

.codics-shop-lottery-content {
    height: 90vw;
    width: 100%;
    position: relative;
    float: left;
}

.codics-shop-lottery-img {
    height: 100%;
    width: 100%;
    position: absolute !important;
    left: 0.6%;
    top: 0;
}

.codics-shop-lottery-content-start {
    height: 99px;
    width: 77px;
    position: absolute !important;
    margin: auto;
    left: 0;
    top: -17%;
    bottom: 0;
    right: 0;
}

.codics-shop-lottery-zp {
    transition: 5s;
    width: 67%;
    height: 67vw;
    position: absolute !important;
    margin: auto;
    left: 0;
    top: -12.8%;
    right: 0;
    bottom: 0;
    border-radius: 50%;
}

.codics-shop-lottery-button-box {
    height: 80px;
    width: 100%;
    position: relative;
    float: left;
    perspective: 230px;
    top: -25px;
}

.codics-shop-lottery-button-card {
    height: 100%;
    width: 67%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, #A611E4, #5E06DF);
    transform-origin: top;
    transition: all 1s;
    transform-style: preserve-3d;
    -webkit-transform-origin-z: -16px;
    transform: rotateX(30deg);
    border-radius: 20px 20px 0 0;
    box-sizing: border-box;
    padding: 15px;
}

.codics-shop-lottery-users-card2 {
    height: fit-content !important;
    box-sizing: border-box;
    padding: 15px !important;
    font-size: 14px;
    color: #FFF;
    line-height: 40px;
}

.codics-shop-lottery-button-viw {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    position: absolute;
    left: 5px;
    top: 5px;
    background: linear-gradient(to bottom, #A611E4, #5E06DF);
    border-radius: 20px 20px 0 0;
    box-shadow: inset 2px 2px 5px #5802C1, inset -3px -3px 6px rgba(174, 93, 236, .3);
    box-sizing: border-box;
}

.codics-shop-lottery-button {
    height: 100%;
    width: calc(50% - 5px);
    position: relative;
    float: left;
    background: #FFBC42;
    border-radius: 9px;
    box-shadow: -1px -1px 2px #FFD84C, 1.5px 1.5px 3px #D9A038;
    line-height: 50px;
    text-align: center;
    font-size: 15px;
    font-weight: bolder;
    transition: .5s;
    user-select: none;
    color: #704f00;
}

.codics-shop-lottery-button:active {
    font-size: 14.9px;
    box-shadow: inset 1px 1px 2px #FFD84C, inset -1.5px -1.5px 3px #D9A038;
}

.codics-shop-lottery-button-card::after {
    content: '';
    height: 30px;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background: #390098;
    transform: rotateX(-30deg);
    transform-origin: top;

}

.codics-shop-lottery-wdjp {
    text-decoration: underline;
    height: 30px;
    width: 100%;
    position: relative;
    float: left;
    font-size: 10px;
    text-align: center;
    color: #FFF;
    line-height: 30px;
    top: 15px;
    z-index: 10;
}

.codics-shop-lottery-users-title {
    height: 50px;
    width: fit-content;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    line-height: 50px;
    font-size: 17px;
    font-weight: 900;
    margin-top: 30px;
    color: #FFF;

}

.codics-shop-lottery-users-title::before {
    content: '';
    height: 50px;
    width: 160px;
    background: url('https://cdn.codics.cn/huayuan/img/yasuo_%E5%9C%86%E8%A7%92%E7%9F%A9%E5%BD%A2%205%20%E6%8B%B7%E8%B4%9D%202.webp') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: -110px;
    transform: scale(.2);
}

.codics-shop-lottery-users-title::after {
    content: '';
    height: 50px;
    width: 160px;
    background: url('https://cdn.codics.cn/huayuan/img/yasuo_%E5%9C%86%E8%A7%92%E7%9F%A9%E5%BD%A2%205%20%E6%8B%B7%E8%B4%9D.webp') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    right: -110px;
    transform: scale(.2);
}

.codics-shop-lottery-users-box {
    height: 390px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 15px 20px 15px 20px;
}

.codics-shop-lottery-users-card {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #6431B6;
    border-radius: 10px;
    box-shadow: inset 2px 2px 5px #5802C1, inset -3px -3px 6px rgba(174, 93, 236, .3);
    overflow: hidden;
    padding: 5px 0 5px 0;
}

.codics-shop-lottery-users-card ul {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    list-style-type: none;
    padding-inline-start: 0 !important;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
    transform: translate3d(0, 0, 0);
    will-change: transform;
    animation: huaD 5s linear infinite;
}

.codics-shop-lottery-users-card li {
    height: 50px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 50px;
    font-size: 14px;
    color: #FFF;
    font-weight: 500;
    list-style-type: none;
    box-sizing: border-box;
    padding: 0 15px 0 15px;
}

.codics-shop-lottery-users-card div {
    height: 50px;
    width: fit-content;
    position: absolute;
    right: 15px;
    top: 0;
    line-height: 50px;
}

@keyframes huaD {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-500px);
    }
}
</style>