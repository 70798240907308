<template>
    <div style="height: calc(100% - 60px);background: #FFF;">
        <div class="codics-user-body">
            <div class="codics-user-himg-box">
                <div class="codics-user-himg">
                    <div class="codics-user-shop-shen-qing">
                        <el-popover placement="bottom" width="160" v-model="sjsqVisible">
                            <p>您确定要提交商家申请吗？</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="text" @click="sjsqVisible = false">取消</el-button>
                                <el-button type="primary" size="mini"
                                    @click="sjsqVisible = false; applyTraderFuc();">确定</el-button>
                            </div>
                            <div slot="reference"
                                style="height: 40px;width: 100%;position: absolute;bottom: 0;line-height: 40px;text-align: center;font-size: 13px;color: #555;cursor: pointer;user-select: none;">
                                申请成为商家</div>
                        </el-popover>
                    </div>
                    <div class="codics-user-add">
                        <i class="el-icon-edit codics-user-add-icon"></i>
                    </div>
                    <el-image class="codics-user-himg-img" style="border-radius: 10px;" shape="square" :size="150"
                        src="http://coderutil.oss-cn-beijing.aliyuncs.com/photo/file_a1d75f58282440eb88bf7afc481c470d.png"></el-image>
                </div>
            </div>
            <el-input class="codics-user-info-input" style="margin-bottom: 20px;" clearable placeholder="请输入昵称"
                v-model="userInfo.nickname" :disabled="nickState">
                <el-button v-show="nickState" slot="append" icon="el-icon-edit" @click="nickState = false;"></el-button>
                <el-button v-show="!nickState" style="margin: 0 0 0 0 !important;" slot="append" icon="el-icon-check"
                    @click="nickState = false;"></el-button>
                <el-button v-show="!nickState" style="margin: 0 0 0 0 !important;" slot="append" icon="el-icon-close"
                    @click="nickState = true;"></el-button>
            </el-input>
            <div class="codics-user-info-box">
                <div class="codics-user-info-card">
                    <el-select class="codics-user-info-card-input"
                        style="width: calc(33.33% - 13.33px);margin-right: 19.9px;" v-model="value" placeholder="请选择性别">
                        <el-option label="未知" value=0></el-option>
                        <el-option label="男" value=1></el-option>
                        <el-option label="女" value=2></el-option>
                    </el-select>
                    <el-input class="codics-user-info-card-input"
                        style="width: calc(33.33% - 13.33px);margin-right: 19.9px;" clearable placeholder="请输入邮箱"
                        v-model="userInfo.email" :disabled="emailState">
                        <el-button slot="append" icon="el-icon-edit" @click="emailState = false;"></el-button>
                    </el-input>
                    <el-input class="codics-user-info-card-input" style="width: calc(33.33% - 13.33px);" clearable
                        placeholder="请输入手机号" v-model="userInfo.phone" :disabled="phoneState">
                        <el-button slot="append" icon="el-icon-edit" @click="phoneState = false;"></el-button>
                    </el-input>
                </div>

            </div>
            <div class="codics-user-info-box">
                <div class="codics-user-info-card">
                    <el-button style="width: calc(50% - 8px);margin-right: 6px;box-sizing: border-box;" type="success"
                        plain>绑定微信（{{ userInfo.wxkey == null ? '未绑定' : '已绑定' }}）</el-button>
                    <el-button style="width: calc(50% - 8px);box-sizing: border-box;" type="primary" plain>绑定QQ（{{
                            userInfo.qkey == null ? '未绑定' : '已绑定' }}）</el-button>
                </div>
            </div>
            <el-button class="codics-user-info-xgmm" type="warning" style="width: 100%;" plain>修改密码</el-button>
        </div>
    </div>
</template>

<script>
import { applyTrader } from '@/api';
export default {
    data() {
        return {
            userInfo: {
                nickname: '无事发生',
                email: '3041913305@qq.com',
                phone: '13155553831'
            },
            nickState: true,
            emailState: true,
            phoneState: true,
            sjsqVisible: false
        }
    },
    methods: {
        async applyTraderFuc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await applyTrader();
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '商户申请提交成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            } else {
                this.$message.error(data.message);
            }
        }
    }
}
</script>

<style>
/* 个人中心页面体 */
.codics-user-body {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 头像盒子容器 */
.codics-user-himg-box {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 个人中心用户头像盒子 */
.codics-user-himg {
    height: fit-content;
    width: fit-content;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    box-sizing: border-box;
    background: #FFF;
    box-shadow: 0px 0px 5px #e0dede;
    cursor: pointer;
    overflow: hidden;
    margin-top: 40px;
    margin-bottom: 40px;
}

/* 个人中心用户头像 */
.codics-user-himg-img {
    height: 150px;
    width: 150px;
    position: absolute !important;
    top: 0;
    left: 0;
    display: unset !important;
    z-index: 1;
    box-sizing: border-box;
    margin: 5px;
}

.codics-user-shop-shen-qing::before {
    content: '';
    height: 160px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: #FFF;
    border-radius: 10px;
}

/* 个人中心用户头像盒子添加按钮阴影遮罩 */
.codics-user-add {
    height: 160px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .45);
    opacity: 0;
    transition: .4s;
    z-index: 2;
    border-radius: 10px;
}

/* 用户头像盒子Hover事件 */
.codics-user-himg:hover .codics-user-add {
    opacity: 1;
}

.codics-user-himg:hover .codics-user-shop-shen-qing {
    height: 200px;
}

/* 用户头像盒子编辑图标 */
.codics-user-add-icon {
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    color: #FFF;
}

/* 用户头像申请成为商户按钮 */
.codics-user-shop-shen-qing {
    height: 160px;
    width: 160px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    background: #F5F7FA;
    z-index: 0;
    border-radius: 10px;
    transition: .4s;
}

/* 用户信息输入框 */
.codics-user-info-input {
    width: 95%;
    max-width: 700px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

/* 用户其他信息输入盒子 */
.codics-user-info-box {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 用户其他信息输入容器 */
.codics-user-info-card {
    height: fit-content;
    width: 100%;
    max-width: 700px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

/* 修改密码按钮 */
.codics-user-info-xgmm {
    position: relative;
    float: left;
    max-width: 700px;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width:768px) {

    .codics-user-info-card-input {
        width: 100% !important;
        margin-bottom: 20px;
        margin-right: 0 !important;
    }

    .codics-user-info-input {
        width: 100% !important;
    }

    .codics-user-info-box {
        margin-bottom: unset !important;
    }

    .codics-user-info-xgmm {
        margin-top: 20px !important;
    }

}
</style>