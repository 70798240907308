import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/Home';
import Login from '@/pages/Login';
import Admin from '@/pages/Admin';
import AdminHome from '@/pages/Admin/Home';
import AdminUser from '@/pages/Admin/User';
import AdminUserApply from '@/pages/Admin/User/Apply';
import AdminMenu from '@/pages/Admin/User/Menu';
import AdminProduct from '@/pages/Admin/Product';
import AdminProductType from '@/pages/Admin/Product/Type';
import AdminProductBrand from '@/pages/Admin/Product/Brand';
import AdminProductCdk from '@/pages/Admin/Product/Cdk';
import AdminExchangeOrder from '@/pages/Admin/Product/Order';
import AdminFinance from '@/pages/Admin/Finance';
import AdminFinanceWareHouse from '@/pages/Admin/Finance/Warehouse';
import AdminFinanceOutBound from '@/pages/Admin/Finance/Outbound';
import AdminSysUser from '@/pages/Admin/Sys';
import AdminSysNotice from '@/pages/Admin/Sys/Notice';
import AdminSysLunbo from '@/pages/Admin/Sys/Lunbo';
import AdminProductLottery from '@/pages/Admin/Product/Lottery';
import AdminAneArticle from '@/pages/Admin/Ane/Article';
import AdminAneNews from '@/pages/Admin/Ane/News';
import Shop from '@/pages/Shop';
import ShopHome from '@/pages/Home';
import ShopExchange from '@/pages/Shop/exchange';
import ShopDetail from '@/pages/Shop/detail';
import ShopLottery from '@/pages/Shop/lottery';
import User from '@/pages/User';
import UserApply from '@/pages/Apply';
import Firm from '@/pages/Firm';
import FirmHome from '@/pages/Firm/Home';
import FirmStaff from '@/pages/Firm/Staff';
import FirmStaffRole from '@/pages/Firm/Staff/Role';
import FirmProduct from '@/pages/Firm/Product';
import FirmProductBrand from '@/pages/Firm/Product/Brand';
import FirmExchangeOrder from '@/pages/Firm/Product/Order';
import FirmProductCdk from '@/pages/Firm/Product/Cdk';
import FirmProductLottery from '@/pages/Firm/Product/Lottery';
import FirmCdkReceive from '@/pages/Firm/Product/Receive';
import FirmWareHouse from '@/pages/Firm/Finance/Warehouse';
import FirmOutBound from '@/pages/Firm/Finance/Outbound';
import FirmSet from '@/pages/Firm/Set';
import FirmNotice from '@/pages/Firm/Set/Notice';
import FirmLog from '@/pages/Firm/Log';
import Book from '@/pages/Book';
import BookUpload from '@/pages/Book/Upload';
import BookHome from '@/pages/Book/Home';
import BookPdf from '@/pages/Book/Pdf';
import Img2pdf from '@/pages/Book/Img2pdf';
import Txt2Qr from '@/pages/Tools/Txt2qr';
import EmailBind from '@/pages/Email/Bind';
Vue.use(VueRouter);
const BookAsyncJsComponent = () => {
    import('../../public/js/pdf.mjs');
    import('../../public/js/pdf.worker.mjs');
    import('../../public/js/FileSaver.js');
    import('../../public/js/jszip.min.js');
}
export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            name: '官网首页',
            path: '/',
            component: Home,
            meta: {
                showIndex: '1',
                topShow: true,
                bottomShow: false,
                metaInfo: {
                    title: '华远创智-一卡在手，畅享无忧提货！',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: '登录',
            path: '/login',
            component: Login,
            meta: {
                showIndex: '-1',
                topShow: true,
                bottomShow: false,
                metaInfo: {
                    title: '卡提货-登陆注册',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: '后台',
            path: '/admin',
            component: Admin,
            redirect: '/admin/home',
            meta: {
                showIndex: '-1',
                topShow: false,
                bottomShow: false,
                metaInfo: {
                    title: '卡提货-后台管理',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            },
            children: [
                {
                    name: '后台首页',
                    path: 'home',
                    component: AdminHome,
                    meta: {
                        metaInfo: {
                            title: '卡提货-后台主页',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '用户管理',
                    path: 'user',
                    component: AdminUser,
                    meta: {
                        metaInfo: {
                            title: '卡提货-用户管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '菜单管理',
                    path: 'menu',
                    component: AdminMenu,
                    meta: {
                        metaInfo: {
                            title: '卡提货-菜单管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商品管理',
                    path: 'product',
                    component: AdminProduct,
                    meta: {
                        metaInfo: {
                            title: '卡提货-商品管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '类型管理',
                    path: 'producttype',
                    component: AdminProductType,
                    meta: {
                        metaInfo: {
                            title: '卡提货-类型管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '品牌管理',
                    path: 'productbrand',
                    component: AdminProductBrand,
                    meta: {
                        metaInfo: {
                            title: '卡提货-品牌管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '兑换订单管理',
                    path: 'exorder',
                    component: AdminExchangeOrder,
                    meta: {
                        metaInfo: {
                            title: '卡提货-兑换订单管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '卡券管理',
                    path: 'cdk',
                    component: AdminProductCdk,
                    meta: {
                        metaInfo: {
                            title: '卡提货-卡券管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: 'admin-sys-user',
                    path: 'sysuser',
                    component: AdminSysUser,
                    meta: {
                        metaInfo: {
                            title: '卡提货-后台主页',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '财务管理',
                    path: 'finance',
                    component: AdminFinance,
                    meta: {
                        metaInfo: {
                            title: '卡提货-财务管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '入库查询',
                    path: 'warehouse',
                    component: AdminFinanceWareHouse,
                    meta: {
                        metaInfo: {
                            title: '卡提货-入库查询',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '出库查询',
                    path: 'outbound',
                    component: AdminFinanceOutBound,
                    meta: {
                        metaInfo: {
                            title: '卡提货-出库查询',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '系统通知管理',
                    path: 'notice',
                    component: AdminSysNotice,
                    meta: {
                        metaInfo: {
                            title: '卡提货-系统通知管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '抽奖活动管理',
                    path: 'lottery',
                    component: AdminProductLottery,
                    meta: {
                        metaInfo: {
                            title: '卡提货-抽奖活动管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户认证管理',
                    path: 'apply',
                    component: AdminUserApply,
                    meta: {
                        metaInfo: {
                            title: '卡提货-商户认证管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '轮播管理',
                    path: 'lunbo',
                    component: AdminSysLunbo,
                    meta: {
                        metaInfo: {
                            title: '卡提货-轮播管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '文章管理',
                    path: 'article',
                    component: AdminAneArticle,
                    meta: {
                        metaInfo: {
                            title: '卡提货-文章管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '新闻管理',
                    path: 'news',
                    component: AdminAneNews,
                    meta: {
                        metaInfo: {
                            title: '卡提货-新闻管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    path: '/admin*',
                    redirect: 'home'
                }
            ]
        },
        {
            name: '商城',
            path: '/shop',
            redirect: '/shop/exchange',
            component: Shop,
            meta: {
                showIndex: '-1',
                topShow: false,
                bottomShow: false
            },
            children: [
                {
                    name: '商城首页',
                    path: 'home',
                    component: ShopHome,
                    meta: {
                        metaInfo: {
                            title: '卡提货-商店首页',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '卡券兑换',
                    path: 'exchange',
                    component: ShopExchange,
                    meta: {
                        metaInfo: {
                            title: '卡提货-卡券兑换',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '卡券详情',
                    path: 'detail/:cdkValue',
                    component: ShopDetail,
                    meta: {
                        metaInfo: {
                            title: '卡提货-卡券详情',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '卡券抽奖',
                    path: 'lottery',
                    component: ShopLottery,
                    meta: {
                        metaInfo: {
                            title: '卡提货-卡券抽奖',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    path: '/shop*',
                    redirect: 'exchange'
                }
            ]
        },
        {
            name: '商户中心',
            path: '/firm',
            redirect: '/firm/home',
            component: Firm,
            meta: {
                showIndex: '-1',
                topShow: false,
                bottomShow: false,
                metaInfo: {
                    title: '卡提货-商户中心',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            },
            children: [
                {
                    name: '商户首页',
                    path: 'home',
                    component: FirmHome,
                    meta: {
                        pageId: -1,
                        metaInfo: {
                            title: '卡提货-商户首页',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户角色管理',
                    path: 'role',
                    component: FirmStaffRole,
                    meta: {
                        pageId: 7,
                        metaInfo: {
                            title: '卡提货-商户角色管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户员工管理',
                    path: 'staff',
                    component: FirmStaff,

                    meta: {
                        pageId: 15,
                        metaInfo: {
                            title: '卡提货-商户员工管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户商品管理',
                    path: 'product',
                    component: FirmProduct,
                    meta: {
                        pageId: 9,
                        metaInfo: {
                            title: '卡提货-商品管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户品牌管理',
                    path: 'productbrand',
                    component: FirmProductBrand,
                    meta: {
                        pageId: 10,
                        metaInfo: {
                            title: '卡提货-品牌管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户兑换订单管理',
                    path: 'exorder',
                    component: FirmExchangeOrder,
                    meta: {
                        pageId: 12,
                        metaInfo: {
                            title: '卡提货-兑换订单管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户卡券管理',
                    path: 'cdk',
                    component: FirmProductCdk,
                    meta: {
                        pageId: 11,
                        metaInfo: {
                            title: '卡提货-卡券管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '卡券领用管理',
                    path: 'receive',
                    component: FirmCdkReceive,
                    meta: {
                        pageId: 18,
                        metaInfo: {
                            title: '卡提货-卡券领用管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户抽奖活动管理',
                    path: 'lottery',
                    component: FirmProductLottery,
                    meta: {
                        pageId: 99,
                        metaInfo: {
                            title: '卡提货-抽奖活动管理',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户系统设置',
                    path: 'set',
                    component: FirmSet,
                    meta: {
                        pageId: 21,
                        metaInfo: {
                            title: '卡提货-商户系统设置',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户通知设置',
                    path: 'notice',
                    component: FirmNotice,
                    meta: {
                        pageId: 20,
                        metaInfo: {
                            title: '卡提货-商户通知设置',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户入库记录',
                    path: 'warehouse',
                    component: FirmWareHouse,
                    meta: {
                        pageId: 16,
                        metaInfo: {
                            title: '卡提货-商户入库记录',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户出库记录',
                    path: 'outbound',
                    component: FirmOutBound,
                    meta: {
                        pageId: 17,
                        metaInfo: {
                            title: '卡提货-商户出库记录',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    name: '商户日志记录',
                    path: 'log',
                    component: FirmLog,
                    meta: {
                        pageId: 22,
                        metaInfo: {
                            title: '卡提货-商户日志记录',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
                {
                    path: '/firm*',
                    redirect: '/firm/home'
                }
            ]
        },
        {
            name: '个人中心',
            path: '/user',
            component: User,
            meta: {
                showIndex: '-1',
                topShow: true,
                bottomShow: true,
                metaInfo: {
                    title: '卡提货-个人中心',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: '商户申请',
            path: '/apply',
            component: UserApply,
            meta: {
                topShow: true,
                bottomShow: true,
                metaInfo: {
                    title: '卡提货-商户申请',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: '画册上传',
            path: '/book/upload',
            component: BookUpload,
            beforeEnter: (to, from, next) => {
                // 当用户尝试访问 /special-page 时，加载js文件
                Promise.all([BookAsyncJsComponent()]).then(next());
            },
            meta: {
                topShow: true,
                bottomShow: true,
                metaInfo: {
                    title: '卡提货-画册上传',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: '画册展示',
            path: '/book/show/:id',
            component: BookPdf,
            // beforeEnter: (to, from, next) => {
            //     // 当用户尝试访问 /special-page 时，加载js文件
            //     Promise.all([BookAsyncJsComponent()]).then(next());
            // },
            meta: {
                topShow: false,
                bottomShow: false,
                metaInfo: {
                    title: '卡提货-画册展示',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: '图片转PDF',
            path: '/book/img2pdf',
            component: Img2pdf,
            meta: {
                topShow: true,
                bottomShow: true,
                metaInfo: {
                    title: '卡提货-图片转PDF',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: '二维码转换',
            path: '/tools/txt2qr',
            component: Txt2Qr,
            meta: {
                topShow: true,
                bottomShow: true,
                metaInfo: {
                    title: '卡提货-二维码转换',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: '邮箱绑定',
            path: '/email/bind',
            component: EmailBind,
            meta: {
                topShow: true,
                bottomShow: true,
                metaInfo: {
                    title: '卡提货-邮箱绑定',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            }
        },
        {
            name: 'PDF画册',
            path: '/book',
            redirect: '/book',
            component: Book,
            meta: {
                topShow: false,
                bottomShow: false,
                metaInfo: {
                    title: '卡提货-PDF画册',
                    keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                    description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                }
            },
            children: [
                {
                    name: '画册首页',
                    path: '/',
                    component: BookHome,
                    meta: {
                        pageId: -1,
                        metaInfo: {
                            title: '卡提货-画册首页',
                            keywords: '卡提货, 优惠券, 礼品卡, 自动发卡',
                            description: '汇聚优质品牌，提供便捷提货服务。一卡在手，畅享购物乐趣，让您轻松拥有心仪商品，体验尊贵品质生活。'
                        }
                    }
                },
            ]
        },
        {
            path: '*',
            redirect: '/'
        },
    ],
});