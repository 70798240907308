import Vue from 'vue';
import Vuex from 'vuex';
import { getCookie,delCookie } from '@/utils/mycookie';

Vue.use(Vuex);
// 仓库数据
const state = {
    userInfo: JSON.parse(getCookie('user-inf'))
};
// 修改仓库数据的唯一手段
const mutations = {
    LOGOUT(state){
        state.userInfo = null;
    },
    LOGIN(state){
        state.userInfo = JSON.parse(getCookie('user-inf'));
    }
};
// 处理action,可以书写自己的业务逻辑,也可以异步处理
const actions = {
    logout({commit}) {

        delCookie('user-inf');

        commit('LOGOUT');

    },
    login({commit}){

        commit('LOGIN');

    }
};
// 获取仓库的数据更加简便
const getters = {};

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
});