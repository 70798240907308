<template>
    <div class="codics-firm-set-body">
        <div class="codics-firm-set-logo-box">
            <el-upload class="codics-firm-set-logo-upload" list-type="picture-card"
                :on-preview="handlePictureCardPreview" :http-request="uploadFile" ref="fmUpload">
                <i class="el-icon-plus"></i>
                <el-image class="codics-firm-set-logo" name="imageFile"
                    :src="(firmInfo?.icon == '' || firmInfo?.icon == null) ? 'https://cdn.codics.cn/huayuan/img/PicsArt_03-25-05.35.37.png' : firmInfo?.icon"></el-image>
            </el-upload>
        </div>
        <el-input class="codics-firm-set-input codics-firm-set-title" v-model="firmInfo.name" placeholder="请输入您的品牌名称"
            :disabled="nameState">
            <template slot="append">
                <i v-show="nameState" class="el-icon-edit codics-firm-set-input-icon" @click="nameState = false;"></i>
                <i v-show="!nameState" class="el-icon-check codics-firm-set-input-icon"
                    @click="nameState = true; updateFirmInfoFuc();"></i>
                <i v-show="!nameState" class="el-icon-close codics-firm-set-input-icon" @click="nameState = true;"></i>
            </template>
        </el-input>
        <el-input class="codics-firm-set-input codics-firm-set-url" v-model="firmInfo.url" placeholder="请输入您的品牌网站"
            :disabled="urlState">
            <template slot="append">
                <i v-show="urlState" class="el-icon-edit codics-firm-set-input-icon" @click="urlState = false;"></i>
                <i v-show="!urlState" class="el-icon-check codics-firm-set-input-icon"
                    @click="urlState = true; updateFirmInfoFuc();"></i>
                <i v-show="!urlState" class="el-icon-close codics-firm-set-input-icon" @click="urlState = true;"></i>
            </template>
        </el-input>
        <el-button class="codics-factory-user-info-xgmm" type="warning" style="width: 100%;" plain
            @click="passVisible = true;">修改密码</el-button>
        <el-dialog title="修改密码" :close-on-click-modal='false' :visible.sync="passVisible" width="80%">
            <el-form :model="updateForm">
                <el-form-item label="原密码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.o" autocomplete="off" placeholder="请输入原密码..." clearable
                        :maxlength="30" show-password></el-input>
                </el-form-item>
                <el-form-item label="重复原密码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.opass1" autocomplete="off" placeholder="请输入原密码..." clearable
                        :maxlength="30" show-password></el-input>
                </el-form-item>
                <el-form-item label="现密码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.n" autocomplete="off" placeholder="请输入现密码..." clearable
                        :maxlength="30" show-password></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="passVisible = false">取 消</el-button>
                <el-button type="primary" @click="updatePassFuc();">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import * as qiniu from 'qiniu-js';
import { mapState } from 'vuex';
import { jumpTo } from '@/utils/myutils';
import { getFirmInfo, updateFirmInfo, getQiNiuToken, updatePass } from '@/api';
export default {
    data() {
        return {
            passVisible:false,
            nameState: true,
            urlState: true,
            qiNiuInfo: {
                token: ''
            },
            firmInfo: {
                icon: null,
                name: null,
                url: null
            },
            updateForm: {
                o: '',
                opass1: '',
                n: ''
            }
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.getTokenFuc();

        this.getFirmInfoFuc();

    },
    methods: {
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        async getFirmInfoFuc() {

            if (this.userInfo == null) {

                this.$message.error("请先登录！");

                return true;

            }

            let data = await getFirmInfo({ id: this.userInfo?.user?.uar?.sid });

            if (data.code == 200) {

                data.data.icon = data.data.icon;

                this.firmInfo = data.data;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getUserListData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserListData(val, this.dataSize);
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        async updatePassFuc() {
            if (this.updateForm.o == '' || this.updateForm.opass1 == '' || this.updateForm.n == '') {
                this.$message({
                    message: '密码不能为空！',
                    type: 'warning'
                });
                return;
            }
            if (this.updateForm.o != this.updateForm.opass1) {
                this.$message({
                    message: '两次输入的密码不一致！',
                    type: 'warning'
                });
                return;
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await updatePass(this.updateForm);
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '更新密码成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    this.$store.dispatch("logout");

                    jumpTo(this, "登录", { url: "admin" }, {});
                }, 300);
            } else {
                this.$message.error(data.message);
            }
            this.passVisible = false;
        },
        async updateFirmInfoFuc() {
            if (this.firmInfo.icon == null && this.firmInfo.name == null && this.firmInfo.url == null) {
                this.$message.info('请刷新页面后再次尝试！');
                return true;
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await updateFirmInfo(this.firmInfo);
            loading.close();
            if (data.code == 200) {
                this.firmInfo = data.data;
            } else {
                this.$message.error(data.message);
            }
        },
        handlePictureCardPreview(file) {

        },
        // 上传方法
        uploadFile(uploadInfo) {

            this.firmInfo.icon = 'https://cdn.codics.cn/huayuan/img/' + uploadInfo.file.name;

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                },
                error(err) {
                    if (err.code == 614) {
                        _that.updateFirmInfoFuc();
                    }
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.updateFirmInfoFuc();
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            });
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        // 图片删除
        handleRemove(file, fileList) {
            // console.log("123123123:",file, fileList);

            this.fmList = this.fmList.filter(item => item.indexOf(file.name.replace(',', '')) == -1);
        },
    },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-firm-set-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

.codics-firm-set-logo-box {
    height: 100px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.codics-firm-set-logo {
    height: 100%;
    width: 211.86px;
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
}

.codics-firm-set-logo::after {
    content: '+';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .3);
    opacity: 0;
    line-height: 100px;
    text-align: center;
    transition: .4s;
    font-size: 20px;
    color: #FFF;
    font-weight: bolder;
}

.codics-firm-set-logo:hover:after {
    opacity: 1 !important;
}

.codics-firm-set-input {
    width: 100%;
    max-width: 1180px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box !important;
    margin-bottom: 20px;
}

.codics-firm-set-input-icon {
    position: relative;
    height: 38px;
    width: 40px;
    line-height: 38px !important;
    text-align: center;
    cursor: pointer;
}

.codics-firm-set-logo-upload {
    height: 100px !important;
    width: 211.86px !important;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

.codics-firm-set-logo-upload .el-upload--picture-card {
    height: 100% !important;
    width: 100% !important;
}

.codics-firm-set-logo-upload .el-icon-plus {
    display: none !important;
}

.codics-firm-set-logo-upload .el-upload-list {
    display: none !important;
}

/* 修改密码按钮 */
.codics-factory-user-info-xgmm {
    position: relative;
    float: left;
    max-width: 1180px;
    left: 50%;
    transform: translateX(-50%);
}
</style>