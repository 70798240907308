<template>
    <div class="codics-apply-body">
        <div class="codics-apply-content">
            <el-steps class="codics-apply-step" :active="active" finish-status="success" align-center>
                <el-step title="填写资料"></el-step>
                <el-step title="官方审核"></el-step>
                <el-step title="审核通过"></el-step>
            </el-steps>
            <el-form v-show="active == 0" class="codics-apply-form" :model="addForm">
                <el-form-item style="margin-top: 15px;" label="公司资质" :label-width="formLabelWidth">
                    <el-upload class="codics-apply-form-zz-upload" list-type="picture-card"
                        :on-preview="handlePictureCardPreview" :http-request="uploadFile" ref="fmUpload">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item style="margin-top: 15px;" label="公司名称" :label-width="formLabelWidth">
                    <el-input v-model="addForm.com" autocomplete="off" placeholder="请填写您的公司名称"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: 15px;" label="您的姓名" :label-width="formLabelWidth">
                    <el-input v-model="addForm.name" autocomplete="off" placeholder="请填写您的姓名"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: 15px;" label="电话号码" :label-width="formLabelWidth">
                    <el-input v-model="addForm.phone" autocomplete="off" placeholder="请输入您的电话号码"></el-input>
                </el-form-item>
                <el-form-item style="margin-top: 15px;" label="单位地址" :label-width="formLabelWidth">
                    <el-cascader size="large" :options="areaOptions" v-model="addtions" @change="handleChange">
                    </el-cascader>
                    <el-input class="codics-apply-form-other" v-model="addForm.other" autocomplete="off"
                        placeholder="请输入您的详细地址"></el-input>
                </el-form-item>
            </el-form>
            <el-button v-show="active == 0"
                style="width: 92%;position: relative;float:left;left:50%;transform:translateX(-50%);top: 8vh;"
                type="success" plain @click="applyTraderFuc();">提交申请</el-button>
            <el-image v-show="active == 1" class="codics-apply-shing"
                src="https://cdn.codics.cn/huayuan/img/%E6%9A%82%E6%97%A0%E5%AE%A1%E6%A0%B8%E8%AE%B0%E5%BD%95.svg"
                fit="fill"></el-image>
            <el-image v-show="active == 3" class="codics-apply-shing codics-apply-shing2"
                src="https://cdn.codics.cn/huayuan/img/%E6%9A%82%E6%97%A0%E5%AE%A1%E6%A0%B8%E8%AE%B0%E5%BD%95.svg"
                fit="fill"></el-image>
            <el-button v-show="active == 3"
                style="width: 92%;position: relative;float:left;left:50%;transform:translateX(-50%);top: 25vh;"
                type="success" plain @click="toPage('商户中心', {}, {});">跳转后台</el-button>
        </div>
    </div>
</template>

<script>
import { getQiNiuToken, infoApply, applyTrader } from '@/api';
import * as qiniu from 'qiniu-js';
import { regionData, codeToText, TextToCode } from "element-china-area-data";
import { jumpTo } from '@/utils/myutils';
export default {
    data() {
        return {
            applyData: null,
            qiNiuInfo: {
                token: ''
            },
            active: 1,
            areaOptions: regionData,
            addForm: {
                com: '',
                name: '',
                phone: '',
                other: ''
            },
            formLabelWidth: '120px',
            addtions: [],
            jsList: []
        }
    },
    mounted() {
        this.getTokenFuc();
        this.infoApplyFuc();
    },
    methods: {
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        // 图片点击预览
        handlePictureCardPreview(file) {

        },
        // 上传方法
        uploadFile(uploadInfo) {

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                },
                error(err) {
                    // console.log(err)
                    if (err.code == 614) {

                        _that.jsList[_that.jsList.length] = 'huayuan/img/' + uploadInfo.file.name;

                    }
                    // console.log(err);
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.jsList[_that.jsList.length] = res.key;
                }
            }
            const observable = qiniu.upload(uploadInfo.file, 'huayuan/img/' + uploadInfo.file.name, this.qiNiuInfo.token, {}, {

            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        // 图片删除
        handleRemove(file, fileList) {
            // console.log("123123123:",file, fileList);

            this.fmList = this.fmList.filter(item => item.indexOf(file.name.replace(',', '')) == -1);
        },
        handleChange(value) {

            value.forEach((item, index) => {

                switch (index) {

                    case 0:

                        this.addForm.province = codeToText[item];

                        break;

                    case 1:

                        this.addForm.city = codeToText[item];

                        break;

                    case 2:

                        this.addForm.area = codeToText[item];

                        break;

                }

            });

        },
        async infoApplyFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await infoApply();

            loading.close();

            if (data.code == 200) {

                this.active = data?.data.state == 0 ? 1 : 3;

                this.applyData = data?.data;

            } else {

                this.active = 0;

                // this.$message.error(data.message);

            }

        },
        async applyTraderFuc() {

            this.addForm.img = this.jsList.join(",");

            if (this.addForm.com == '' || this.addForm.name == '' || this.addForm.phone == '' || this.addForm.province == '' || this.addForm.city == '' || this.addForm.area == '') {

                this.$notify({
                    title: '警告',
                    message: '请填写完成资料后再进行提交！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await applyTrader(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.active = 1;

            } else {

                this.$message.error(data.message);

            }

        },
        toPage(n, p, q) {
            jumpTo(this, n, p, q);
        }
    }
}
</script>

<style scoped lang="scss">
.codics-apply-body {
    height: fit-content;
    width: 100%;
    min-height: calc(100% - 120px);
    position: relative;
    float: left;

    .codics-apply-content {
        height: fit-content;
        width: 100%;
        position: relative;
        float: left;
        max-width: 1180px;
        left: 50%;
        transform: translateX(-50%);

        .codics-apply-step {
            width: 100%;
            position: relative;
            float: left;
            margin-top: 50px;
        }

        .codics-apply-form {
            width: 100%;
            position: relative;
            float: left;
            max-width: 980px;
            left: 50%;
            transform: translateX(-56%);
            margin-top: 20px;

            .codics-apply-form-other {
                width: calc(100% - 237px);
                position: relative;
                margin-left: 20px;
            }
        }

        .codics-apply-shing {
            height: 211.2px;
            width: 240px;
            position: relative;
            float: left;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 15vh;
            overflow: visible !important;

            &:after {
                content: '您的申请已经提交，请耐心等待审核！期间会有业务人员拨打您的电话，请您保持电话畅通，注意接听！';
                height: fit-content;
                width: 100vh;
                max-width: 1180px;
                position: relative;
                float: left;
                left: 50%;
                transform: translateX(-50%);
                top: 50%;
                text-align: center;
                line-height: 50px;
                font-size: 25px;
                text-align: center;
                color: #333;
                font-weight: bolder;
            }
        }

        .codics-apply-shing2 {
            &:after {
                content: '您的申请审核通过啦！您可以点击下方按钮跳转至您的管理端后台！';
            }
        }



        @media (max-width:768px) {
            .el-form-item {
                padding-right: 45px;
            }

            .codics-apply-form {
                transform: translateX(-50%);
            }

            .el-cascader {
                width: 100% !important;
                margin-bottom: 20px;

            }

            .codics-apply-shing {
                &:after {
                    width: 150%;
                    top: 25%;
                }
            }

            .codics-apply-form-other {
                width: 100% !important;
                margin-left: unset !important;
            }
        }
    }
}
</style>