<template>
    <div class="codics-book-body">
        <div class="codics-book-upload-title">PDF转画册</div>
        <el-upload class="codics-book-upload" :http-request="uploadFile" :show-file-list="false" drag
            action="files.kylebing.cn" accept=".pdf">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将pdf文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <!-- <label id="pickFile" class="codics-book-upload">
            <input type="file" id="fileInput1" name="file" style="visibility: hidden;" accept="application/pdf"
                @change="uploadFile">
            <i class="iconfont codics-book-upload-icon">&#xe679;</i>
        </label> -->
        <!-- <div id="imgShow" class="codics-book-upload-img-box">

        </div> -->
        <!-- <input class="codics-book-upload" id="chooseFile" type="file" > -->
        <ul class="codics-book-upload-list" v-infinite-scroll="load" style="overflow:auto">
            <li v-for="(item, index) in fileList" :key="index" class="infinite-list-item codics-book-upload-list-item">
                {{
            '/file/电子画册/' + index + '.pdf' }}</li>
        </ul>
        <div class="codics-book-upload-url" v-html="showTxt"></div>
        <MyLoading v-show="uploadState" :show-txt="'文件解析进度' + uploadProgress + '%，请耐心等待...'"></MyLoading>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getQiNiuToken, userInfos, addPdf } from '@/api';
import { randomString, jumpTo } from '@/utils/myutils';
import * as qiniu from 'qiniu-js';
import MyLoading from '@/components/MyLoading';
export default {
    data() {
        return {
            qiNiuInfo: {
                token: null
            },
            uploadState: false,
            showTxt: '生成的链接在这里展示...',
            totalPages: 0,
            uploadProgress: 0,
            scaleNum: 2,//设置缩放比例，越大生成图片越清晰
            fileList: ['', '', '']
        }
    },
    mounted() {
        this.getTokenFuc();
    },
    methods: {
        load() {
            this.count += 2
        },
        async getTokenFuc() {
            let data = await getQiNiuToken();
            if (data.code == 200) {
                this.qiNiuInfo.token = data.uptoken;
            } else {
                this.$message.error(data.message);
            }
        },
        uploadFile(uploadInfo) {

            this.uploadState = true;
            //请先登录平台
            if (!this.userInfo) {

                this.$message({
                    showClose: true,
                    message: '请先完成登录后再进行操作！',
                    type: 'warning'
                });

                this.$store.dispatch('logout')

                jumpTo(this, "登录", {}, {});

                return;

            }

            if (this.qiNiuInfo.token == null) {
                //请先获取上传Token
                this.$message({
                    showClose: true,
                    message: '请刷新网站以重新获取Token！',
                    type: 'warning'
                });

                return;
            }

            let pdfPath = 'huayuan/pdf/' + randomString(10) + '_' + new Date().getTime() + '_' + this.userInfo.user.id + '.pdf';

            const _that = this;
            // 上传文件
            const observer = {
                next(res) {
                    // console.log('next: ', res)
                    _that.uploadProgress = res.total.percent.toFixed(2);;
                },
                error(err) {
                    // console.log(err)
                    _that.uploadState = false;
                },
                complete(res) {
                    // console.log('complete: ', res)
                    _that.showTxt = res.key;
                    _that.uploadState = false;
                    _that.addPdfFuc(uploadInfo.file.name, res.key, '')
                }
            }
            // console.log(uploadInfo.file);
            const observable = qiniu.upload(uploadInfo.file, pdfPath, this.qiNiuInfo.token, {
                params: { 'x:name': 'qiniu' },
            }, {
                region: qiniu.region.z0
            })
            const subscription = observable.subscribe(observer) // 上传开始
            // console.log(subscription);
            // subscription.unsubscribe() // 上传取消
        },
        // uploadFile(e) {
        //     var _this = this;
        //     console.log(e);
        //     // var pdfPath = e.target.value;
        //     var fileData = e.file;
        //     var fileSize = fileData.size;
        //     var mb;
        //     if (fileSize) {// 限制上传文件尺寸
        //         mb = fileSize / 1048576;
        //         // if(mb > 10) {
        //         //   alert("文件大小不能>10M");
        //         //   return;
        //         // }
        //     }
        //     var reader = new FileReader();
        //     reader.readAsDataURL(fileData); //将文件读取为 DataURL
        //     reader.onload = function (e) { //文件读取成功完成时触发
        //         console.log(pdfjsLib);
        //         pdfjsLib.getDocument(reader.result).promise.then(function (pdf) { //调用pdf.js获取文件
        //             if (pdf) {
        //                 _this.totalPages = pdf.numPages; //获取pdf文件总页数
        //                 // $("#currentPages").text("1/");
        //                 // $("#totalPages").text(totalPages);
        //                 var imgShow = document.getElementById("imgShow");
        //                 //遍历动态创建canvas
        //                 for (var i = 1; i <= _this.totalPages; i++) {
        //                     var canvas = document.createElement('canvas');
        //                     canvas.id = "pageNum" + i;
        //                     // imgShow.appendChild(canvas);
        //                     var context = canvas.getContext('2d');
        //                     _this.renderImg(pdf, i, context, canvas);
        //                 }
        //             }
        //         });
        //     };
        // },
        renderImg(pdfFile, pageNumber, canvasContext, canvas) {
            var _this = this;
            pdfFile.getPage(pageNumber).then(function (page) { //逐页解析PDF
                var viewport = page.getViewport(_this.scaleNum); // 页面缩放比例
                var newcanvas = canvasContext.canvas;
                //设置canvas真实宽高
                newcanvas.width = viewport.width;
                newcanvas.height = viewport.height;
                //设置canvas在浏览中宽高
                newcanvas.style.width = "100%";
                newcanvas.style.height = "100%";
                //默认显示第一页，其他页隐藏
                if (pageNumber != 1) {
                    newcanvas.style.display = "none";
                }
                var renderContext = {
                    canvasContext: canvasContext,
                    viewport: viewport
                };
                page.render(renderContext).promise.then(function () {
                    console.log(canvas.toDataURL("image/png", 1.0))
                });; //渲染生成
            });
            // $("#imgloading").css('display', 'none');
            return;
        },
        async addPdfFuc(name, url, himg) {
            this.uploadState = true;
            let data = await addPdf({ 'name': name, 'url': url, 'himg': himg });
            this.uploadState = false;
            if (data.code == 200) {
                this.showTxt = "<a href='" + window.location.origin + "/book/show/" + data.data.id + "' target='_blank'>" + window.location.origin + "/book/show/" + data.data.id + "</a>";
            } else {
                this.$message.error(data.message);
            }
        }
    },
    components: {
        MyLoading
    },
    computed: {
        ...mapState(['userInfo'])
    },
}
</script>

<style>
.codics-book-body {
    height: calc(100% - 120px);
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
}

.codics-book-upload-title {
    height: 50px;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 55%;
    line-height: 50px;
    text-align: center;
    font-size: 35px;
    color: #333;
}

.codics-book-upload {
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* border: dashed 1px #333; */
    cursor: pointer;
}

.codics-book-upload-progress {
    height: 20px;
    width: 90%;
    max-width: 800px;
    position: absolute !important;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-book-upload-icon {
    height: 50px;
    width: 50px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    user-select: none;
    cursor: pointer;
}

.codics-book-upload-list {
    height: calc(100% - 120px);
    width: 100%;
    max-width: 300px;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.codics-book-upload-list-item {
    height: 50px;
    width: 100%;
    line-height: 50px;
    padding-left: 15px;
    color: #333;
    background: #E8F3FE;
    font-size: 14px;
    color: #333;
    user-select: none;
    cursor: pointer;
    margin-bottom: 5px;
}

.codics-book-upload-url {
    height: 40px;
    width: 80%;
    max-width: 980px;
    position: absolute;
    margin: auto;
    line-height: 40px;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: -55%;
    border: dashed 1px #d9d9d9;
    font-size: 13px;
    color: #333;
    cursor: pointer;
}

.codics-book-upload-url:hover {
    border-color: #409EFF;
}

.codics-book-upload-img-box {
    height: 300px;
    width: 300px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

@media (max-width:768px) {
    .codics-book-upload-list {
        display: none;
    }
}
</style>