<template>
    <div class="codics-pdf-body" id="pdfIframe">
        <div class="codics-pdf-top-bar">
            <i class="iconfont codics-pdf-bottom-pag" @click="menuClick" style="float: left !important;"
                title="更多功能">&#xe866;</i>
            <el-image class="codics-pdf-top-logo"
                src="https://cdn.codics.cn/huayuan/img/yasuo_%E5%8D%A1%E6%8F%90%E8%B4%A7-LOGO%E7%99%BD.webp"
                fit="fill"></el-image>
            <i class="iconfont codics-pdf-bottom-pag" @click="dirClick" title="画册目录">&#xe715;</i>
            <i class="iconfont codics-pdf-bottom-pag" @click="menuClick" title="画册分享">&#xe873;</i>
        </div>
        <MyLoading v-show="loadState" :show-txt="'书册加载进度' + loadProgress.toFixed(2) + '%，请耐心等待...'"></MyLoading>
        <div class="codics-pdf-bottom-bar">
            <el-avatar class="codics-pdf-bottom-himg" :size="30"
                :src="pdfInfo?.account?.himg == null ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : pdfInfo?.account?.himg"></el-avatar>
            <div class="codics-pdf-bottom-nick">{{ pdfInfo?.account?.nickname }}</div>
            <i class="iconfont codics-pdf-bottom-pag" @click="showPagBar" title="页面跳转">&#xe76d;</i>
            <i class="iconfont codics-pdf-bottom-pag" @click="showPagBar" title="计时翻页">&#xe6bb;</i>
            <i class="iconfont codics-pdf-bottom-pag codics-pdf-bottom-qrcode" style="font-size: 16px;"
                title="扫码浏览">&#xe608;
                <el-image class="codics-pdf-bottom-qrimg" :src="qrImg" fit="fill"></el-image>
            </i>
        </div>
    </div>
</template>

<script>
import MyLoading from '@/components/MyLoading';
import { doThat } from '@/utils/config';
import QRCode from 'qrcode';
export default {
    data() {
        return {
            loadState: false,
            loadProgress: 0,
            totalPages: 0,
            nowPages: 0,
            scaleNum: 2,
            pdfData: [],
            pdfInfo: {},
            qrImg: ''
        }
    },
    mounted() {
        import('@/../public/js/pdf.mjs');
        import('@/../public/js/pdf.worker.mjs');
        import('@/../public/js/FileSaver.js');
        import('@/../public/js/jszip.min.js');
        if (!window.pdfWidth) {
            doThat(this);
        }
        window.pdfNowPage = this.$route.query.p;
        this.creatQrCode(window.location.href);
        // document.getElementById('targetButton').click();
    },
    beforeDestroy() {
        if (this.$route.path.indexOf('/book/show/') == -1) {
            let elements = document.getElementsByClassName("pdf-view-yichu");
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }
            let baoCuo = document.getElementById("webpack-dev-server-client-overlay");
            baoCuo.parentNode.removeChild(baoCuo);
        }
    },
    methods: {
        wxShare() {
            const wx = require("weixin-js-sdk");

        },
        async creatQrCode(QRlink) {   // QRlink是二维码的内容
            var _this = this;
            let opts = {
                errorCorrectionLevel: "L",//容错级别
                type: "image/png",//生成的二维码类型
                quality: 0.3,//二维码质量
                margin: 1,//二维码留白边距
                width: 100,//宽
                height: 100,//高
                text: QRlink,//二维码内容
                color: {
                    dark: "#000",//前景色
                    light: "#fff"//背景色
                }
            };
            await QRCode.toDataURL(QRlink, opts, (err, url) => {
                if (err) throw err
                //将生成的二维码路径复制给data的QRImgUrl
                // console.log(url)
                _this.qrImg = url;
            })
        },
        showPagBar() {
            document.getElementById('menu').style.bottom = document.getElementById('menu').style.bottom == '-80px' ? '40px' : '-80px';
            // document.getElementById('contentBox').style.bottom = document.getElementById('menu').style.bottom == '-80px' ? '40px' : '-80px';
        },
        menuClick() {
            console.log(123123123)
            $('#btnMore').trigger(_event._end);
        },
        dirClick() { 
            $('#btnThumb').trigger(_event._end);
        }
    },
    components: {
        MyLoading
    }
}
</script>

<style>
.codics-pdf-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.codics-pdf-top-bar {
    height: 40px !important;
    width: 100% !important;
    position: absolute !important;
    top: 0;
    background: #000;
    z-index: 1111;
}

.codics-pdf-top-logo {
    height: 50%;
    width: fit-content;
    position: relative;
    float: left;
    top: 50%;
    transform: translateY(-50%);
}

.codics-pdf-view {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
}

/* #pbTopBar */
#pbToolBar {
    display: none !important;
}

.codics-pdf-bottom-bar {
    height: 40px !important;
    width: 100% !important;
    position: absolute !important;
    bottom: 0 !important;
    background: #000;
    /* display: none; */
    box-shadow: rgba(40, 0, 0, 0.5) 0px 0px 5px;
    border-top: 1px solid rgb(30, 30, 30);
    z-index: 1111;
}

/* 用户头像 */
.codics-pdf-bottom-himg {
    position: relative;
    float: left;
    top: 5px;
    left: 5px;
}

.codics-pdf-bottom-nick {
    height: 100%;
    width: fit-content;
    position: relative;
    float: left;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #FFF;
    font-weight: 600;
    margin-left: 15px;
}

.codics-pdf-bottom-pag {
    height: 100%;
    width: 40px;
    position: relative;
    float: right;
    line-height: 40px;
    text-align: center;
    font-size: 18px;
    color: #FFF;
    user-select: none;
    cursor: pointer;
}

.codics-pdf-bottom-qrimg {
    height: 100px;
    width: 100px;
    position: absolute;
    top: -143px;
    right: 0;
    opacity: 0;
    transition: .4s;
    background: #333;
}

.codics-pdf-bottom-qrcode:hover .codics-pdf-bottom-qrimg {
    opacity: 1;
}

#menu {
    transition: .4s;
}

.pdf-view-yichu {
    padding-top: 40px;
}
</style>