<template>
    <div class="codics-bottom-bar">
        Copyright&nbsp;©&nbsp;2024&nbsp;<a href="#" style="text-decoration: none;color: #333;font-weight: bolder;">山东华远创智信息技术有限公司</a>&nbsp;
        <a target="_blank" href="https://beian.miit.gov.cn/" rel="external nofollow noreferrer"
            style="color: #333;">鲁ICP备2023021315号-3</a>&nbsp;&nbsp;<a id="codicsBottomTxt" style="display: none;">本站已稳定运行了 <a id="aaa"></a>
            天</a>
    </div>
</template>

<script>
export default {
    name: 'ShopIndex',
    data() {
        return {

        };
    },

    mounted() {
        var urodz = new Date("02/20/2024");
        var now = new Date();
        var ile = now.getTime() - urodz.getTime();
        var dni = Math.floor(ile / (1000 * 60 * 60 * 24));
        document.getElementById('aaa').innerHTML = dni;
    },

    methods: {

    },
};
</script>

<style lang="css">
/* 网页底栏 */
.codics-bottom-bar {
    height: 60px;
    width: 100%;
    position: relative;
    float: bottom;
    line-height: 60px;
    text-align: center;
    font-size: 14px;
    color: #333;
    background: #FFF;
}

@media (max-width:768px) {
    #codicsBottomTxt {
        display: none !important;
    }
}
</style>