<template>
    <div class="codics-admin-ane-article-body">
        <div class="codics-admin-ane-article-control">
            <el-button class="codics-admin-ane-article-control-search" type="success" icon="el-icon-plus"
                @click="addProductDialogVisibility = true;">添加</el-button>
            <!-- 添加表单 -->
            <el-dialog title="添加商品类型" :visible.sync="addProductDialogVisibility" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="分类名称" :label-width="formLabelWidth">
                        <el-input v-model="addForm.l" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品父类" :label-width="formLabelWidth">
                        <el-cascader :options="productTypeList" :props="{ checkStrictly: true }" clearable
                            @change="handleSubCat"></el-cascader>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addProductDialogVisibility = false">取 消</el-button>
                    <el-button type="primary" @click="addProductTypeFuc">确 定</el-button>
                </div>
            </el-dialog>
            <!-- <el-button class="codics-admin-ane-article-control-search" type="danger" icon="el-icon-plus">删除</el-button> -->
        </div>
        <el-table stripe class="codics-admin-ane-article-table" :data="productTypeList" border style="width: 100%"
            row-key="value" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="value" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="label" label="数值" width="180">
            </el-table-column>
            <el-table-column prop="detail" label="名称" width="180">
            </el-table-column>
            <el-table-column prop="img" label="父类" width="180">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm title="这是一段内容确定删除吗？">
                        <el-button v-show="scope.row.children == null" size="mini" type="danger" slot="reference"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-ane-article-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]"
            :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="maxSize">
        </el-pagination>
    </div>
</template>

<script>
import { getProductTypeList, addProductType } from '@/api';
export default {
    data() {
        return {
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            formLabelWidth: '120px',
            searchType: '名称',
            currentPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            productTypeList: []
        }
    },
    mounted() {

        this.getProductTypesData();

    },
    methods: {
        async getProductTypesData() {

            let data = await getProductTypeList();

            if (data.code == 200) {

                this.productTypeList = data.data;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getUserListData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserListData(val, this.dataSize);
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductTypeFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '分类名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductType(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: '添加商品分类成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-ane-article-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-ane-article-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 15px;
}

/* 后台管理页面主页控制栏 */
.codics-admin-ane-article-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-ane-article-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-ane-article-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-ane-article-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>