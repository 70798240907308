<template>
    <el-header class="codics-header-bar">
        <div class="codics-header-bar-content">
            <router-link to="/">
                <el-image class="codics-header-icon" :style="{ width: icon = '' ? '0px' : '125px' }" fit="fill"
                    :src='logo'></el-image>
            </router-link>
            <div v-show="!isPhone" class="codics-header-phone"><a href="tel: 4006-315-789"
                    style="text-decoration: none;color: #303133;">客服电话·4006-315-789</a></div>
            <el-button v-if="userInfo == null" class="codics-header-login" type="warning" plain icon="el-icon-user"
                @click="toPage('登录', {}, {})">后台登录</el-button>
            <el-dropdown class="codics-header-himg" v-if="userInfo != null" @command="handleCommand">
                <el-avatar style="margin-top: 13px;cursor: pointer;border: 1px solid #FFF;" :size="34"
                    :src="userInfo?.user.himg == null ? 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png' : userInfo?.user.himg"></el-avatar>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item command="grzx">个人中心</el-dropdown-item> -->
                    <!-- <el-dropdown-item command="wddd">我的订单</el-dropdown-item> -->
                    <el-dropdown-item command="rjht"
                        v-if="userInfo.user.member == 0 || userInfo?.user.uar!=null">软件后台</el-dropdown-item>
                    <el-dropdown-item command="tcdl">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button v-show="userInfo?.user.member > 1 && userInfo != null && userInfo?.user.uar==null"
                class="codics-header-login" type="success" icon="el-icon-wallet" style="margin-right: 15px;" plain
                @click="toPage('商户申请', {}, {})">在线咨询</el-button>
            <!-- <el-popconfirm v-show="userInfo?.user.member > 1 && userInfo != null" icon="el-icon-wallet"
                icon-color="#67C23A" title="您确定要提交商户申请吗？" @confirm="applyTraderFuc">
                
            </el-popconfirm> -->
            <el-badge v-if="userInfo != null" :value="unRead" class="item codics-header-mess">
                <i class="el-icon-message-solid" @click="showMess"></i>
            </el-badge>
            <el-menu :default-active="this.$route.meta.showIndex" class="codics-header-menu" mode="horizontal"
                @select="handleSelect" text-color="#212220" active-text-color="#E6A23C">
                <el-menu-item index="1" @click="toPage('官网首页', {}, {})">首页</el-menu-item>
                <el-menu-item index="2"><a href="./katihuo.html" target="_blank">关于卡提货</a></el-menu-item>
                <!-- <el-submenu index="2">
                    <template slot="title">平台业务</template>
                    <el-menu-item index="2-1" @click="toPage('画册上传', {}, {})">PDF转画册<svg class="icon" style="margin-top: -4px;margin-left: 5px;" aria-hidden="true"><use xlink:href="#icon-vip_16"></use></svg></el-menu-item>
                    <el-menu-item index="2-2" @click="toPage('图片转PDF', {}, {})">图片转PDF<svg class="icon" style="margin-top: -4px;margin-left: 5px;" aria-hidden="true"><use xlink:href="#icon-vip_16"></use></svg></el-menu-item>
                    <el-menu-item index="2-2">选项2</el-menu-item>
                    <el-menu-item index="2-3">选项3</el-menu-item>
                    <el-submenu index="2-4">
                        <template slot="title">选项4</template>
                        <el-menu-item index="2-4-1">选项1</el-menu-item>
                        <el-menu-item index="2-4-2">选项2</el-menu-item>
                        <el-menu-item index="2-4-3">选项3</el-menu-item>
                    </el-submenu>
                </el-submenu> -->
                <!-- <el-menu-item index="3" disabled>消息中心</el-menu-item> -->
                <el-menu-item v-show="userInfo?.user.member <= 1 || userInfo?.user.uar!=null" index="3"
                    @click="toPage(userInfo?.user?.member == 0 ? '后台首页' : '商户中心', {}, {})">商户中心</el-menu-item>
                <!-- <a style="text-decoration: none;color: #303133;"
                    :href="userInfo?.user?.member == 0 ? '/admin' : '/firm'" target="_blank"> -->
            </el-menu>
        </div>
    </el-header>
</template>

<script>
import { mapState } from 'vuex';
import { getCookie, setCookie, delCookie } from '@/utils/mycookie';
import { jumpTo } from '@/utils/myutils';
import { applyTrader } from '@/api';
export default {
    name: 'ShopIndex',
    props: {
        unRead: {
            type: Number,
            default: 0
        }
    },
    created() {

        this.$watch('unRead', (newVal, oldVal) => {
            // 处理parentData变化的逻辑
            this.unRead = newVal;

        });

    },
    data() {
        return {
            logo: 'https://cdn.codics.cn/huayuan/img/PicsArt_03-25-05.35.37.png',
            menuData: {
                data: [
                ]
            },
            messageDialog: false,
            messDirection: 'ltr',
            isPhone: false
        };
    },

    mounted() {

        // console.log(this.userInfo)

        this.isPhone = document.body.clientWidth <= 768

        window.onresize = () => {
            return (() => {
                this.isPhone = document.body.clientWidth <= 768
            })()
        }

    },

    methods: {
        toPage(n, p, q) {
            jumpTo(this, n, p, q);
        },
        handleCommand(command) {

            switch (command) {

                case 'wddd':

                    this.messageDialog = true;

                    break;

                case 'rjht':

                    jumpTo(this, '后台首页', {}, {});

                    break;

                case 'tcdl':

                    this.logout();

                    break;

                case 'grzx':

                    jumpTo(this, '个人中心', {}, {});

                    break;

            }

        },
        logout() {

            this.$store.dispatch('logout')

            this.toPage("登录", {}, {});

        },
        handleSelect() {

        },
        async applyTraderFuc() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await applyTrader();
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '商户申请提交成功！',
                    type: 'success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        showMess() {

            this.$emit('change-data', true);

        }
    },

    computed: {
        ...mapState(['userInfo'])
    }
};
</script>

<style lang="css">
/* 顶栏控件 */
.codics-header-bar {
    height: 60px;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    border-bottom: 1px solid #e8e9e7;
    transition: 5s;
}

/* 顶栏控件内容显示区域 */
.codics-header-bar-content {
    height: 100%;
    width: 100%;
    max-width: 1450px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
}

/* 顶栏图标LOGO */
.codics-header-icon {
    height: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    left: 10px;
    cursor: pointer;
    user-select: none !important;
}

/* 顶栏菜单 */
.codics-header-menu {
    height: 100%;
    width: auto;
    position: relative;
    float: right;
    right: 20px;
    border-width: 1px;
}

/* 顶栏信息按钮 */
.codics-header-mess {
    position: relative;
    float: right;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 30px;
    right: 40px;
    cursor: pointer;
}

/* 顶栏用户头像 */
.codics-header-himg {
    height: 100%;
    position: relative;
    float: right;
    right: 10px;
}

/* 顶栏菜单样式设置 */
.el-submenu__title,
.el-menu-item {
    border-width: 1px !important;
    border-color: #E83D18;
}

/* 顶栏登录按钮 */
.codics-header-login {
    position: relative;
    float: right;
    top: 10px;
    right: 10px;
}

@media (max-width:768px) {
    .codics-header-menu {
        display: none !important;
    }
}

.codics-header-phone {
    height: 100%;
    width: fit-content;
    position: relative;
    float: left;
    line-height: 60px;
    text-align: center;
    font-size: 15px;
    /* font-weight: bolder; */
    text-decoration: none;
    color: #333;
    margin-left: 18px;
    top: 3px;
}
</style>