<template>
    <div class="codics-img-to-pdf-body">
        <div class="codics-img-to-pdf-upload-title">图片转PDF</div>
        <el-upload action="files.kylebing.cn" width="100%" class="codics-img-to-pdf-upload" multiple
            :on-preview="handlePictureCardPreview" :http-request="uploadFile" :on-remove="handleRemove" ref="fmUpload"
            drag v-model="imgData" list-type="picture-card" :show-file-list="true">
            <el-image class="codics-img-to-pdf-upload-img"
                src="https://cdn.codics.cn/huayuan/img/%E4%B8%8A%E4%BC%A0%E6%96%87%E4%BB%B6.svg" fit="fill"></el-image>
            <div class="codics-img-to-pdf-upload-tip el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <el-button style="margin-top: 0px;" type="success" @click="enPdf" plain>生成并下载</el-button>
        <MyLoading v-show="uploadState"
            :show-txt="'正在处理图片' + (uploadProgress * 100 / pickData.length * 2).toFixed(2) + '%，请耐心等待...'"></MyLoading>
    </div>
</template>

<script>
import MyLoading from '@/components/MyLoading';
import jsPDF from "jspdf";
import Sortable from 'sortablejs';
export default {
    data() {
        return {
            uploadState: false,
            uploadProgress: 0,
            pickData: [],
            imgData: []
        }
    },
    mounted() {
        this.initDragSort();
    },
    methods: {
        initDragSort() {
            // 支持拖拽排序
            const el = this.$refs.fmUpload.$el.querySelectorAll('.el-upload-list')[0];
            Sortable.create(el, {
                onEnd: ({ oldIndex, newIndex }) => {
                    // 交换位置
                    const arr = this.imgData;
                    const page = arr[oldIndex];
                    arr.splice(oldIndex, 1);
                    arr.splice(newIndex, 0, page);
                }
            });
        },
        uploadFile(item) {

            this.pickData[this.pickData.length] = item;

            // this.uploadState = true;

            // this.uploadProgress+=1;

            // var reader = new FileReader();

            // reader.readAsDataURL(item.file);

            // reader.onload = () => {

            //     this.imgData[this.imgData.length] = reader.result;
            //     this.uploadProgress+=1;
            //     if(this.uploadProgress==this.pickData.length*2){
            //          this.uploadState = false;
            //          this.uploadProgress = 0;
            //     }
            // }
            // reader.onerror = function (err) {
            //     console.log(err);
            // }


        },
        enPdf() {

            const pdf = new jsPDF();

            this.uploadState = true;

            this.uploadProgress += 1;

            var reader = new FileReader();

            reader.readAsDataURL(item.file);

            reader.onload = () => {

                this.imgData[this.imgData.length] = reader.result;
                this.uploadProgress += 1;
                if (this.uploadProgress == this.pickData.length * 2) {
                    this.uploadState = false;
                    this.uploadProgress = 0;
                    this.imgData.forEach((item, index) => {

                        pdf.addImage(item, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());

                        if (index + 1 != this.imgData.length) pdf.addPage();

                    });

                    pdf.save('download.pdf');
                }
            }
            reader.onerror = function (err) {
                console.log(err);
            }



        }

    },
    components: {
        MyLoading
    }
}
</script>

<style>
.codics-img-to-pdf-body {
    height: fit-content;
    min-height: calc(100% - 120px);
    width: 100%;
    max-width: 1450px;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
}

.codics-img-to-pdf-upload-title {
    height: 30px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 30px;
    font-size: 14px;
    padding: 0 0 0 15px;
    border-left: 1px solid #E6A23C;
    margin-top: 15px;
    margin-bottom: 15px;
}

.codics-img-to-pdf-upload {
    width: 100vw;
    max-width: 1450px;

}

.codics-img-to-pdf-body .el-upload,
.codics-img-to-pdf-body .el-upload-dragger {
    width: 100%;
}

.codics-img-to-pdf-body .el-upload {
    border: none !important;
}

.codics-img-to-pdf-upload-img {
    height: 120px;
    width: 120px;
    position: absolute !important;
    margin: auto;
    top: -40px;
    left: 0;
    right: 0;
    bottom: 0;
}

.codics-img-to-pdf-upload-tip {
    height: 50px;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: 120px;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 50px;
    text-align: center;
    font-size: 13px !important;
}

.codics-img-to-pdf-body .el-upload--picture-card {
    position: absolute !important;
    top: 60px;
    left: 20px;
    width: calc(100% - 40px);
}

.codics-img-to-pdf-body .el-upload-list {
    margin-top: 195px;
    float: inline-start;
    max-width: 1450px;
    width: calc(100% - 40px);
}

.el-upload-list__item-actions {
    cursor: grab !important;
}

.el-upload-list__item-actions:active {
    cursor: grabbing !important;
}
</style>