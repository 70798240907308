<template>
    <div class="codics-firm-notice-body">
        <div class="codics-firm-notice-control">
            <el-button class="codics-firm-notice-control-search" type="success" icon="el-icon-plus"
                @click="addDialogVisibility = true;">添加通知</el-button>
            <!-- 添加表单 -->
            <el-dialog title="添加系统公告" :visible.sync="addDialogVisibility" width="80%">
                <el-input v-model="addForm.title" autocomplete="off" placeholder="请输入公告标题" style="margin-bottom: 15px;"
                    @input="searchChange"></el-input>
                <el-input type="textarea" :rows="2" placeholder="请输入公告内容" v-model="addForm.content"
                    style="margin-bottom: 15px;">
                </el-input>
                <el-select v-model="addForm.state" placeholder="请选择公告状态" style="margin-bottom: 15px;">
                    <el-option label="上架" value=0></el-option>
                    <el-option label="下架" value=1></el-option>
                </el-select>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addDialogVisibility = false">取 消</el-button>
                    <el-button type="primary" @click="addNoticeFuc">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-firm-notice-control-input codics-firm-notice-control-input-search" style="width: 250px !important;"
                :placeholder="'请输入要搜索的内容'" v-model="searchTxt" clearable @input="searchChange">
                <el-button slot="append" icon="el-icon-search" @click="searchNotice"></el-button>
            </el-input>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteNoticesFuc">
                <el-button class="codics-firm-notice-control-search codics-firm-notice-control-delete" slot="reference" type="danger"
                    icon="el-icon-delete">批量删除</el-button>
            </el-popconfirm>
        </div>
        <el-table stripe class="codics-firm-notice-table" :data="searchTxt == '' ? tableData : searchData" border
            style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="title" class-name="codics-firm-notice-title" label="标题" width="180">
            </el-table-column>
            <el-table-column prop="content" class-name="codics-firm-notice-content" label="内容" width="180">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="70"
                :filters="[{ text: '上架', value: 0 }, { text: '下架', value: 1 }]" :filter-method="stateFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? 'success' : 'warning'" disable-transitions>{{ scope.row.state
                    == 0 ? '上架' : '下架' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button v-show="scope.row.state == 1" size="mini"
                        @click="changeNoticeFuc(scope.$index, scope.row)" style="margin-right: 0px;">上架</el-button>
                    <el-button v-show="scope.row.state == 0" size="mini"
                        @click="changeNoticeFuc(scope.$index, scope.row)" style="margin-right: 0px;">下架</el-button>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm title="您确定要删除当前选中的数据吗?" @confirm="deleteThat(scope.$index, scope.row)">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-firm-notice-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPge"
            :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
            :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
    </div>
</template>

<script>
import { addNotice, deleteNotices, listNotices, changeNotice } from '@/api';
export default {
    data() {
        return {
            addDialogVisibility: false,
            searchTxt: '',
            addForm: {
                title: '',
                content: '',
                state: "0"
            },
            formLabelWidth: '120px',
            searchType: '名称',
            currentPage: 1,
            tableData: [],
            searchData: [],
            searchPge: 1,
            searchMax: 0,
            searchSize: 10,
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            productTypeList: [],
            selectData: []
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.listNoticesFuc();

    },
    methods: {
        handleSelectionChange(val) {
            this.selectData = val;
        },
        async listNoticesFuc() {

            let data = await listNotices({ key: '', pge: this.currentPage, psz: this.dataSize });

            if (data.code == 200) {

                this.tableData = data.data;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getUserListData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserListData(val, this.dataSize);
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        stateFilter(v, r, c) {

            return r.state === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addNoticeFuc() {

            if (this.addForm.title == '' || this.addForm.content == '') {

                this.$notify({
                    title: '警告',
                    message: '公告标题及内容不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addNotice(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: data.message,
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        searchChange(val) {

            if (val == '') {

                this.searchTxt = '';

                this.searchData = [];

                this.searchMax = 0;

                this.searchPge = 1;

            }

        },
        deleteNoticesFuc() {
            if (this.selectData.length == 0) {

                this.$message.error("请先选中要删除的数据再进行该操作！");

                return true;

            }
            let t = [];
            this.selectData.forEach(item => {

                t[t.length] = item.id;

            });
            this.deleteData(t);
        },
        deleteThat(idx, row) {
            let t = [row.id];
            this.deleteData(t);
        },
        async deleteData(t) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await deleteNotices({ ids: t });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        async searchNotice() {
            if (this.searchTxt == '') {
                this.$message.error("请先输入要搜索的内容！");
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await listNotices({ key: this.searchTxt, pge: this.searchPge, psz: this.searchSize });
            loading.close();
            if (data.code == 200) {
                this.searchData = data.data;
            } else {
                this.$message.error(data.message);
            }

        },
        async changeNoticeFuc(idx, row) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await changeNotice({ id: row.id });
            loading.close();
            if (data.code == 200) {
                row.state = row.state == 0 ? 1 : 0;
            } else {
                this.$message.error(data.message);
            }
        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-firm-notice-body {
    height: fit-content;
    width: 100%;
    min-height: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-firm-notice-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-firm-notice-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-firm-notice-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-firm-notice-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-firm-notice-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-firm-notice-content div {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

@media (max-width:768px) {

    .codics-firm-notice-control-input-search {
        margin-right: 0 !important;
        width: calc(100% - 110px) !important;
    }

    .codics-firm-notice-control-delete{
        margin-bottom: 15px !important;
    }

}
</style>