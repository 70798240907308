<template>
    <div class="codics-book-home-home-body">
        <el-carousel class="codics-book-home-home-lunbo" height="80vh">
            <el-carousel-item class="codics-book-home-home-lunbo-item" v-for="(item, index) in lunboData" :key="index">
                <div v-html="item.data"></div>
            </el-carousel-item>
        </el-carousel>
        <div class="codics-book-home-home-card-box">
            <div class="codics-book-home-home-card" v-for="item in cardData" :key="item.id">
                <div class="codics-book-home-home-card-line">
                    <el-image class="codics-book-home-home-card-line-icon" :src="item.icon"></el-image>
                    <div class="codics-book-home-home-card-line-title">{{ item.title }}</div>
                </div>
                <div class="codics-book-home-home-card-line-txt">{{ item.detail }}</div>
            </div>
        </div>
        <el-divider class="codics-book-home-divider">优秀用户案例</el-divider>
        <div id="isolde" class="codics-book-home-home-content">
            <div data-isolde-el="flatty" class="codics-book-home-home-content-card" v-for="(item, index) in bookData"
                :key="index">
                <el-image class="codics-book-home-home-content-card-qr"
                    src="https://cdn.codics.cn/huayuan/img/yasuo_PicsArt_06-12-10.57.27.webp" fit="fill"
                    style="position: absolute;left: 0;top: 0;height: 50px;width: 50px;opacity: .9;"></el-image>
                <div class="codics-book-home-home-content-card-vip">
                    <svg class="icon" style="margin-top: 6px;" aria-hidden="true">
                        <use xlink:href="#icon-a-puka-huangguan2"></use>
                    </svg>
                    <div class="codics-book-home-home-content-card-vip-txt">会员专属</div>
                </div>
                <div class="codics-book-home-home-content-card-book">
                    <div class="codics-book-home-home-content-card-book-page" v-for="(item2, index2) in 7" :key="index2"
                        :style="{ 'max-height': '160px', 'position': index2 == 0 ? 'relative' : 'absolute', 'transition-duration': (index2 + 1) * .4 + 's', backgroundColor: index2 == 0 ? '#FFF !important' : 'unset !important', 'top': '0', 'height': index2 == 0 ? 'auto' : '100%', 'box-sizing': 'border-box' }">
                        <el-image v-show="index2 == 0" :src="item.himg"
                            style="backface-visibility: hidden !important;z-index: 111;height:auto;display: block;"
                            fit="cover"></el-image>
                    </div>
                </div>
                <el-image class="codics-book-home-home-content-card-qr-img"
                    style="position: absolute;margin: auto;height: 100px;width: 100px;top: 0;left: 0;right: 0;bottom: 0;border: 1px solid #DDD;z-index: 1110;transition: .4s;transform: scale(.6);opacity: 0;"
                    src="https://www.codics.cn/img/home/right/qr.svg" fit="fill"></el-image>
                <el-button class="codics-book-home-home-content-card-see" type="warning" plain
                    style="transition: .4s;">点击翻阅</el-button>
                <div class="codics-book-home-home-content-card-bottom">
                    <div class="iconfont codics-book-home-home-content-card-bottom-txt">&#xe879;{{ ' ' + 123 }}</div>
                    <div class="iconfont codics-book-home-home-content-card-bottom-txt">&#xe7c0;{{ ' ' + 99 }}</div>
                    <div class="iconfont codics-book-home-home-content-card-bottom-txt" style="float: left;">&#xe873;
                    </div>
                </div>
            </div>
        </div>
        <div class="codics-book-home-home-more-btn-box">
            <el-button class="codics-book-home-home-more-btn" type="info" plain>点击加载更多</el-button>
        </div>
        <el-divider class="codics-book-home-divider codics-book-home-divider2">会员特权对比</el-divider>
        <table class="codics-book-home-home-package">
            <col style="width:25%;">
            <col style="width:25%;">
            <col style="width:25%;">
            <col style="width:25%;">
            <tbody>
                <tr>
                    <td>功能特权</td>
                    <td
                        style="background-color:#FFFAEB;background-image: url('https://cdn.codics.cn/huayuan/img/yasuo_%E6%8E%A8%E8%8D%90%E9%80%89%E6%8B%A9%E8%A7%92%E6%A0%87.webp');background-repeat: no-repeat;background-size: 80px 80px;">
                        <svg class="icon"
                            style="transform: scale(1.5);margin-right: 10px;margin-top: 30px;color: #E2B03B !important;"
                            aria-hidden="true">
                            <use xlink:href="#icon-a-puka-huangguan2"></use>
                        </svg>超级会员<br>
                        <el-button type="warning" style="margin-bottom: 20px;font-size: 12px !important;" size="mini"
                            plain>开通会员</el-button>
                    </td>
                    <td><svg class="icon"
                            style="transform: scale(1.5);margin-right: 10px;margin-top: 30px;color: #E2B03B !important;"
                            aria-hidden="true">
                            <use xlink:href="#icon-svg"></use>
                        </svg>普通会员<br>
                        <el-button type="danger" style="margin-bottom: 20px;font-size: 12px !important;" size="mini"
                            plain>开通会员</el-button>
                    </td>
                    <td>普通用户</td>
                </tr>
                <tr v-for="(item, index) in packageData" :key="index">
                    <td class="iconfont" v-html="item.tips"></td>
                    <td class="iconfont" :style="{ 'background-color': index % 2 == 1 ? '#FFFAEB' : '#FbF4E4' }"
                        v-html="item.svip"></td>
                    <td class="iconfont" v-html="item.vip"></td>
                    <td class="iconfont" v-html="item.normal"></td>
                </tr>
            </tbody>
        </table>
        <el-divider class="codics-book-home-divider codics-book-home-divider2">更多平台服务</el-divider>
        <div class="codics-book-home-home-custom">
            <div class="codics-book-home-home-customer-box">
                <div class="codics-book-home-home-customer-card" v-for="(item, index) in 3" :key="index">
                    <div class="codics-book-home-home-customer-card-line" v-for="(item3, index3) in 3" :key="index3">
                        <el-image class=" codics-book-home-home-customer-img"
                            v-for="(item2, index2) in (index % 2 == 0 ? brandData : brandData2)[index3]"
                            :style="{ 'width': ((index % 2 == 1) ? (index3 % 2 == 1) : (index3 % 2 == 0)) ? '20%' : '25%' }"
                            :key="index2" :src="item2" fit="scale-down"></el-image>
                    </div>
                </div>
            </div>
            <div class="codics-book-home-home-custom-title">服务于各类场景</div>
            <div class="codics-book-home-home-custom-tip">PDF转画册，随时随地，云端畅享，模拟翻页阅读PDF，满足多场景需求，服务亿万用户，共绘阅读新篇章！</div>
            <div class="codics-book-home-home-card-box codics-book-home-home-card-box2">
                <div class="codics-book-home-home-card" v-for="item in cardData" :key="item.id">
                    <div class="codics-book-home-home-card-line">
                        <el-image class="codics-book-home-home-card-line-icon" :src="item.icon"></el-image>
                        <div class="codics-book-home-home-card-line-title">{{ item.title }}</div>
                    </div>
                    <div class="codics-book-home-home-card-line-txt">{{ item.detail }}</div>
                </div>
            </div>
            <div class="codics-book-home-home-custom-tip" style="margin-top: 50px;"><a
                    style="color: #00A4FF;text-decoration: none;font-size: 16px;" href="#">查看更多服务案例 &gt;&gt;</a></div>
        </div>
    </div>
</template>

<script>
import { loadJs } from '@/utils/myutils';
import isolde from 'isolde';
import 'isolde/dist/isolde.min.css';
export default {
    data() {
        return {
            showIndex: '1',
            lunboData: [
                // {
                //     data: `<video style='height:100%;width:100%;position:relative;object-fit:cover;' src="https://cdn.codics.cn/huayuan/video/ai_banner_pc.mp4" autoplay="" loop="" muted=""></video>`
                // },
                {
                    data: `<video style='height:100%;width:100%;position:relative;float:left;object-fit:cover;' src="https://cdn.codics.cn/huayuan/video/nM2BIQrv4z00jc990GY7No8OCY~xf4xwLzTZC2t0sB0bekfFi9oZykL3WPbxB_H5jLwVU4.mp4" autoplay="" loop="" muted=""></video>`
                },
                // {
                //     data: `<video style='height:100%;width:100%;position:relative;object-fit:cover;' src="https://cdn.codics.cn/huayuan/video/guanwang_ceb1d19.mp4" autoplay="" loop="" muted=""></video>`
                // }
            ],
            cardData: [
                {
                    id: 1,
                    icon: 'https://cdn.codics.cn/huayuan/img/%E5%BA%94%E7%94%A82.png',
                    title: '代金券',
                    detail: '线上线下场景,抵扣同样等值的现金的券',
                    url: ''
                },
                {
                    id: 2,
                    icon: 'https://cdn.codics.cn/huayuan/img/%E5%BA%94%E7%94%A82.png',
                    title: '折扣券',
                    detail: '线上线下场景,按折扣比例抵扣相应金额的券',
                    url: ''
                },
                {
                    id: 3,
                    icon: 'https://cdn.codics.cn/huayuan/img/%E5%BA%94%E7%94%A82.png',
                    title: '提领券',
                    detail: '线上线下场景,直接领取制定商品的券',
                    url: ''
                },
                {
                    id: 4,
                    icon: 'https://cdn.codics.cn/huayuan/img/%E5%BA%94%E7%94%A82.png',
                    title: '计次卡',
                    detail: '线上线下场景,按照实际使用次数从剩余次数中扣减，直至次数用完',
                    url: ''
                }
            ],
            bookData: [
                {
                    himg: 'https://cdn.codics.cn/huayuan/img/79b6016f30abb435209a6ba228bb81f.png'
                },
                {
                    himg: 'https://img0.baidu.com/it/u=253932222,2612729299&fm=253&fmt=auto&app=138&f=JPEG?w=220&h=311'
                },
                {
                    himg: 'https://img2.baidu.com/it/u=1939555239,313169841&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=710'
                },
                {
                    himg: 'https://img1.baidu.com/it/u=1566278739,638581835&fm=253&fmt=auto&app=138&f=JPEG?w=368&h=499'
                },
                {
                    himg: 'https://y.qq.com/music/photo_new/T002R300x300M000000A3rdZ15CF3i_1.jpg?max_age=2592000'
                }
            ],
            packageData: [
                {
                    tips: '套餐价格',
                    svip: '￥1499/年<del>￥1999/年</del>',
                    vip: '￥599/年<del>￥899/年</del>',
                    normal: '永久免费'
                },
                {
                    tips: '定制域名',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '子账号数',
                    svip: '5个',
                    vip: '2个',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '空间容量',
                    svip: '5GB',
                    vip: '2GB',
                    normal: '100MB'
                },
                {
                    tips: '上传数量',
                    svip: '不限量',
                    vip: '99个',
                    normal: '2个'
                },
                {
                    tips: '加密浏览',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '个人书架',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '作品审核',
                    svip: 'AI快速审核',
                    vip: 'AI快速审核',
                    normal: '人工审核，上架慢'
                },
                {
                    tips: '画册水印',
                    svip: '无水印',
                    vip: '无水印',
                    normal: '有水印'
                },
                {
                    tips: '访问统计',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '存储期限',
                    svip: '永久保存',
                    vip: '永久保存',
                    normal: '定期清除'
                },
                {
                    tips: '广告投放',
                    svip: '无广告',
                    vip: '无广告',
                    normal: '不定期投放'
                },
                {
                    tips: 'PDF尺寸',
                    svip: '无限制',
                    vip: '无限制',
                    normal: '5MB'
                },
                {
                    tips: '电话按钮',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '邮箱按钮',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '客服插件',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '调研插件',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: 'LOGO标识',
                    svip: '隐藏不显示',
                    vip: '隐藏不显示',
                    normal: '显示'
                },
                {
                    tips: '图片拼接PDF',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '防误删有效期',
                    svip: '30天',
                    vip: '7天',
                    normal: '1天'
                },
                {
                    tips: '背景音乐',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '读者留言',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '编辑工具',
                    svip: '<div class="iconfont" style="color:#6DD46E;">&#xe848;</div>',
                    vip: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                },
                {
                    tips: '画册模板',
                    svip: '全部',
                    vip: '一半',
                    normal: '<div class="iconfont" style="color:#F94A51;">&#xe84b;</div>'
                }
            ],
            brandData: [
                ['https://cdn.codics.cn/huayuan/img/zcl.png', 'https://cdn.codics.cn/huayuan/img/wdDwWAs.png', 'https://cdn.codics.cn/huayuan/img/apl.png', 'https://cdn.codics.cn/huayuan/img/ali.png', 'https://cdn.codics.cn/huayuan/img/adl.png'],
                ['https://cdn.codics.cn/huayuan/img/%E5%A4%A9%E7%8C%AB%E5%BD%A2%E8%B1%A12.png', 'https://cdn.codics.cn/huayuan/img/global.png', 'https://cdn.codics.cn/huayuan/img/logo.png', 'https://cdn.codics.cn/huayuan/img/logo%20DSS-logo.png'],
                ['https://cdn.codics.cn/huayuan/img/logo%20%281%29.png', 'https://cdn.codics.cn/huayuan/img/logo%20%282%29.png', 'https://cdn.codics.cn/huayuan/img/LOGO%20%283%29.png', 'https://cdn.codics.cn/huayuan/img/logo%20%284%29.png', 'https://cdn.codics.cn/huayuan/img/logo%20%285%29.png']
            ],
            brandData2: [
                ['https://cdn.codics.cn/huayuan/img/logo%20%285%29.png', 'https://cdn.codics.cn/huayuan/img/logo%20%287%29.png', 'https://cdn.codics.cn/huayuan/img/logo%20%286%29.png', 'https://cdn.codics.cn/huayuan/img/myyouthlogo1.png'],
                ['https://cdn.codics.cn/huayuan/img/logo%20%2812%29.png', 'https://cdn.codics.cn/huayuan/img/LOGO%20%2810%29.png', 'https://cdn.codics.cn/huayuan/img/logo%20%289%29.png', 'https://cdn.codics.cn/huayuan/img/LOGO-copy-copy.png', 'https://cdn.codics.cn/huayuan/img/logo%20%288%29.png'],
                ['https://cdn.codics.cn/huayuan/img/logo%20%282%29.png', 'https://cdn.codics.cn/huayuan/img/Logo%20%2815%29.png', 'https://cdn.codics.cn/huayuan/img/logo%20%2814%29.png', 'https://cdn.codics.cn/huayuan/img/logo%20%2813%29.png']
            ],
        }
    },
    mounted() {
        // document.head.innerHTML += `<link rel="stylesheet" href="../../css/sortable.min.css" />`;
        // loadJs('../../js/sortable.min.js');
        // loadJs("setTimeout(()=>{document.querySelector('#sortable').sortablejs();},200);", true);
        const ijs = new isolde({
            responsive: {
                1230: {
                    columns: 4
                },
                1000: {
                    columns: 3
                },
                568: {
                    columns: 2
                },
                0: {
                    columns: 1
                }
            }
        });

        // [__PARENT__].sortablejs({
        //     responsive: {
        //         1170: {
        //             columns: 4
        //         },
        //         768: {
        //             columns: 3
        //         },
        //         0: {
        //             columns: 1
        //         }
        //     }
        // });
    }
}
</script>

<style>
.codics-book-home-home-body {
    height: fit-content;
    width: 100% !important;
    box-sizing: border-box !important;
    position: relative;
    float: left;
    margin-top: -60px;
}

.codics-book-home-home-lunbo {
    height: 80vh;
    width: 100%;
    position: relative;
    float: left;
}

.codics-book-home-home-lunbo-item div {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
}

/* 主页卡片盒子 */
.codics-book-home-home-card-box {
    height: fit-content;
    width: 100%;
    max-width: 1450px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    top: -57.5px;
    z-index: 100;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
}

/* 主页卡片 */
.codics-book-home-home-card {
    height: 115px;
    width: calc(25% - 15px);
    position: relative;
    float: left;
    background: linear-gradient(0deg, #fff, #f3f5f8);
    border: 1px solid #FFF;
    border-radius: 3px;
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
    cursor: pointer;
    user-select: none;
    transition: .4s;
    box-sizing: border-box;
    padding: 5px;
}

.codics-book-home-home-card:hover {
    box-shadow: 2px 2px 5px 0 rgba(55, 99, 170, .1);
}

.codics-book-home-home-card:nth-child(2),
.codics-book-home-home-card:nth-child(3),
.codics-book-home-home-card:nth-child(4) {
    margin-left: 20px;
}

.codics-book-home-home-card-line {
    height: 50px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
}

.codics-book-home-home-card-line-icon {
    height: 100%;
    width: 50px;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 15px;
}

.codics-book-home-home-card-line-title {
    height: 50px;
    width: fit-content;
    position: relative;
    float: left;
    line-height: 50px;
    font-size: 18px;
    color: #181818;
    font-weight: 500;
}

.codics-book-home-home-card-line-txt {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    font-size: 14px;
    color: #4b5b76;
    line-height: 25px;
    box-sizing: border-box;
    padding: 0 15px 0 15px;
    top: -5px;
}

.codics-book-home-home-content {
    height: fit-content;
    width: calc(100% - 40px);
    max-width: 1410px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
    padding: 0 50px 0 50px;
    margin-top: 50px;
}

.codics-book-home-home-content-card {
    height: 300px;
    width: 25%;
    box-sizing: border-box;
    background: #f3f5f8;
    background: linear-gradient(0deg, #fff, #f3f5f8);
    border: 2px solid #FFF;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
}

.codics-book-home-home-content-card:hover {
    box-shadow: 2px 2px 5px 0 rgba(55, 99, 170, .1);
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-book {
    left: calc(50% + 60px);
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-see {
    opacity: 1;
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-book-page:nth-child(1) {
    transform: rotateY(-180deg);
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-book-page:nth-child(2) {
    transform: rotateY(-160deg);
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-book-page:nth-child(3) {
    transform: rotateY(-140deg);
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-book-page:nth-child(4) {
    transform: rotateY(-130deg);
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-book-page:nth-child(5) {
    transform: rotateY(-45deg);
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-book-page:nth-child(6) {
    transform: rotateY(-35deg);
}

.codics-book-home-home-content-card:hover .codics-book-home-home-content-card-book-page:nth-child(7) {
    transform: rotateY(-20deg);
}

.codics-book-home-home-content-card-book {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
    height: auto;
    /*150px;*/
    width: 120px;
    position: relative;
    float: left;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transition: .4s;
    background-color: transparent;
    box-sizing: border-box;
}

.codics-book-home-home-content-card-book-page {
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 0 6px 6px 0;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    transition-duration: 0.4s;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    transform-origin: left center;
    /* background-image: -webkit-linear-gradient(rgba(200, 200, 200, 0.1) 0.1em, transparent 0.1em), -webkit-linear-gradient(left, #f5f3f1 0%, #ffffff 100%); */
    background-size: 100% 0.6em;
    box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.2), inset -1px 0px 1px rgba(150, 150, 150, 0.3);
    background: -webkit-linear-gradient(left, #FFF 0%, #fffbf6 100%);
    background: -moz-linear-gradient(left, #FFF 0%, #fffbf6 100%);
    background: -ms-linear-gradient(left, #FFF 0%, #fffbf6 100%);
    background: linear-gradient(left, #FFF 0%, #fffbf6 100%);
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.codics-book-home-home-content-card-book-page:nth-child(1) {
    backface-visibility: hidden;
}

/* .codics-book-home-home-content-card-book-page:nth-child(1)::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    z-index: 1;
    box-shadow: inset 0px -1px 2px rgba(50, 50, 50, 0.2), inset -1px 0px 1px rgba(150, 150, 150, 0.3);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
} */

.codics-book-home-home-content-card-vip {
    height: 30px;
    width: fit-content;
    position: absolute;
    top: 5px;
    right: 5px;
    /* border: 1px solid #FFF; */
    background: rgba(0, 0, 0, 0);
    /* border-radius: 2px; */
    box-sizing: border-box;
    text-align: center;
    padding: 0 10px 0 10px;
}

.codics-book-home-home-content-card-vip-txt {
    line-height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 11px;
    color: #333;
    font-weight: 500;
    position: relative;
    float: right;
    margin-left: 6px;
}

.codics-book-home-home-content-card-see {
    height: fit-content;
    width: fit-content;
    position: absolute !important;
    margin: auto !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: 2s;
    z-index: 1111;
}

.codics-book-home-home-more-btn-box {
    position: relative;
    height: fit-content;
    width: 100%;
    float: left;
    margin-top: 100px;
    box-sizing: border-box;
}

.codics-book-home-home-more-btn {
    position: relative;
    height: fit-content;
    width: 90%;
    max-width: 500px;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box;
}

.codics-book-home-home-content-card-bottom {
    height: 30px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

}

.codics-book-home-home-content-card-bottom-txt {
    height: 100%;
    width: fit-content;
    position: relative;
    float: right;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    padding: 0 10px 0 10px;
}

.codics-book-home-totop-box {
    height: 100px;
    width: 50px;
    position: fixed;
    right: 60px;
    bottom: 60px;
    box-sizing: border-box;
    z-index: 1000;
}

.codics-book-home-totop-card {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 0;
    box-sizing: border-box;
    padding: 5px;
    user-select: none;
    cursor: pointer;
}

.codics-book-home-totop-card-icon {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #FFF;
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
    background: linear-gradient(0deg, #fff, #f3f5f8);
}

.codics-book-home-totop-card-qrcode {
    height: 100px;
    width: 100px;
    position: absolute;
    top: 0;
    left: -100px;
    box-sizing: border-box;
    padding: 5px;
    border: 1px solid #FFF;
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
    background: linear-gradient(0deg, #fff, #f3f5f8);
    opacity: 0;
    pointer-events: none !important;
    transition: .4s;
}

.codics-book-home-totop-card-qr:hover+.codics-book-home-totop-card-qrcode {
    opacity: 1;
}

.codics-book-home-divider {
    width: calc(100% - 30px);
    position: relative;
    float: left !important;
    max-width: 1410px;
    left: 50%;
    transform: translateX(-50%);
}

.codics-book-home-divider2 {
    margin-top: 50px !important;
}

.codics-book-home-divider div {
    user-select: none;
    font-size: 17px;
    color: #4b5b76;
    background-color: #f8f8f8 !important;
}


.codics-book-home-home-package {
    height: fit-content;
    line-height: 40px;
    width: 100%;
    position: relative;
    float: left;
    margin-top: 50px !important;
    margin-bottom: 15px;
    box-sizing: border-box;
    max-width: 1410px;
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #d3d1d0;
}

.codics-book-home-home-package,
.codics-book-home-home-package td,
.codics-book-home-home-package th {
    border-collapse: collapse;
    border-spacing: 10px;
    /* border: 1px solid #DDD; */
    border-left: 1px solid #d3d1d0;
    border-right: 1px solid #d3d1d0;
    min-width: 45px;
    text-align: center;
}

.codics-book-home-home-package td {
    padding-left: 10px;
}

.codics-book-home-home-package tr:nth-child(even) {
    background-color: #FBF9F8 !important;
}

.codics-book-home-home-package tr:nth-child(odd) {
    background-color: #ffffff !important;
}

.codics-book-home-home-package-vip-tag {
    height: 30px;
    width: 50px;
    position: absolute !important;
    left: 0px;
    top: 2px;
    align-content: start;
}

.codics-book-home-home-content-card-qr:hover~.codics-book-home-home-content-card-qr-img {
    transform: scale(1) !important;
    opacity: 1 !important;
}

.codics-book-home-home-content-card-qr:hover~.codics-book-home-home-content-card-see {
    opacity: 0 !important;
    transform: scale(.6) !important;
}

.codics-book-home-home-custom {
    height: fit-content;
    min-height: 470px;
    width: 100%;
    position: relative;
    float: left;
    margin-top: 50px;
    overflow: hidden;
}

.codics-book-home-home-custom::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #f8f8f8, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    z-index: 1;
}

.codics-book-home-home-custom::after {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), #f8f8f8);
}

.codics-book-home-home-custom-title {
    height: 70px;
    width: 100%;
    text-align: center;
    line-height: 70px;
    position: relative;
    float: left;
    font-size: 24px;
    font-weight: bolder;
    color: #666;
    user-select: none;
    margin-top: 60px;
    z-index: 2;
}

.codics-book-home-home-custom-tip {
    height: 50px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #999;
    text-align: center;
    user-select: none;
    z-index: 2;
}

.codics-book-home-home-customer-box {
    height: fit-content;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    will-change: transform;
    animation: huaDongDH 20s linear infinite;
}

.codics-book-home-home-customer-card {
    height: 470px;
    width: 100%;
    position: relative;
    float: left;
}

.codics-book-home-home-customer-img {
    height: 100%;
    width: 20%;
    position: relative;
    float: left;
    left: -10000px;
    overflow: hidden;
    filter: drop-shadow(10000px 0px 0 #EAEAED);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2%;
    box-sizing: border-box;
}

.codics-book-home-home-customer-img img {
    transform: scale(.94);
}

.codics-book-home-home-customer-card-line {
    height: 33.3333%;
    width: 100%;
    position: relative;
    float: left;
}

.codics-book-home-home-card-box2 {
    top: 0 !important;
    margin-top: 30px !important;
}

@keyframes huaDongDH {
    0% {
        top: 0px;
    }

    100% {
        top: -940px;
    }
}

@media (max-width:768px) {
    .codics-book-home-home-card {
        width: 100%;
        margin-left: unset !important;
        margin-bottom: 15px;
    }

    .codics-book-home-totop-box {
        right: 20px !important;
        bottom: 20px !important;
    }
}
</style>