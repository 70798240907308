<template>
    <div class="codics-admin-product-brand-body">
        <div class="codics-admin-product-brand-control">
            <el-button class="codics-admin-product-brand-control-search" type="success" icon="el-icon-plus"
                @click="addProductDialogVisibility = true;">添加品牌</el-button>
            <!-- 添加表单 -->
            <el-dialog title="添加商品品牌" :visible.sync="addProductDialogVisibility" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="品牌名称" :label-width="formLabelWidth">
                        <el-input v-model="addForm.l" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品品牌" :label-width="formLabelWidth">
                        <el-cascader :options="productBrandList" clearable @change="handleSubCat"></el-cascader>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addProductDialogVisibility = false">取 消</el-button>
                    <el-button type="primary" @click="addProductBrandFuc">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <el-table stripe class="codics-admin-product-brand-table" :data="productBrandList" border style="width: 100%"
            row-key="value" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="value" label="主键" width="180" style="user-select: none;">
            </el-table-column>
            <el-table-column prop="label" label="名称" width="180">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteProductBrandFuc([scope.row.value])">
                        <el-button v-show="scope.row.children == null" size="mini" type="danger"
                            slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-product-brand-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
            :page-size="dataSize" layout="total, sizes, prev, pager, next, jumper" :total="maxSize">
        </el-pagination>
    </div>
</template>

<script>
import { getProductBrandList, addProductBrand, deleteProductBrand } from '@/api';
export default {
    data() {
        return {
            searchTxt: '',
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            formLabelWidth: '120px',
            searchBrand: '名称',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            productBrandList: []
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.getProductBrandsData();

    },
    methods: {
        async getProductBrandsData() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await getProductBrandList();

            loading.close();

            if (data.code == 200) {

                this.productBrandList = data.data;

                this.maxSize = data.count;

                this.hasChildren(this.productBrandList);

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getUserListData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserListData(val, this.dataSize);
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductBrandFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '品牌名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await addProductBrand(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '添加商品品牌成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        hasChildren(list) {

            for (var i = 0; i < list.length; i++) {

                if (list[i]?.children != null) {

                    // list[i].hasChildren = true;

                    this.hasChildren(list[i].children);

                } else {

                    // delete list[i].children

                }

            }

        },
        async deleteProductBrandFuc(t) {

            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await deleteProductBrand({ ids: t });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }
        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-product-brand-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-product-brand-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 后台管理页面主页控制栏 */
.codics-admin-product-brand-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-product-brand-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-product-brand-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-product-brand-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>