<template>
    <div class="codics-firm-cdk-receive-body">
        <div class="codics-firm-cdk-receive-control">
            <el-input class="codics-firm-cdk-receive-control-input" style="width: 360px !important;"
                placeholder="请输入要搜索的订单/领用人/卡券信息..." v-model="searchTxt" clearable @input="searchChange">
                <el-button
                    @click="searchPage = 1; searchMax = 0; searchData = []; nowSearchTxt = searchTxt; listCdkUseFuc();"
                    slot="append" icon="el-icon-search" style="padding: 0 25px 0 25px !important;"></el-button>
            </el-input>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteCdksFuc">
                <el-button class="codics-firm-cdk-receive-control-search" slot="reference" type="danger"
                    icon="el-icon-delete">批量删除</el-button>
            </el-popconfirm>
        </div>
        <el-table stripe class="codics-firm-cdk-receive-table" :data="cdkUseData" border style="width: 100%"
            @expand-change="init_chart">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <fieldset class="codics-firm-cdk-receive-info">
                        <legend>领用记录</legend>
                        <table>
                            <tbody>
                                <tr v-for="(item, index) in props.row.list" :key="index">
                                    <td width="50px">{{ item.id }}</td>
                                    <td width="220px">{{ item.oid }}</td>
                                    <td width="50px">{{ item.cid }}</td>
                                    <td width="300px">{{ item.createtime }}</td>
                                    <td v-if="index == 0"
                                        style="color: #E6A23C;cursor: pointer;min-height: 300px !important;"
                                        :rowspan="props.row.list.length">
                                        <div :id="'myChart' + props.row.id" class="my-chart"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                </template>
            </el-table-column>
            <el-table-column type="selection" width="45">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="oid" label="订单号" width="220">
            </el-table-column>
            <el-table-column prop="remark" label="备注信息" width="180">
            </el-table-column>
            <el-table-column prop="createtime" label="创建时间" width="250">
            </el-table-column>
            <el-table-column prop="account" label="领用人" width="210" style="line-height: 20px;">
                <template slot-scope="scope">
                    <el-image style="height: 30px;width: 30px;position: relative;float: left;"
                        :src="scope.row?.account?.himg == null ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : scope.row?.account?.himg"
                        fit="fill">
                    </el-image>
                    <div style="margin-top: 5px;position: relative;float: left;margin-left: 5px;">{{
                    scope.row?.account?.nickname }}</div>
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？">
                        <el-button size="mini" type="danger" slot="reference"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-firm-cdk-receive-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
            layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax"
            :pager-count="5">
        </el-pagination>
    </div>
</template>

<script>
import { getProductTypeList, addProductType, listCdkUse } from '@/api';
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    data() {
        return {
            addProductDialogVisibility: false,
            addForm: {
            },
            formLabelWidth: '120px',
            searchTxt: '',
            nowSearchTxt: '',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10,
            cdkUseData: [],
            searchData: [],
            chartOption: {
                series: [
                    {
                        type: 'pie',
                        data: [
                            {
                                value: 335,
                                name: '直接访问'
                            },
                            {
                                value: 234,
                                name: '联盟广告'
                            },
                            {
                                value: 1548,
                                name: '搜索引擎'
                            }
                        ]
                    }
                ]
            },
            selectData: []
        }
    },
    mounted() {

        this.$emit('check-mem');

        this.listCdkUseFuc();

    },
    methods: {
        async listCdkUseFuc() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listCdkUse({ pge: this.nowSearchTxt == '' ? this.currentPage : this.searchPage, psz: this.nowSearchTxt == '' ? this.dataSize : this.searchSize, key: this.nowSearchTxt });

            loading.close();

            if (data.code == 200) {

                this.hasChildren(data.data);

                if (this.nowSearchTxt == '') {

                    this.cdkUseData = data.data;

                } else {

                    this.searchData = data.data;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getUserListData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserListData(val, this.dataSize);
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        hasChildren(list) {

            for (var i = 0; i < list.length; i++) {

                if (list[i]?.list != null && list[i]?.list.length != 0) {

                    list[i].hasChildren = true;

                    this.hasChildren(list[i].list);

                } else {

                    delete list[i].list

                    list[i].hasChildren = false;

                }

            }

        },
        init_chart(a, b) {
            if (b.includes(a)) {
                setTimeout(() => {
                    var chartDom = document.getElementById("myChart" + a.id);
                    var myChart = echarts.init(chartDom);
                    var option;
                    option = {
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            top: '5%',
                            left: 'center'
                        },
                        series: [
                            {
                                name: 'Access From',
                                type: 'pie',
                                radius: ['40%', '70%'],
                                avoidLabelOverlap: false,
                                padAngle: 5,
                                itemStyle: {
                                    borderRadius: 10
                                },
                                label: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: 40,
                                        fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: [
                                    { value: a.used, name: '已兑换' },
                                    { value: a.list.length - a.used, name: '未兑换' }
                                ]
                            }
                        ]
                    };

                    option && myChart.setOption(option);
                }, 30)
            }
        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
                this.nowSearchTxt = '';
                this.changePassState(this.passState);
            }
        }
    },
    components: {
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-firm-cdk-receive-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-firm-cdk-receive-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-firm-cdk-receive-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-firm-cdk-receive-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-firm-cdk-receive-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-firm-cdk-receive-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-firm-cdk-receive-info {
    height: fit-content;
    width: calc(100% - 30px);
    box-sizing: border-box;
    position: relative;
    float: left;
    left: 15px;
    border: 1px solid #DCDFE6;
    padding-bottom: 0px;
    font-size: 14px !important;
    padding: 0 15px 0 15px;
}

.codics-firm-cdk-receive-info table {
    line-height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.codics-firm-cdk-receive-info table,
.codics-firm-cdk-receive-info td,
.codics-firm-cdk-receive-info th {
    border-collapse: collapse;
    border-spacing: 10px;
    border: 1px solid #DDD;
    min-width: 45px;
}

.codics-firm-cdk-receive-info td {
    padding-left: 10px;
}

.codics-firm-cdk-receive-info table tr:nth-child(even) {
    background: #f8f8f8;
}

.my-chart {
    height: 400px;
    width: 100%;
}
</style>