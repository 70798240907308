<template>
    <div class="codics-admin-home-body">
        <div class="codics-admin-home-control">
            <el-input class="codics-admin-home-control-input" style="width: 300px !important;"
                placeholder="请输入账号/昵称/邮箱/手机号..." v-model="searchTxt" clearable @input="searchChange">
                <el-button style="padding: 0 30px 0 30px;" slot="append" icon="el-icon-search" @click="searchPage=1;searchMax=0;searchData=[];searchUsersFuc();"></el-button>
            </el-input>
            <!-- <el-input class="codics-admin-home-control-input" placeholder="请输入内容" v-model="input" clearable>
            </el-input>
            <el-input class="codics-admin-home-control-input" placeholder="请输入内容" v-model="input" clearable>
            </el-input> -->
            <el-dropdown class="codics-admin-home-control-search" split-button type="primary" @click="handleClick">
                <i class="iconfont" style="font-size: 14px;">&#xe775;</i> 批量冻结
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>批量封禁</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-button class="codics-admin-home-control-search" type="warning" icon="el-icon-key">重置密码</el-button>
            <el-button class="codics-admin-home-control-search" type="danger" icon="el-icon-delete">批量删除</el-button>
        </div>
        <el-table stripe class="codics-admin-home-table" :data="searchTxt == '' ? tableData : searchData" border
            style="width: 100%">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="account" label="账号" width="180">
            </el-table-column>
            <el-table-column prop="himg" label="头像" width="60">
                <template slot-scope="scope">
                    <el-image style="top:3.5px"
                        :src="scope.row.himg == null ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : scope.row.himg"
                        fit="fill">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="nickname" label="昵称" width="180">
            </el-table-column>
            <el-table-column prop="sex" label="性别" width="70"
                :filters="[{ text: '未知', value: 0 }, { text: '男性', value: 1 }, { text: '女性', value: 2 }]"
                :filter-method="sexFilter" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.sex == 0 ? 'info' : (scope.row.sex == 1 ? '' : 'danger')"
                        disable-transitions>{{ scope.row.sex == 0 ? '未知' :
                    (scope.row.sex == 1 ? '男性' : '女性') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="member" label="权限" width="70"
                :filters="[{ text: '管理', value: 0 }, { text: '商户', value: 1 }, { text: '用户', value: 2 }]"
                :filter-method="memberFilter" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.member == 0 ? 'danger' : (scope.row.member == 1 ? 'warning' : '')"
                        disable-transitions>{{ scope.row.member == 0 ?
                    '管理' : (scope.row.member == 1 ? '商户' : '用户') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="state" label="状态" width="70"
                :filters="[{ text: '正常', value: 0 }, { text: '冻结', value: 1 }, { text: '封禁', value: 2 }]"
                :filter-method="stateFilter" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? '' : (scope.row.state == 1 ? 'info' : 'danger')"
                        disable-transitions>{{ scope.row.state == 0 ? '正常'
                    : (scope.row.state == 1 ? '冻结' : '封禁') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="vip" label="会员" sortable width="80">
            </el-table-column>
            <el-table-column prop="wxkey" label="微信" width="80"
                :filters="[{ text: '已绑定', value: 0 }, { text: '未绑定', value: 1 }]" :filter-method="wxFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.wxkey != null ? '' : 'warning'" disable-transitions>{{ scope.row.wxkey !=
                    null
                    ? '已绑定'
                    : '未绑定' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="qkey" label="QQ" width="80"
                :filters="[{ text: '已绑定', value: 0 }, { text: '未绑定', value: 1 }]" :filter-method="qqFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.qkey != null ? '' : 'warning'" disable-transitions>{{ scope.row.qkey !=
                    null ?
                    '已绑定'
                    : '未绑定' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="email" label="邮箱" width="200">
            </el-table-column>
            <el-table-column prop="phone" label="手机" width="110">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-popconfirm title="您确定要重置当前账号的密码为123456吗？" @confirm="resetPass(scope.$index, scope.row)">
                        <el-button size="mini" slot="reference" style="margin-right: 10px;">重置</el-button>
                    </el-popconfirm>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-home-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
            :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
    </div>
</template>

<script>
import { getUserList, rePass, searchUsers2 } from '@/api';
export default {
    data() {
        return {
            currentPage: 1,
            searchPage: 1,
            searchTxt: '',
            tableData: [],
            searchData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            dataSize: 10,
            searchSize: 10
        }
    },
    mounted() {
        this.getUserListData(1, this.dataSize);
    },
    methods: {
        async searchUsersFuc() {

            if(this.searchTxt==''){

                this.$message.error('请先输入要搜索的内容！');

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await searchUsers2({ key: this.searchTxt, pge: this.searchPage, psz: this.searchSize });
            loading.close();
            if (data.code == 200) {
                this.searchData = data.data;
                this.searchMax = data.count;
            } else {
                this.$message.error(data.message);
            }

        },
        async getUserListData(pge, psz) {

            let data = await getUserList({ 'pge': pge, 'psz': psz });

            if (data.code == 200) {

                this.tableData = data.data;

                this.maxSize = data.count;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.searchTxt == '') {
                this.dataSize = val;
                this.getUserListData(this.currentPage, val);
            } else {
                this.searchSize = val;
                this.searchUsersFuc();
            }
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
                this.getUserListData(val, this.dataSize);
            } else {
                this.searchPage = val;
                this.searchUsersFuc();
            }
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        memberFilter(v, r, c) {

            return r.member === v;

        },
        stateFilter(v, r, c) {

            return r.state === v;

        },
        wxFilter(v, r, c) {

            let t = r.wxkey === null || r.wxkey === '';

            return v == 0 ? !t : t;

        },
        qqFilter(v, r, c) {

            let t = r.qkey === null || r.qkey === '';

            return v == 0 ? !t : t;

        },
        async resetPass(idx, row) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await rePass({ id: row.id });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '账号密码重置成功！',
                    type: 'success'
                });
            } else {
                this.$message.error(data.message);
            }

        },
        searchChange(a) {
            if (a == '') {
                this.searchPage = 1;
                this.searchData = [];
                this.searchMax = 0;
                this.searchSize = 10;
            }
        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-home-body {
    height: fit-content;
    width: 100%;
    min-height: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-home-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-admin-home-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-home-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-home-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-home-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>