<template>
    <div class="i-zyl-com-my-loader" @click="handleClick" :style="{ 'background': backColor }">
        <span class="i-zyl-com-my-loader-one"></span>
        <div class="i-zyl-com-my-loader-txt">{{ showTxt }}</div>
    </div>
</template>

<script>
export default {
    props: {
        backColor: {
            type: String,
            default: 'rgba(0,0,0,.8)'
        },
        showTxt: {
            type: String,
            default: '加载中,请耐心等待...'
        }
    },
    methods: {
        handleClick() {
            this.$emit('click');
        }
    }
}
</script>

<style>
.i-zyl-com-my-loader {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000 !important;
}

.i-zyl-com-my-loader-one {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: absolute;
    margin: auto;
    top: -10%;
    left: 0;
    right: 0;
    bottom: 0;
    transform: rotate(45deg);
}

.i-zyl-com-my-loader-one::before {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: -24px;
    -webkit-animation: animloader59 4s ease infinite;
    animation: animloader59 4s ease infinite;
}

.i-zyl-com-my-loader-one::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    -webkit-animation: animloader56 2s ease infinite;
    animation: animloader56 2s ease infinite;
}

.i-zyl-com-my-loader-txt {
    height: 50px;
    width: fit-content;
    position: absolute;
    margin: auto;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    line-height: 50px;
    font-size: 15px;
    color: #FFF;
    user-select: none;
}

@-webkit-keyframes animloader56 {
    0% {
        transform: translate(0, 0) rotateX(0) rotateY(0);
    }

    25% {
        transform: translate(100%, 0) rotateX(0) rotateY(180deg);
    }

    50% {
        transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
    }

    75% {
        transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
    }

    100% {
        transform: translate(0, 0) rotateX(0) rotateY(360deg);
    }
}

@keyframes animloader56 {
    0% {
        transform: translate(0, 0) rotateX(0) rotateY(0);
    }

    25% {
        transform: translate(100%, 0) rotateX(0) rotateY(180deg);
    }

    50% {
        transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
    }

    75% {
        transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
    }

    100% {
        transform: translate(0, 0) rotateX(0) rotateY(360deg);
    }
}

@-webkit-keyframes animloader59 {
    0% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }

    12% {
        box-shadow: 0 24px white, 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }

    25% {
        box-shadow: 0 24px white, 24px 24px white, 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }

    37% {
        box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px rgba(255, 255, 255, 0);
    }

    50% {
        box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px white;
    }

    62% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px white, 24px 48px white, 0px 48px white;
    }

    75% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px white, 0px 48px white;
    }

    87% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px white;
    }

    100% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }
}

@keyframes animloader59 {
    0% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }

    12% {
        box-shadow: 0 24px white, 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }

    25% {
        box-shadow: 0 24px white, 24px 24px white, 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }

    37% {
        box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px rgba(255, 255, 255, 0);
    }

    50% {
        box-shadow: 0 24px white, 24px 24px white, 24px 48px white, 0px 48px white;
    }

    62% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px white, 24px 48px white, 0px 48px white;
    }

    75% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px white, 0px 48px white;
    }

    87% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px white;
    }

    100% {
        box-shadow: 0 24px rgba(255, 255, 255, 0), 24px 24px rgba(255, 255, 255, 0), 24px 48px rgba(255, 255, 255, 0), 0px 48px rgba(255, 255, 255, 0);
    }
}
</style>