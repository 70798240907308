<template>
    <div style="height: 100%;width: 100%;box-sizing: border-box;overflow: hidden;">
        <el-image style="height: 100%;width: 100%;position: absolute;left: 0;top: 0;"
            src="https://cdn.codics.cn/huayuan/img/24-3-26-%E7%BA%BF%E4%B8%8A%E6%8F%90%E9%A2%86%E7%95%8C%E9%9D%A2%E8%83%8C%E6%99%AF.jpg"
            fit="fill"></el-image>
        <div class="codics-shop-exchange-body">
            <el-image class="codics-shop-exchange-logo"
                :src="isShop ? (shopInfo?.icon == '' ? 'https://cdn.codics.cn/huayuan/img/ee0e00e9b8cf925a3f056c0bcb7e4ac.png' : shopInfo?.icon) : logo"
                fit="fill"></el-image>
            <el-input class="codics-shop-exchange-input" placeholder="请输入16位兑换码" v-model="formData.cdkValue">
                <a slot="suffix" class="iconfont codics-shop-exchange-id-scan"
                    href="javascript:window.open('//996315.com/api/scan/?redirect_uri=' + encodeURIComponent(location.href), '_self');">
                    &#xe748;</a>
            </el-input>
            <el-input class="codics-shop-exchange-input" placeholder="请输入验证码" v-model="formData.verify">
                <template slot="append">
                    <div @click="refreshCode()">
                        <SecurityCode class="login-input-card-code" style="position: relative;"
                            :identifyCode="identifyCode">
                        </SecurityCode>
                    </div>
                </template>
            </el-input>
            <el-button class="codics-shop-exchange-xstl" type="warning" plain @click="queryCdk">线上领取</el-button>
        </div>
        <div style="height: 30px;width: 100px;position: absolute;margin:auto;left:0;right:0;bottom: 90px;line-height: 30px;text-align: center;color: #333;font-size: 14px;user-select: none;cursor: pointer;background: #FFF;border-radius: 15px;"
            @click="yhxzDialog = true;">用户须知
            <el-image style="position: absolute;height: 100px;width: 90px;top: -40px;right: -100px;"
                src="https://cdn.codics.cn/huayuan/img/%E5%B0%8F%E6%9D%BE%E9%BC%A0.png" fit="fill"></el-image>
        </div>
        <el-drawer title="用户须知" :visible.sync="yhxzDialog" direction="btt" size="calc(100% - 60px)">
            <el-image
                src="https://cdn.codics.cn/huayuan/img/24-4-9-%E7%BA%BF%E4%B8%8A%E5%88%B8%E5%85%91%E6%8D%A2%E6%B5%81%E7%A8%8B.jpg"></el-image>
        </el-drawer>
        <div class="codics-shop-bottom-txt">Copyright © 2024 <a
                :href="(isShop && shopInfo?.url != '') ? shopInfo?.url : '#'"
                style="text-decoration: none;color: #FFF;">{{ isShop ? shopInfo?.name : '华远创智' }}</a></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SecurityCode from '@/components/SecurityCode';
import { jumpTo } from '@/utils/myutils';
import { getFirmInfo } from '@/api';
export default {
    components: {
        SecurityCode
    },
    data() {
        return {
            logo: 'https://cdn.codics.cn/huayuan/img/PicsArt_03-25-05.35.37.png',
            formData: {
                cdkValue: this.$route.query.k,
                cdkPass: this.$route.query.p,
                phone: '',
                verify: '',
                path: 'shop-exchange'
            },
            identifyCode: "",
            yhxzDialog: false,
            isShop: false,
            shopInfo: {
                icon: ''
            }
        }
    },
    mounted() {

        if (this.formData.cdkValue != null && this.formData.cdkPass != null) {

            jumpTo(this, '卡券详情', this.formData, {'p':this.formData.cdkPass});

        }

        this.refreshCode();

        var qr = GetQueryString("qrresult");

        if (qr != undefined && window.location.href != qr) {

            var reg = /(\d+)$/;

            this.formData.cdkValue = qr.match(reg)[0];

            console.log(this.formData.cdkValue);

        }

        function GetQueryString(name) {
            var reg = new RegExp("\\b" + name + "=([^&]*)");
            var r = location.href.match(reg);
            if (r != null) return decodeURIComponent(r[1]);
        }

        this.isShop = !isNaN(Number(this.$route.query.u));

        if (this.isShop) {

            this.getFirmInfoFuc();

        }

    },
    methods: {
        async getFirmInfoFuc() {

            let data = await getFirmInfo({ id: this.$route.query.u });

            if (data.code == 200) {

                this.shopInfo = data.data;

            } else {

                this.$message.error(data.message);

            }

        },
        queryCdk() {
            if (this.formData.cdkValue == null || this.formData.cdkValue == '' || this.formData.verify == '') {

                this.$message({
                    message: '商品兑换码及验证码不能为空！',
                    type: 'warning',
                    showClose: true
                });

                return true;

            }

            if (this.formData.verify.toLowerCase() != this.identifyCode.toLowerCase()) {

                this.$message({
                    showClose: true,
                    message: '验证码输入不正确！',
                    type: 'error'
                });

                return true;

            }

            jumpTo(this, '卡券详情', this.formData, {});

        },
        //初始化验证码
        refreshCode() {
            this.inputCode = ''
            this.identifyCode = ""; //输入框置空
            this.makeCode(4); //验证码长度为4
        },
        //随机切换验证码
        makeCode(length) {
            let code = '';
            for (let i = 0; i < length; i++) {
                const r = Math.floor(Math.random() * 10);
                if (r < 10) {
                    code += r;
                } else {
                    code += String.fromCharCode(r - 10 + 65);
                }
            }
            this.identifyCode = code;
            localStorage.setItem('code', this.identifyCode);
        }
    },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>

<style>
/* 商城兑换网页体 */
.codics-shop-exchange-body {
    height: fit-content;
    width: fit-content;
    position: relative;
    float: left;
    max-width: 800px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-60%);
}

/* 商城兑换logo */
.codics-shop-exchange-logo {
    height: 100px;
    width: 212px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 20px;
}

/* 商城兑换输入框 */
.codics-shop-exchange-input {
    max-width: 85%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
    border-color: coral;
}

/* 商城兑换输入框扫码控件 */
.codics-shop-exchange-id-scan {
    height: 100%;
    width: 40px;
    position: absolute;
    right: 0;
    line-height: 40px;
    cursor: pointer;
    text-decoration: none;
    color: #333;
}

/* 商城兑换按钮 */
.codics-shop-exchange-xstl {
    width: 100%;
    max-width: 85%;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 60px !important;
}

/* 商城底栏版权文本 */
.codics-shop-bottom-txt {
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    line-height: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: #FFF;
}
</style>