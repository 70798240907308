<template>
    <transition name="webdialog" v-if="show">
        <!--    绑定style中top和left-->
        <div ref="myComponent" class="codics-webdialog-SsjdjiIIs-moveBox"
            :style="full ? `top:0px;left:0px;height:100%;width:100%;` : position">
            <div class="codics-webdialog-SsjdjiIIs-moveHead" @mousedown="mousedown" @mousemove="mousemove"
                @mouseup="mouseup" @mouseleave="mousemove">
                <div class="codics-webdialog-SsjdjiIIs-title" v-html="'当前客服忙线...'"></div>
                <div class="codics-webdialog-SsjdjiIIs-icon iconfont" @mousemove="stopmove" style="font-size: 20px;"
                    @click="toggleShow(1);">
                    &#xe84a;</div>
                <div class="codics-webdialog-SsjdjiIIs-icon iconfont" @mousemove="stopmove" @click="toggleShow(0);">{{
        full ? '&#xe802;' :
            '&#xe803;' }}</div>
            </div>
            <!-- <slot>插槽</slot> -->
            <div class="codics-webdialog-SsjdjiIIs-content">
                <div class="codics-webdialog-SsjdjiIIs-item" v-for="(item, index) in ltData" :key="index">
                    <div class="codics-webdialog-SsjdjiIIs-item-kf" v-show="item.type == 0">
                        <div class="codics-webdialog-SsjdjiIIs-item-head-box">
                            <el-image class="codics-webdialog-SsjdjiIIs-item-head"
                                src="http://demo.sucaihuo.com/jquery/22/2229/demo/images/en_3.png"></el-image>
                        </div>
                        <div class="codics-webdialog-SsjdjiIIs-item-content">
                            <div class="codics-webdialog-SsjdjiIIs-item-txt" v-html="item.txt"></div>
                        </div>
                    </div>
                    <div class="codics-webdialog-SsjdjiIIs-item-yh" v-show="item.type == 1">
                        <div class="codics-webdialog-SsjdjiIIs-item-head-box" style="float: right !important;">
                            <el-image class="codics-webdialog-SsjdjiIIs-item-head"
                                src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-image>
                        </div>
                        <div class="codics-webdialog-SsjdjiIIs-item-content" style="float: right !important;">
                            <div class="codics-webdialog-SsjdjiIIs-item-txt"
                                style="border-radius: 20px 0 20px 20px !important;" v-html="item.txt"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="codics-webdialog-SsjdjiIIs-bottom">
                <div class="codics-webdialog-SsjdjiIIs-input">
                    <input type="text" placeholder="请输入要咨询的内容" v-model="srTxt">
                </div>
                <div class="codics-webdialog-SsjdjiIIs-input-send iconfont" @click="sendMess">&#xe6e8;</div>
            </div>
        </div>
    </transition>
</template>
<script>
import full from 'core-js/full';
import { mapState } from 'vuex';
export default {
    name: 'moveBox',
    props: {
        title: { //对话框标题
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '300px'
        },
        width: {
            type: String,
            default: '500px'
        },
        full: {
            type: Boolean,
            value: false
        }
    },
    data() {
        return {
            show: false,
            srTxt: '',
            x: 100,
            y: 50,
            leftOffset: 0, //鼠标距离移动窗口左侧偏移量
            topOffset: 0, //鼠标距离移动窗口顶部偏移量
            isMove: false, //是否移动标识
            ltData: [
                {
                    id: 1,
                    name: '华远客服',
                    type: 0,
                    txt: '正在帮您转接客服！<a class="el-icon-loading"></a>'
                }
            ],
            websocket: null
        }
    },
    computed: {
        //top与left加上px
        position() {
            return `top:${this.y}px;left:${this.x}px;height:${this.height};width:${this.width};`
        },
        ...mapState(['userInfo'])
    },
    mounted() {

    },
    methods: {
        //控制打开关闭
        toggleShow(data) {
            if (this.websocket == null) {
                var _this = this;
                //判断当前浏览器是否支持WebSocket
                if ('WebSocket' in window) {
                    this.websocket = new WebSocket("ws://hyapi.posto.cn/api/custom/" + (this.userInfo.user.member==3 ? '0' : '1'));
                } else {
                    this.addMess(0, "当前浏览器不支持websocket！请更换浏览器再尝试接入客服！");
                }
                //连接发生错误的回调方法
                this.websocket.onerror = function () {
                    _this.addMess(0, "Connect error!");
                };

                //连接成功建立的回调方法
                this.websocket.onopen = function (event) {
                    _this.ltData = [];
                    // setMessageInnerHTML("连接已建立！");
                }

                //接收到消息的回调方法
                this.websocket.onmessage = function (event) {
                    var result = event.data
                    var ob = JSON.parse(result);
                    //判断用户状态
                    if (ob.state != undefined && ob.state != "success" && _this.websocket != null) {
                        _this.addMess(0, "非法连接！");
                        _this.websocket.close();
                    }

                    //判断是否有消息
                    if (ob.msg != undefined) {
                        _this.addMess(0, ob.msg);
                    }
                }

                //连接关闭的回调方法
                this.websocket.onclose = function () {
                    _this.addMess(0, "close");
                }

                //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
                window.onbeforeunload = function () {
                    if (_this.websocket != null)
                        _this.websocket.close();
                }

                //关闭连接
                function closeWebSocket() {
                    if (_this.websocket != null)
                        _this.websocket.close();
                }
            }
            if (data == 0) { this.full = !this.full; } else { this.show = !this.show };
            if (this.full || this.show) {
                setTimeout(() => {
                    this.x = (window.innerWidth - this.$refs.myComponent.offsetWidth) / 2;
                    this.y = (window.innerHeight - this.$refs.myComponent.offsetHeight) / 2;
                }, 10);
            }
            if (data == 1 && this.websocket != null) {
                this.websocket.close();
                this.websocket = null;
                this.ltData = [
                    {
                        id: 1,
                        name: '华远客服',
                        type: 0,
                        txt: '正在帮您转接客服！<a class="el-icon-loading"></a>'
                    }
                ];
            }
        },
        mousedown(event) {
            //鼠标按下事件
            this.leftOffset = event.offsetX
            this.topOffset = event.offsetY
            this.isMove = true;
        },
        //鼠标移动
        mousemove(event) {
            if (!this.isMove && !this.full) {
                return true;
            }
            this.x = event.clientX - this.leftOffset
            this.y = event.clientY - this.topOffset
            event.stopPropagation()
        },
        //鼠标抬起
        mouseup() {
            this.isMove = false
        },
        stopmove(event) {
            event.stopPropagation();
        },
        addMess(type, txt) {
            this.ltData.push({
                id: this.ltData.length,
                name: '1',
                type: type,
                txt: txt
            });
        },
        sendMess() {
            this.addMess(1, this.srTxt);
            this.websocket.send(this.srTxt);
            this.srTxt = '';
        }
    }
}
</script>
<style>
.codics-webdialog-SsjdjiIIs-moveBox {
    position: fixed;
    z-index: 99998;
    background: rgb(255, 255, 255);
    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, .1);
    box-sizing: border-box;
    background: #EBECF0;
    border: 2px solid #FFF;
    overflow: hidden;
}

.codics-webdialog-SsjdjiIIs-moveHead {
    height: 50px;
    width: 100%;
    position: relative;
    float: left;
    user-select: none !important;
    line-height: 50px;
    box-sizing: border-box;
    box-shadow: 1px 1px 8px #BABECC;
    cursor: grab;
}

.codics-webdialog-SsjdjiIIs-title {
    height: 100%;
    width: fit-content;
    float: left;
    padding-left: 15px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.codics-webdialog-SsjdjiIIs-icon {
    height: 100%;
    width: 40px;
    position: relative;
    float: right;
    box-sizing: border-box;
    line-height: 50px;
    text-align: center;
    color: #333;
    font-size: 16px;
    cursor: pointer;
}

.codics-webdialog-SsjdjiIIs-bottom {
    height: 70px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    user-select: none !important;
}

.codics-webdialog-SsjdjiIIs-input {
    height: 100%;
    width: calc(100% - 80px);
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 15px 0 15px 15px;
}

.codics-webdialog-SsjdjiIIs-input input {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    box-shadow: inset 2px 2px 5px #BABECC, inset -5px -5px 10px #FFF;
    outline: none !important;
    border: none !important;
    background: #EBECF0;
    padding: 0 15px 0 15px;
    transition: all 0.2s ease-in-out;
}

.codics-webdialog-SsjdjiIIs-input input:focus {
    box-shadow: inset 1px 1px 2px #BABECC, inset -1px -1px 2px #FFF;
}

.codics-webdialog-SsjdjiIIs-input-send {
    height: 40px;
    width: 50px;
    position: relative;
    float: left;
    box-sizing: border-box;
    top: 50%;
    margin-left: 15px;
    cursor: pointer;
    background: #EBECF0;
    transform: translateY(-50%);
    box-shadow: -5px -5px 20px #FFF, 5px 5px 20px #BABECC;
    line-height: 40px;
    text-align: center;
    transition: all 0.2s ease-in-out;
}

.codics-webdialog-SsjdjiIIs-input-send:hover {
    box-shadow: -3px -3px 12px #FFF, 3px 3px 12px #BABECC;
}

.codics-webdialog-SsjdjiIIs-input-send:active {
    box-shadow: 1px 1px 4px #FFF, -1px -1px 4px #BABECC;
}

.codics-webdialog-SsjdjiIIs-content {
    height: calc(100% - 120px);
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    overflow-y: scroll;
}

.codics-webdialog-SsjdjiIIs-item {
    height: fit-content;
    min-height: 50px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
}

.codics-webdialog-SsjdjiIIs-item-kf {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

.codics-webdialog-SsjdjiIIs-item-head-box {
    height: 100%;
    width: 70px;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 15px;
}

.codics-webdialog-SsjdjiIIs-item-head {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    border: 2px solid #FFF;
    box-shadow: -5px -5px 20px #FFF, 5px 5px 20px #BABECC;
    border-radius: 50%;
}

.codics-webdialog-SsjdjiIIs-item-content {
    height: fit-content;
    width: fit-content;
    max-width: calc(100% - 140px);
    position: relative;
    float: left;
    box-sizing: border-box;
    line-height: 36px;
    font-size: 13px;
    color: #666;
    padding: 15px 0 15px 0;
    font-weight: 500;
}

.codics-webdialog-SsjdjiIIs-item-txt {
    height: fit-content;
    width: fit-content;
    position: relative;
    float: left;
    box-sizing: border-box;
    background: #EBECF0;
    box-shadow: -5px -5px 20px #FFF, 5px 5px 20px #BABECC;
    border: 2px solid #FFF;
    border-radius: 0 20px 20px 20px;
    padding: 0 15px 0 15px;
}

.webdialog-enter-active,
.webdialog-leave-active {
    transition: all .5s;
}

.webdialog-enter,
.webdialog-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>