<template>
    <div class="codics-admin-product-brand-body">
        <div class="codics-admin-product-brand-control">
            <el-input class="codics-admin-product-brand-control-input"
                :placeholder="'请输入' + (searchType == 0 ? '订单信息' : (searchType == 1 ? '产品' : (searchType == 2 ? '收件信息' : '物流信息')))"
                v-model="searchTxt" clearable @input="searchChange">
                <el-select style="width: 40px !important;" v-model="searchType" slot="prepend" placeholder="">
                    <el-option label="券码" value=0></el-option>
                    <el-option label="产品" value=1></el-option>
                    <el-option label="收件信息" value=2></el-option>
                    <el-option label="物流信息" value=3></el-option>
                </el-select>
            </el-input>
            <el-button class="codics-admin-product-brand-control-search" type="warning" icon="el-icon-search"
                @click="searchPage = 1; searchOrderFuc(searchPage, searchSize);">搜索</el-button>
            <el-button class="codics-admin-product-brand-control-search" type="danger"
                icon="el-icon-delete">删除</el-button>
            <el-button class="codics-admin-product-brand-control-search"
                style="float: right !important;margin-right: 0 !important;" icon="el-icon-download"
                @click="tableToExcel">导出数据</el-button>
        </div>
        <el-table stripe class="codics-admin-product-brand-table" :data="searchTxt == '' ? tableData : searchData"
            border style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column class-name="codics-admin-product-brand-table-qm" prop="productCdk.cdk" label="券码"
                width="180">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="80"
                :filters="[{ text: '待核销', value: 0 }, { text: '待发货', value: 1 }, { text: '已发货', value: 2 }, { text: '已冻结', value: 3 }, { text: '已封禁', value: 4 }]"
                :filter-method="stateFilter" filter-placement="bottom-end">

                <template slot-scope="scope">
                    <el-tag
                        :type="scope.row.state == 0 ? 'info' : (scope.row.state == 1 ? '' : (scope.row.state == 2 ? 'success' : (scope.row.state == 3 ? 'warning' : 'danger')))"
                        disable-transitions>{{ scope.row.state == 0 ? '待核销' : (scope.row.state == 1 ? '待发货' :
                    (scope.row.state == 2 ? '已发货' : (scope.row.state == 3 ? '已冻结' : '已封禁'))) }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column class-name="codics-admin-product-brand-table-cp" prop="product.name" label="产品"
                width="180">
            </el-table-column>
            <el-table-column prop="area.name" label="姓名" width="80">
            </el-table-column>
            <el-table-column prop="area.phone" label="手机号" width="110">
            </el-table-column>
            <el-table-column prop="area" label="地址" width="180" class-name="codics-admin-product-brand-table-dz">
                <template slot-scope="scope">
                    {{ scope.row.area.province + '/' + scope.row.area.city + '/' + scope.row.area.area + '/' +
                    scope.row.area.remark }}
                </template>
            </el-table-column>
            <el-table-column prop="type" label="物流" width="100">
            </el-table-column>
            <el-table-column prop="tid" label="单号" width="150">
            </el-table-column>
            <el-table-column prop="updatetime" label="发货时间" width="180"
                class-name="codics-admin-product-brand-table-dz">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-popconfirm title="通过后会削减库存，请认真核对信息！" @confirm="examineOrderFuc(scope.$index, scope.row)">
                        <el-button size="mini" v-show="scope.row.state == 0" slot="reference"
                            style="margin-right: 0px;">审核</el-button>
                    </el-popconfirm>
                    <el-button size="mini" v-show="scope.row.state == 1 && new Date(scope.row.updatetime) <= new Date()"
                        @click="fahuoVisible = true; fahuoForm.id = scope.row.id;"
                        style="margin-right: 0px;">发货</el-button>
                    <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button> -->
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="handleDelete(scope.$index, scope.row)">
                        <el-button size="mini" slot="reference" type="danger">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-product-brand-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
            :total="searchTxt == '' ? maxSize : maxSearchSize">
        </el-pagination>
        <el-dialog title="填写发货信息" :visible.sync="fahuoVisible" width="80%">
            <el-form :model="fahuoForm">
                <el-form-item label="快递类型" :label-width="formLabelWidth">
                    <el-select v-model="fahuoForm.type" placeholder="请选择">
                        <el-option v-for="item in wuliuType" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-show="fahuoForm.type != 'wuxvwuliu'" label="快递单号" :label-width="formLabelWidth">
                    <el-input placeholder="请输入快递单号" v-model="fahuoForm.tid" clearable
                        :style="{ width: 'calc(100% - 50px)' }">
                    </el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false; faHuoFuc();">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getExOrders, delExOrders, getAreaInfo, examineOrder, faHuo, searchOrders } from '@/api';
import { exportExcel } from '@/utils/myutils';
export default {
    data() {
        return {
            searchTxt: '',
            cdkState: [
                {
                    value: 0,
                    label: '可用'
                },
                {
                    value: 1,
                    label: '冻结'
                },
                {
                    value: 2,
                    label: '已用'
                },
                {
                    value: 3,
                    label: '销毁'
                }
            ],
            shopLoad: false,
            productOptions: [],
            addProductDialogVisibility: false,
            addForm: {
                sta: 0,
                exp: new Date().getTime(),
                cot: 1,
                sid: null,
                num: 1
            },
            formLabelWidth: '120px',
            searchType: 0,
            currentPage: 1,
            searchPage: 1,
            searchSize: 10,
            tableData: [],
            searchData: [],
            selectData: [],
            multipleSelection: [],
            maxSize: 0,
            maxSearchSize: 0,
            dataSize: 10,
            fahuoVisible: false,
            wuliuType: [
                {
                    value: 'shunfeng',
                    label: '顺丰快递'
                },
                {
                    value: 'zhongtong',
                    label: '中通快递'
                },
                {
                    value: 'yuantong',
                    label: '圆通快递'
                },
                {
                    value: 'shentong',
                    label: '申通快递'
                },
                {
                    value: 'jtexpress',
                    label: '极兔快递'
                },
                {
                    value: 'yunda',
                    label: '韵达快递'
                },
                {
                    value: 'jd',
                    label: '京东快递'
                },
                {
                    value: 'ems',
                    label: '邮政快递'
                },
                {
                    value: 'wuxvwuliu',
                    label: '无需物流'
                }
            ],
            fahuoForm: {

            }
        }
    },
    mounted() {

        this.getOrdersData(this.currentPage, this.dataSize);

    },
    methods: {

        async getOrdersData(pge, psz) {

            let data = await getExOrders({ "pge": pge, "psz": psz });

            if (data.code == 200) {

                this.tableData = data.data;

                this.maxSize = data.count;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {

            if (this.searchTxt == '') {
                this.dataSize = val;
                this.getOrdersData(this.currentPage, val);
            } else {
                this.searchSize = val;
                this.searchOrderFuc(this.searchPage, val);
            }
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
                this.getOrdersData(val, this.dataSize);
            } else {
                this.searchPage = val;
                this.searchOrderFuc(val, this.searchSize);
            }
        },
        stateFilter(v, r, c) {

            return r.state === v;

        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        tableToExcel() {
            if (this.selectData.length == 0) {
                this.$message({
                    message: '请至少选中一条数据再进行该操作！',
                    type: 'warning'
                });
                return true;
            }
            const now = new Date();
            const year = now.getFullYear(); // 获取当前年份
            const month = now.getMonth() + 1; // 获取当前月份，注意getMonth()返回的是0-11，所以需要加1
            const day = now.getDate(); // 获取当前日期
            let title = ['序号', '状态', '快递', '快递单号', "商品", "商品介绍", '现价', '原价', '姓名', '手机号', '省份', '城市', '区域', '具体地址', '卡券号', '卡券密码'];
            let filter = ['cid', 'pid', 'aid', 'count', 'product.id', 'product.img', 'product.itimg', 'product.type', 'product.sid', 'product.state', 'product.createby', 'product.createtime', 'product.updateby', 'product.updatetime', 'product.brand', 'product.inventory', 'product.like', 'product.view', 'product.sell', 'product.follow', 'area.id', 'area.uid', 'productCdk.id', 'productCdk.state', 'productCdk.expires', 'productCdk.count', 'productCdk.sid', 'productCdk.createby', 'productCdk.createtime', 'productCdk.updateby', 'productCdk.updatetime', 'productCdk.product', 'createby', 'createtime', 'updateby', 'updatetime'];  //不导出的字段
            exportExcel(this.selectData, '用户订单数据_' + year + month + day, title, filter);
        },
        async addProductCdkFuc() {

            if (this.addForm.exp - new Date().getTime() < 0) {

                this.$notify({
                    title: '警告',
                    message: '卡密有效期不能小于1天！',
                    type: 'warning'
                });

                return true;

            }

            if (this.addForm.sid == null || this.addForm.sid == '') {

                this.$notify({
                    title: '警告',
                    message: '卡密绑定商品不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductCdks(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: data.count + '条卡密生成成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async queryProductData(query) {
            if (query !== '') {
                this.shopLoad = true;
                let data = await queryProduct({ key: query });
                this.shopLoad = false;
                if (data.code == 200) {

                    this.$message({
                        message: '查询商品成功！',
                        type: 'success'
                    });

                    this.productOptions = data.data;

                } else {

                    this.$message.error(data.message);

                    this.productOptions = [];

                }
            } else {
                this.productOptions = [];
            }
        },
        async handleDelete(index, row) {
            console.log(index, row)
            let data = await delExOrders({ id: row.id });
            if (data.code == 200) {
                this.$message({
                    message: '删除订单成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        async seeArea(index, row) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await getAreaInfo({ id: row.aid });
            loading.close();
            if (data.code == 200) {
                this.$alert(data.data, '收货地址', {
                    confirmButtonText: '知道了',
                    callback: action => {

                    }
                });
            } else {
                this.$message.error(data.message);
            }
        },
        async examineOrderFuc(index, row) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await examineOrder({ id: row.id });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '订单审核成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        async faHuoFuc() {
            if (this.fahuoForm.id == '') {
                this.$message({
                    message: '未选中发货订单！',
                    type: 'danger'
                });
                return true;
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await faHuo(this.fahuoForm);
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '订单发货成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        async searchOrderFuc(pge, psz) {

            if (this.searchTxt == '') {

                this.$message({
                    showClose: true,
                    message: '要搜索的内容不能为空！',
                    type: 'warning'
                });

            }

            console.log(this.searchType, this.searchTxt, pge, psz);

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await searchOrders({ type: this.searchType, key: this.searchTxt, 'pge': pge, 'psz': psz });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '数据搜索成功！',
                    type: 'success'
                });
                this.searchData = data.data;
                this.maxSearchSize = data.count;
            } else {
                this.$message.error(data.message);
            }

        },
        searchChange(val) {

            if (val == '') {

                this.searchData = [];

                this.maxSearchSize = 0;

            }

        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-product-brand-body {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-product-brand-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-admin-product-brand-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-product-brand-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-product-brand-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-product-brand-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-admin-product-brand-table-qm div,
.codics-admin-product-brand-table-cp div,
.codics-admin-product-brand-table-dz div {
    white-space: nowrap !important;
    /* 防止文本换行 */
    overflow: hidden !important;
    /* 隐藏超出容器的内容 */
    text-overflow: ellipsis !important;
}
</style>