<template>
    <div class="codics-firm-staff-role-body">
        <div class="codics-firm-staff-role-control">
            <el-button class="codics-firm-staff-role-control-search" type="success" icon="el-icon-plus"
                @click="addRoleDialogVisibility = true;">添加角色</el-button>
            <!-- 添加表单 -->
            <el-dialog title="添加角色" :visible.sync="addRoleDialogVisibility" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="角色名称" :label-width="formLabelWidth">
                        <el-input v-model="addForm.role" autocomplete="off" maxlength="15" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="角色状态" :label-width="formLabelWidth">
                        <el-select v-model="addForm.state" placeholder="请选择">
                            <el-option v-for="(item, index) in stateList" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="角色备注" :label-width="formLabelWidth">
                        <el-input type="textarea" placeholder="请输入角色备注" v-model="addForm.remark" maxlength="130"
                            show-word-limit rows="4">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addRoleDialogVisibility = false">取 消</el-button>
                    <el-button type="primary" @click="addRoleFuc();">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 更新表单 -->
            <el-dialog title="更新信息" :visible.sync="isUpdate" width="80%">
                <el-form :model="nowRole">
                    <el-form-item label="角色名称" :label-width="formLabelWidth">
                        <el-input v-model="nowRole.role" autocomplete="off" maxlength="15" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="角色状态" :label-width="formLabelWidth">
                        <el-select v-model="nowRole.state" placeholder="请选择">
                            <el-option v-for="(item, index) in stateList" :key="index" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="角色备注" :label-width="formLabelWidth">
                        <el-input type="textarea" placeholder="请输入角色备注" v-model="nowRole.remark" maxlength="130"
                            show-word-limit rows="4">
                        </el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="isUpdate = false">取 消</el-button>
                    <el-button type="primary" @click="updateRoleFuc();">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-firm-staff-role-control-input" style="width: 260px !important;"
                placeholder="请输入要搜索的内容..." v-model="searchTxt" clearable @input="searchChange">
                <el-button
                    @click="searchPage = 1; searchMax = 0; searchData = []; nowSearchTxt = searchTxt; searchRoleFuc(true);"
                    slot="append" icon="el-icon-search" style="padding: 0 25px 0 25px !important;"></el-button>
            </el-input>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteRolesFuc">
                <el-button class="codics-firm-staff-role-control-search" slot="reference" type="danger"
                    icon="el-icon-delete">批量删除</el-button>
            </el-popconfirm>
        </div>
        <el-table stripe class="codics-firm-staff-role-table" :data="searchTxt == '' ? tableData : searchData" border
            style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="role" label="角色" width="180">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="70"
                :filters="[{ text: '上架', value: 0 }, { text: '下架', value: 1 }]" :filter-method="stateFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? 'success' : 'danger'" disable-transitions>{{ scope.row.state
                    == 0 ? '上架' : '下架' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" width="180">
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button size="mini" type="warning"
                        @click="manageLimitVisibility = true; nowId = scope.row.id; transferValue = (scope.row?.ram?.mid).split(',').map(Number);"
                        plain>权限</el-button>
                    <el-button size="mini" @click="isUpdate = true; nowRole = scope.row;"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteRolesFuc([scope.row.id])">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-firm-staff-role-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="searchTxt == '' ? currentPage : searchPage"
            :page-sizes="[10, 20, 30, 40]" :page-size="searchTxt == '' ? dataSize : searchSize"
            layout="total, sizes, prev, pager, next, jumper" :total="searchTxt == '' ? maxSize : searchMax">
        </el-pagination>
        <!-- 权限表单 -->
        <el-dialog :title="'权限管理' + ''" :visible.sync="manageLimitVisibility" width="fit-content">
            <el-transfer v-model="transferValue" :data="transferData" :titles="['未分配权限', '已分配权限']" filterable
                target-order="push" :props="{ key: 'id', label: 'name', children: 'children' }"></el-transfer>
            <div slot="footer" class="dialog-footer">
                <el-button @click="manageLimitVisibility = false">取 消</el-button>
                <el-button type="primary" @click="bindRam(nowId)">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { searchRoles, addRole, listMenu, bindRam, updateRole, deleteRoles } from '@/api';
export default {
    data() {
        return {
            nowRole: {},
            transferData: [],
            transferValue: [],
            addRoleDialogVisibility: false,
            isUpdate: false,
            manageLimitVisibility: false,
            addForm: {
                role: '',
                state: 0,
                remark: ''
            },
            formLabelWidth: '120px',
            searchType: '名称',
            currentPage: 1,
            searchPage: 1,
            tableData: [],
            searchData: [],
            selectData: [],
            multipleSelection: [],
            maxSize: 0,
            searchMax: 0,
            searchTxt: '',
            dataSize: 10,
            searchSize: 10,
            stateList: [
                {
                    value: 0,
                    label: '上架'
                },
                {
                    value: 1,
                    label: '下架'
                }
            ],
            nowId: null,
            nowSearchTxt: ''
        }
    },
    mounted() {
        this.$emit('check-mem');
        this.searchRoleFuc();
        this.listMenuData();
    },
    methods: {
        async bindRam(rid) {

            if (rid == null) {

                this.$message({
                    message: '未成功选定角色！',
                    type: 'success'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindRam({ rid: rid, mids: this.transferValue });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '绑定权限成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }
        },
        async listMenuData() {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listMenu();

            loading.close();

            if (data.code == 200) {

                this.resolveList(data.data, this.transferData);

                this.hasChildren(this.transferData);

            } else {

                this.$message.error(data.message);

            }

        },
        async addRoleFuc() {

            if (this.addForm.role == '' || this.addForm.remark == '') {

                this.$notify({
                    title: '警告',
                    message: '角色信息不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await addRole(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '添加角色成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async updateRoleFuc() {

            console.log(this.nowRole.role, this.nowRole.remark);

            if (this.nowRole.role == '' || this.nowRole.remark == '') {

                this.$notify({
                    title: '警告',
                    message: '角色信息不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await updateRole(this.nowRole);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '修改角色信息成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            if (this.searchTxt == '') {
                this.dataSize = val;
                this.searchRoleFuc();
            } else {
                this.searchSize = val;
                this.searchRoleFuc();
            }
        },
        handleCurrentChange(val) {
            if (this.searchTxt == '') {
                this.currentPage = val;
                this.searchRoleFuc();
            } else {
                this.searchPage = val;
                this.searchRoleFuc();
            }
        },
        stateFilter(v, r, c) {

            return r.state === v;

        },
        searchChange(val) {

            if (val == '') {

                this.searchData = [];

                this.maxSearchSize = 0;

                this.nowSearchTxt = '';

            }

        },
        async searchRoleFuc(b) {

            if (b && this.searchTxt == '') {

                this.$notify({
                    title: '警告',
                    message: '要搜索的内容不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await searchRoles({ key: this.nowSearchTxt, pge: b ? this.searchPage : this.currentPage, psz: b ? this.searchSize : this.dataSize });

            loading.close();

            if (data.code == 200) {

                if (b) {

                    this.searchMax = data.count;

                    this.searchData = data.data;

                } else {

                    this.maxSize = data.count;

                    this.tableData = data.data;

                }

            } else {

                this.$message.error(data.message);

            }

        },
        hasChildren(list) {

            for (var i = 0; i < list.length; i++) {

                if (list[i]?.menu != null) {

                    this.hasChildren(list[i].menu);

                }

            }

        },
        resolveList(list, t) {
            for (let i in list) {
                if (list[i].children != null) {
                    this.resolveList(list[i].children, t);
                } else if (list[i].remark != '') {
                    t[t.length] = list[i];
                }
            }
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        async deleteRolesFuc(t) {
            if (t == null) {

                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }

                var t = [];

                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await deleteRoles({ ids: t });

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        }
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-firm-staff-role-body {
    height: fit-content;
    min-height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-firm-staff-role-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-firm-staff-role-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-firm-staff-role-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-firm-staff-role-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-firm-staff-role-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>