<template>
    <div class="codics-home-footer-box">
        <div class="codics-home-footer-container">
            <!-- <div class="codics-home-footer-service">
                <div class="codics-home-footer-service-item">
                    <div class="codics-home-footer-service-icon iconfont">&#xe903;<a
                            style="height: 30px;width: fit-content;line-height: 30px;font-size: 16px;color: #CCC;margin-left: 15px;">专注服务卡券领域</a>
                    </div>
                </div>
                <div class="codics-home-footer-service-item">
                    <div class="codics-home-footer-service-icon iconfont">&#xe907;<a
                            style="height: 30px;width: fit-content;line-height: 30px;font-size: 16px;color: #CCC;margin-left: 15px;">资深卡券客服专家</a>
                    </div>
                </div>
                <div class="codics-home-footer-service-item">
                    <div class="codics-home-footer-service-icon iconfont">&#xe916;<a
                            style="height: 30px;width: fit-content;line-height: 30px;font-size: 16px;color: #CCC;margin-left: 15px;">一对一的专属经理</a>
                    </div>
                </div>
                <div class="codics-home-footer-service-item">
                    <div class="codics-home-footer-service-icon iconfont">&#xe912;<a
                            style="height: 30px;width: fit-content;line-height: 30px;font-size: 16px;color: #CCC;margin-left: 15px;">几万用户诚意推荐</a>
                    </div>
                </div>
            </div>
            <div class="codics-home-footer-links">
                <div class="codics-home-footer-links-item" v-for="(item,index) in linksData" :key="index">
                    <div class="codics-home-footer-links-item-box">
                        <dt>{{ item.title }}</dt>
                        <dd v-for="(item2,index2) in item.children" :key="index2"><a :href="item2.link">{{ item2.title }}</a></dd>
                    </div>
                </div>
                <div class="codics-home-footer-links-item">
                    <div class="codics-home-footer-links-item-box">
                        <dt>联系我们</dt>
                        <p style="color: #FFF;font-size: 15px;margin-bottom: 15px;">400-111-xxx</p>
                        <div class="codics-home-footer-qrcode-box">
                            <el-image style="height: 100px;width: 100px;cursor: pointer;" fit="fill"
                                src="https://cdn.codics.cn/huayuan/img/%E8%BF%99%E6%98%AF%E4%B8%80%E4%B8%AA%E6%BC%94%E7%A4%BA%E7%94%A8%E7%9A%84%E4%BA%8C%E7%BB%B4%E7%A0%81%20%281%29.png"></el-image>
                            <div class="codics-home-footer-qrcode-matrix"></div>
                            <p
                                style="color: #FFF;font-size: 14px;top: -94px;height: 40px;position: relative;float: left;">
                                关注微信订阅号</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="codics-home-footer-friends">
                <div class="codics-home-footer-friend-title">友情链接：</div>
                <ul class="codics-home-footer-friend-list" style="float: left;">
                    <li v-for="(item,index) in friendsData" :key="index"><a :href="item.url">{{ item.title }}</a></li>
                </ul>
                <a href="#" class="codics-home-footer-friend-more">更多 &gt;&gt;</a>
            </div> -->
            <div v-show="!isPhone" class="codics-home-footer-info">© 2024 (www.katihuo.com) <a
                    href="#">山东华远创智信息技术有限公司</a>
                版权所有，并保留所有权利 <a href="https://beian.miit.gov.cn/"
                    style="text-decoration: none;">鲁ICP备2023021315号-3</a>&nbsp;<a href="tel: 4006-315-789"
                    style="text-decoration: none;">客服电话：4006-315-789</a></div>
            <div v-show="isPhone" class="codics-home-footer-info">'© 2024 山东华远创智信息技术有限公司 <a
                    href="https://beian.miit.gov.cn/" target="_blank"
                    style="text-decoration: none;">鲁ICP备2023021315号-3</a></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ShopIndex',
    data() {
        return {
            linksData: [
                {
                    id: 1,
                    title: '旗下品牌',
                    children: [
                        {
                            title: '华远创智',
                            link: ''
                        },
                        {
                            title: '华远创智',
                            link: ''
                        },
                        {
                            title: '华远创智',
                            link: ''
                        }
                    ]
                },
                {
                    id: 1,
                    title: '关于我们',
                    children: [
                        {
                            title: '公司介绍',
                            link: ''
                        },
                        {
                            title: '发展历程',
                            link: ''
                        },
                        {
                            title: '团队风采',
                            link: ''
                        },
                        {
                            title: '办公环境',
                            link: ''
                        },
                        {
                            title: '荣誉资质',
                            link: ''
                        },
                        {
                            title: '联系我们',
                            link: ''
                        }
                    ]
                },
                {
                    id: 1,
                    title: '其他业务',
                    children: [
                        {
                            title: '其他业务A',
                            link: ''
                        },
                        {
                            title: '其他业务B',
                            link: ''
                        },
                        {
                            title: '其他业务C',
                            link: ''
                        },
                        {
                            title: '其他业务D',
                            link: ''
                        },
                        {
                            title: '其他业务E',
                            link: ''
                        }
                    ]
                },
                {
                    id: 1,
                    title: '其他内容',
                    children: [
                        {
                            title: '官方论坛',
                            link: ''
                        },
                        {
                            title: '服务支持',
                            link: ''
                        },
                        {
                            title: '投诉建议',
                            link: ''
                        },
                        {
                            title: '网站地图',
                            link: ''
                        }
                    ]
                }
            ],
            friendsData: [
                {
                    title: 'APP商城开发',
                    url: ''
                },
                {
                    title: '友情链接1',
                    url: ''
                },
                {
                    title: '友情链接1',
                    url: ''
                },
                {
                    title: '友情链接1',
                    url: ''
                },
                {
                    title: '友情链接1',
                    url: ''
                },
                {
                    title: '友情链接1',
                    url: ''
                },
                {
                    title: '友情链接1',
                    url: ''
                },
                {
                    title: '友情链接1',
                    url: ''
                },
            ],
            isPhone: false
        };
    },

    mounted() {
        this.isPhone = document.body.clientWidth <= 768;
        window.onresize = () => {
            return (() => {
                this.isPhone = document.body.clientWidth <= 768;
            })()
        }
    },

    methods: {

    },
};
</script>

<style lang="css">
.codics-home-footer-box {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    /* background: #222132; */
    background: #FFF;
}

.codics-home-footer-container {
    height: fit-content;
    width: 100%;
    max-width: 1450px;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

.codics-home-footer-box * {
    margin: 0;
    padding: 0;
    color: #666;
}

.codics-home-footer-service {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    border-bottom: 1px solid #393854;
    user-select: none;
}

.codics-home-footer-service-item {
    height: 140px;
    width: 25%;
    position: relative;
    float: left;
    line-height: 140px;
    text-align: center;
    color: #FFF;
}

.codics-home-footer-service-icon {
    height: fit-content;
    width: fit-content;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 30px;
    color: #FFF;
}

.codics-home-footer-links {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

.codics-home-footer-links-item {
    height: fit-content;
    width: 20%;
    position: relative;
    float: left;
    user-select: none;
}

.codics-home-footer-links-item dt {
    color: #CCC;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 2;
    font-weight: 300;
}

.codics-home-footer-links-item dd {
    line-height: 2;
}

.codics-home-footer-links-item a {
    text-decoration: none;
    color: #888;
    transition: all .1s;
    font-size: 14px;
}

.codics-home-footer-links-item a:hover {
    color: #FFF;
}

.codics-home-footer-links-item-box {
    height: fit-content;
    width: fit-content;
    position: relative;
    float: left;
    left: 50%;
    box-sizing: border-box;
    padding-top: 50px;
    transform: translateX(-50%);
}

.codics-home-footer-qrcode-box {
    height: 100px;
    width: 100px;
    box-sizing: border-box;
    cursor: pointer;
}

.codics-home-footer-qrcode-matrix {
    height: 100px;
    width: 450px;
    position: relative;
    float: left;
    top: -104px;
    right: 465px;
    background: #EEE;
    box-sizing: border-box;
    border: 2px solid #FFF;
    border-radius: 4px;
    visibility: hidden;
    transition: .4s;
}

.codics-home-footer-qrcode-box:hover .codics-home-footer-qrcode-matrix {
    visibility: visible;
}

.codics-home-footer-qrcode-matrix::before {
    content: '';
    height: 20px;
    width: 20px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: -7px;
    transform: rotate(45deg);
    background: #EEE;
    z-index: -1;
    box-sizing: border-box;
    border: 2px solid #FFF;
    border-radius: 3px;
}

.codics-home-footer-friends {
    height: fit-content;
    width: fit-content;
    position: relative;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    user-select: none;
}

.codics-home-footer-friend-title {
    height: 20px;
    width: fit-content;
    position: relative;
    float: left;
    font-size: 14px;
    color: #CCC;
    line-height: 20px;
    text-align: center;
}

.codics-home-footer-friend-list li {
    /* float: left; */
    line-height: 20px;
    font-size: 14px;
    color: #888;
    line-height: 20px;
    margin-left: 30px;
    display: inline-block;
}

.codics-home-footer-friend-list a {
    text-decoration: none;
}

.codics-home-footer-friends a:hover {
    color: #FFF;
}

.codics-home-footer-friend-more {
    height: 20px;
    width: fit-content;
    position: relative;
    float: left;
    font-size: 14px;
    color: #CCC;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    margin-left: 30px;
}

.codics-home-footer-info {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #CCC;
    user-select: none;
    color: #333 !important;
}

@media (max-width:768px) {

    .codics-home-footer-service-item {
        width: 50% !important;
    }

    .codics-home-footer-links-item {
        width: 25%;
    }

    .codics-home-footer-links-item:nth-child(5) {
        display: none !important;
    }

}
</style>