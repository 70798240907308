<template>
    <div
        style="position: relative;float: left;left: 50%;transform: translateX(-50%);height: fit-content;min-height: calc(100% - 120px);width: 90%;max-width: 1180px;">
        <div class="open_email" style="margin-left: 8px; margin-top: 8px; margin-bottom: 8px; margin-right: 8px;">
            <div>
                <br>
                <span class="genEmailContent">
                    <div id="cTMail-Wrap"
                        style="word-break: break-all;box-sizing:border-box;text-align:center;min-width:320px; max-width:660px; border:1px solid #f6f6f6; background-color:#f7f8fa; margin:auto; padding:20px 0 30px; font-family:'helvetica neue',PingFangSC-Light,arial,'hiragino sans gb','microsoft yahei ui','microsoft yahei',simsun,sans-serif">
                        <div class="main-content" style="margin-top: 7%;">
                            <table style="width:100%;font-weight:300;margin-bottom:10px;border-collapse:collapse">
                                <tbody>
                                    <tr style="font-weight:300">
                                        <td style="width:3%;max-width:30px;"></td>
                                        <td style="max-width:600px;">
                                            <!-- <div id="cTMail-logo" style="width:92px; height:37px;">
                                                <a href="https://www.codics.cn/2">
                                                    <img border="0"
                                                        src="https://cdn.codics.cn/huayuan/img/PicsArt_03-25-05.35.37.png"
                                                        style="width:100px; height:50px;display:block;">
                                                </a>
                                            </div> -->
                                            <p
                                                style="height:2px;background-color: #333;border: 0;font-size:0;padding:0;width:100%;margin-top:20px;">
                                            </p>
                                            <div id="cTMail-inner"
                                                style="background-color:#fff; padding:23px 0 20px;box-shadow: 0px 1px 1px 0px rgba(122, 55, 55, 0.2);text-align:left;">
                                                <table
                                                    style="width:100%;font-weight:300;margin-bottom:10px;border-collapse:collapse;text-align:left;">
                                                    <tbody>
                                                        <tr style="font-weight:300">
                                                            <td style="width:3.2%;max-width:30px;"></td>
                                                            <td style="max-width:480px;text-align:left;">
                                                                <h1 id="cTMail-title"
                                                                    style="font-size: 20px; line-height: 36px; margin: 0px 0px 22px;">
                                                                    【卡提货™平台】欢迎使用!
                                                                </h1>
                                                                <p id="cTMail-userName"
                                                                    style="font-size:14px;color:#333; line-height:24px; margin:0;">
                                                                    尊敬的卡提货™用户，您好！
                                                                </p>
                                                                <p class="cTMail-content"
                                                                    style="line-height: 24px; margin: 6px 0px 0px; overflow-wrap: break-word; word-break: break-all;">
                                                                    <span
                                                                        style="color: rgb(51, 51, 51); font-size: 14px;">
                                                                        欢迎使用卡提货™平台服务
                                                                    </span>
                                                                </p>
                                                                <p class="cTMail-content"
                                                                    style="line-height: 24px; margin: 6px 0px 0px; overflow-wrap: break-word; word-break: break-all;">
                                                                    <span
                                                                        style="color: rgb(51, 51, 51); font-size: 14px;">您的邮箱{{
                                                                            bindState
                                                                                ? '成功完成绑定！您可以点击下面的按钮跳转到后台继续您的操作！' : '因为【' +
                                                                                errorMess +
                                                                                '】原因绑定失败!您可以继续尝试邮箱绑定或者点击下面的按钮进行反馈！管理将在24小时内对您遇到的问题进行处理！'
                                                                        }}
                                                                        <span style="font-weight: bold;">如已处理可忽略。</span>
                                                                    </span>
                                                                </p>
                                                                <p class="cTMail-content"
                                                                    style="font-size: 14px; color: rgb(51, 51, 51); line-height: 24px; margin: 6px 0px 0px; word-wrap: break-word; word-break: break-all;">
                                                                    <a href="https://www.katihuo.com/firm/"
                                                                        id="cTMail-btn"
                                                                        style="font-size: 16px; line-height: 45px; display: block; background-color: #333; color: #FFF; text-align: center; text-decoration: none; margin-top: 20px; border-radius: 3px;cursor: pointer;user-select: none;">
                                                                        点击此处进行跳转
                                                                    </a>
                                                                </p>
                                                                <p class="cTMail-content"
                                                                    style="line-height: 24px; margin: 6px 0px 0px; overflow-wrap: break-word; word-break: break-all;">
                                                                    <span
                                                                        style="color: rgb(51, 51, 51); font-size: 14px;">
                                                                        <br>
                                                                        无法正常跳转？请复制链接至浏览器打开：https://www.katihuo.com/firm/
                                                                        <br>
                                                                    </span>
                                                                </p>
                                                                <dl
                                                                    style="font-size: 14px; color: rgb(51, 51, 51); line-height: 18px;">
                                                                    <dd
                                                                        style="margin: 0px 0px 6px; padding: 0px; font-size: 12px; line-height: 22px;">
                                                                        <p id="cTMail-sender"
                                                                            style="font-size: 14px; line-height: 26px; word-wrap: break-word; word-break: break-all; margin-top: 32px;">
                                                                            此致
                                                                            <br>
                                                                            <strong>卡提货团队</strong>
                                                                        </p>
                                                                    </dd>
                                                                </dl>
                                                            </td>
                                                            <td style="width:3.2%;max-width:30px;"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="cTMail-copy"
                                                style="text-align:center; font-size:12px; line-height:18px; color:#999">
                                                <table
                                                    style="width:100%;font-weight:300;margin-bottom:10px;border-collapse:collapse">
                                                    <tbody>
                                                        <tr style="font-weight:300">
                                                            <td style="width:3.2%;max-width:30px;"></td>
                                                            <td style="max-width:540px;">
                                                                <p
                                                                    style="text-align:center; margin:20px auto 14px auto;font-size:12px;color:#999;">
                                                                    更多消息，前往官网查看。
                                                                    <a href="https://www.katihuo.com/"
                                                                        style="text-decoration:none;word-break:break-all;word-wrap:normal; color: #333;">
                                                                        官方网站
                                                                    </a>
                                                                </p>
                                                                <p id="cTMail-rights"
                                                                    style="max-width: 100%; margin:auto;font-size:12px;color:#999;text-align:center;line-height:22px;">
                                                                    <img border="0"
                                                                        src="https://s4.ax1x.com/2022/02/24/bFaWp8.jpg"
                                                                        style="width:64px; height:64px; margin:10px auto;">
                                                                    <br>
                                                                    关注服务号，获取更多相关信息
                                                                    <br>
                                                                </p>
                                                                <!-- <p
                                                                    style="margin-top: 10px;text-align: center;font-weight: bolder;">
                                                                    Copyright 2024 华远创智.All Right Reserved.</p> -->
                                                            </td>
                                                            <td style="width:3.2%;max-width:30px;"></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                        <td style="width:3%;max-width:30px;"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { bindEmail2 } from '@/api';
export default {
    data() {
        return {
            bindState: false,
            errorMess: ''
        }
    },
    mounted() {

        this.bindEmailFuc();

    },
    methods: {

        async bindEmailFuc() {

            if (this.$route.query.u == null || this.$route.query.e == null || this.$route.query.k == null) {

                this.$message.error("绑定过程发生未知错误！");

                return;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await bindEmail2({ u: this.$route.query.u, e: this.$route.query.e, v: this.$route.query.k });

            loading.close();

            if (data.code == 200) {

                this.$notify({
                    title: '成功',
                    message: '邮箱绑定成功！',
                    type: 'success'
                });

            } else {

                this.$message.error(data.message);

            }

        }

    }

}
</script>

<style></style>