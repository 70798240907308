<template>
  <div class="codics-firm-home-view">
    <el-image style="height: 100%;width: 100%;position: relative;float: left;" src="https://cdn.codics.cn/huayuan/img/yasuo_24-4-13%20PC%E7%AB%AF%E9%A6%96%E9%A1%B52%20%281%29.webp" fit="cover"></el-image>
  </div>
</template>

<script>
export default {
  mounted(){
    this.$emit('check-mem');
  }
}
</script>

<style>
.codics-firm-home-view{
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
}
</style>