<template>
  <div id="app">
    <Header class="codics-body-header" @change-data="showMess" :unRead="unRead"
      :style="{ position: (showFixedSearch ? 'fixed' : 'relative' + ' !important'), 'background-color': (showFixedSearch ? '#FFF !important' : 'unset !important'), 'transition': '.3s' }"
      v-show="this.$route.meta.topShow"></Header>
    <div v-show="showFixedSearch" style="height: 60px;width: 100%;position: relative;float: left;"></div>
    <router-view :mess-direction="messDirection"></router-view>
    <Footer class="codics-body-footer" v-show="this.$route.meta.bottomShow"></Footer>
    <el-drawer class="codics-admin-mess" title="我的消息" :visible.sync="messageDialog" :direction="messDirection"
      :before-close="handleClose" :size="messDirection == 'btt' ? 'calc(100% - 60px)' : '30%'">
      <div class="codics-admin-mess-control" :style="{ width: messDirection == 'btt' ? '100%' : '30%' }">
        <el-link type="warning" style="text-align: center;padding: 0 20px 0 20px;position: relative;float: left;"
          :underline="false">查看更多</el-link>
        <el-link type="warning" style="text-align: center;padding: 0 20px 0 20px;position: relative;float: right;"
          :underline="false">消息设置</el-link>
      </div>
      <div v-infinite-scroll="loadMess" style="height:calc(100% - 50px);overflow:auto;">
        <div class="codics-admin-mess-box" v-for="(item, index) in messData" :key="index">
          <div class="codics-admin-mess-card">
            <div class="codics-admin-mess-top-bar">
              <el-avatar v-show="item.account != null" shape="square" :size="26"
                style="top: 50%;transform: translateY(-50%);position: relative;float: left;margin-right: 10px;"
                :src="(item.account == null || item.account.himg == null) ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : item.account.himg"></el-avatar>
              <div class="codics-admin-mess-top-nick">{{ item.account == null ? '系统消息' :
      item.account.nickname }}
              </div>
              <el-dropdown style="height: 50px;width: 50px;position: relative;float: right;cursor: pointer;">
                <div class="codics-admin-mess-top-more">
                  <i class="el-icon-more codics-admin-mess-top-more-icon" style="position: absolute;margin: auto;"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>删除消息</el-dropdown-item>
                  <el-dropdown-item>查看详情</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

            </div>
            <div class="codics-admin-mess-body" v-html="item.content">
            </div>
            <div class="codics-admin-mess-top-bar codics-admin-mess-bottom-bar">
              <div class="codics-admin-mess-bottom-time">2024-03-12 11:11:11</div>
              <el-button type="text" class="codics-admin-mess-detail">查看详情<i
                  class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
          </div>
        </div>
        <div
          style="height: 50px;width: 100%;position: relative;float: left;line-height: 50px;text-align: center;color: #AAA;font-size: 15px;">
          没有更多消息了~</div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from './components/Header/index.vue';
import Footer from './components/Footer/index.vue';
import { delCookie, getCookie, setCookie } from '@/utils/mycookie';
import { randomString, jumpTo } from '@/utils/myutils';
import { hex_md5 } from '@/utils/md5';
import { getMyMess, userInfos } from './api';
// 初始化设备唯一识别码
if (getCookie('x-myweb-key') == null) {
  // 测试性能：开始计时
  // console.time('codeExecution');
  // console.log(hex_md5(new Date().valueOf().toString() + randomString(20)));
  // 测试性能：结束计时
  // console.timeEnd('codeExecution');
  //设置设备唯一识别码
  setCookie('x-myweb-key', hex_md5(new Date().valueOf().toString() + randomString(50)));

}
export default {
  name: 'App',
  data() {
    return {
      // userInfo: JSON.parse(getCookie('user-inf')),
      a: '123123',
      showFixedSearch: false,
      messageDialog: false,
      messDirection: 'rtl',
      messPage: 1,
      messData: [],
      screenWidth: 0,
      messCount: 0,
      unRead: 0
    }
  },
  components: {
    Header,
    Footer
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.showSearch);

    this.loadMess();

    this.screenWidth = document.body.clientWidth

    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }

    if (this.userInfo != null) {

      this.userInfoFuc();

    }

  },
  methods: {
    async userInfoFuc() {

      let data = await userInfos();

      if (data.code == 200) {

        if (window.location.href.indexOf('/firm') != -1 && data.user.uar == null) {

          this.$message.error("您的账号没有访问当前页面的权限！");

          this.$store.dispatch('logout');

          jumpTo(this, '登录', {}, {});

        }

        setCookie("user-inf", JSON.stringify(data));
        // 刷新仓库数据
        this.$store.dispatch('login');

      } else {

        switch (data.code) {

          case 409:

          case 401:

            this.$store.dispatch('logout');

            jumpTo(this, '登录', {}, {});

            break;

        }

        this.$message.error(data.message);

      }

    },
    showSearch() {
      // 获取当前滚动条向下滚动的距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      // 当页面滚动到高度300px处时，显示搜索框
      if (scrollTop > 60) {
        this.showFixedSearch = true;
      } else {
        this.showFixedSearch = false;
      }
    },
    showMess() {
      this.messageDialog = true;
    },
    async loadMess() {

      this.messPage++;

      let data = await getMyMess({ pge: this.messPage, psz: 10 });

      if (data.code == 200) {

        if (data.count != this.messData.length) {

          Array.prototype.push.apply(this.messData, data.data);

          this.messCount = data.Count;

          this.unRead = data.unread;

        }

      } else {

        // this.$message.error(data.message);

      }

    },
    handleClose(done) {
      // this.messPage = 0;
      // this.messCount = 0;
      // this.messData = [];
      done();
    },
    checkDevTools() {
      const startTime = performance.now();
      // 设置断点
      // eslint-disable-next-line no-debugger
      debugger;
      const endTime = performance.now();
      // 设置一个阈值，例如100毫秒
      if (endTime - startTime > 100) {
        window.location.href = 'about:blank';
      }
    }
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        if (val <= 768) {
          this.messDirection = 'btt';
        } else {
          this.messDirection = 'rtl';
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapState(['userInfo'])
  },
  // 动态设置页面标题、关键字和描述等属性  
  metaInfo() {
    return {
      title: this.$route.meta.metaInfo.title,
      meta: [
        { name: "keywords", content: this.$route.meta.metaInfo.keywords },
        { name: "description", content: this.$route.meta.metaInfo.description },
      ]
    }
  }
}
</script>

<style>
/* APP主体部分 */
#app {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  background: #f8f8f8;
}

/* APP顶栏 */
.codics-body-header {
  /* 将顶栏悬浮 */
  /* position: fixed; */
  /* top: 0; */
  z-index: 1000;
}

.codics-body-footer {
  position: relative;
  float: left;
}

.el-menu {
  background-color: unset !important;
}

/* #f5dab1 */
</style>