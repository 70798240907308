<template>
    <div class="codics-admin-home-home">
        <marquee class="codics-admin-home-pmd">欢迎超管：<a style="color: #E6A23C;cursor: pointer;" @click="jumpToFuc('个人中心',{},{})">{{
            userInfo.user.nickname
        }}</a>，当前时间：<a>{{ getCurrentTime() }}</a>
        </marquee>
        <fieldset class="codics-admin-home-sjtj">
            <legend>数据统计</legend>
            <router-link class="codics-admin-home-sjtj-card" v-for="item in sjtjData" :to="item.path"
                v-bind:key="item.id">
                <div class="codics-admin-home-sjtj-box">
                    <div class="codics-admin-home-sjtj-title">{{ item.title }}</div>
                    <i :class="item.icon + ' codics-admin-home-sjtj-icon'"></i>
                    <div class="codics-admin-home-sjtj-count">{{ item.count }}</div>
                </div>
            </router-link>
        </fieldset>
        <fieldset class="codics-admin-home-sjtj" style="padding: 0 20px 5px 20px;">
            <legend>系统信息</legend>
            <table class="table-sysinfo">
                <tbody>
                    <tr>
                        <td>系统版本</td>
                        <td>2024.03.01.01</td>
                    </tr>
                    <tr>
                        <td>服务器地址</td>
                        <td>124.221.115.205</td>
                    </tr>
                    <tr>
                        <td>操作系统</td>
                        <td>LINUX</td>
                    </tr>
                    <tr>
                        <td>MySQL版本</td>
                        <td>5.6.62</td>
                    </tr>
                    <tr>
                        <td>上传限制</td>
                        <td>46GB</td>
                    </tr>
                    <tr>
                        <td>剩余空间</td>
                        <td>23123MB</td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
        <fieldset class="codics-admin-home-sjtj" style="padding: 0 20px 5px 20px;">
            <legend>开发相关</legend>
            <table class="table-sysinfo">
                <tbody>
                    <tr>
                        <td>系统版本</td>
                        <td>2022.05.12.01</td>
                    </tr>
                    <tr>
                        <td>服务器地址</td>
                        <td>124.221.115.205</td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { currentDate } from '@/utils/myutils';
import { adminInfo } from '@/api';
import { jumpTo } from "@/utils/myutils";
export default {
    data() {
        return {
            sjtjData: [
                {
                    id: 1,
                    title: '累计用户',
                    icon: 'el-icon-s-custom',
                    count: '*',
                    path: '/admin/user'
                },
                {
                    id: 2,
                    title: '商家认证',
                    icon: 'el-icon-s-check',
                    count: '*',
                    path: '/admin/apply'
                },
                {
                    id: 3,
                    title: '订单管理',
                    icon: 'el-icon-s-order',
                    count: '*',
                    path: '/admin/exorder'
                },
                {
                    id: 4,
                    title: '商品数量',
                    icon: 'el-icon-s-goods',
                    count: '*',
                    path: '/admin/product'
                },
                {
                    id: 5,
                    title: '卡密管理',
                    icon: 'el-icon-s-ticket',
                    count: '*',
                    path: '/admin/cdk'
                },
                {
                    id: 6,
                    title: '异常访问',
                    icon: 'el-icon-warning',
                    count: '*',
                    path: ''
                }
            ],
            isPhone:false
        }
    },
    mounted() {
        this.adminInfoFuc();

    },
    methods: {
        getCurrentTime() {
            return currentDate();
        },
        async adminInfoFuc() {
            let data = await adminInfo();
            if (data.code == 200) {
                this.sjtjData[0].count = data.ucount;
                this.sjtjData[3].count = data.scount;
                this.sjtjData[4].count = data.ccount;
            } else {
                this.$message.error(data.message);
            }
        },
        jumpToFuc(...data){
            jumpTo(this,...data)
        }
    },
    computed: {
        ...mapState(['userInfo'])
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-home-home {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 主页跑马灯公告 */
.codics-admin-home-pmd {
    height: 50px;
    width: 100%;
    position: relative;
    float: left;
    line-height: 20px;
    padding: 15px;
    box-sizing: border-box;
    background-color: #f8f8f8;
    border-left: 4px solid #E6A23C;
    margin-bottom: 10px;
}

/* 主页数据统计 */
.codics-admin-home-sjtj {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    border: 1px solid #DCDFE6;
    margin-bottom: 10px;
}

/* 主页数据统计卡片 */
.codics-admin-home-sjtj-card {
    height: 110px;
    width: 16.6666667%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    color: #333 !important;
}

/* 主页数据统计卡片盒子 */
.codics-admin-home-sjtj-box {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #f8f8f8;
    cursor: pointer;
    user-select: none;
}

/* 主页数据统计标题控件 */
.codics-admin-home-sjtj-title {
    height: 40px;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    padding: 0 10px 0 10px;
    line-height: 40px;
    font-size: 15px;
    color: #333;
}

/* 主页数据统计图标 */
.codics-admin-home-sjtj-icon {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

/* 主页数据统计数据 */
.codics-admin-home-sjtj-count {
    height: 36px;
    width: fit-content;
    position: absolute;
    line-height: 36px;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 0 10px 0 10px;
}

.table-sysinfo {
    line-height: 50px;
    width: 100%;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.codics-admin-home-home table,
.codics-admin-home-home td,
.codics-admin-home-home th {
    border-collapse: collapse;
    border-spacing: 10px;
    border: 1px solid #DDD;
    min-width: 45px;
}

.codics-admin-home-home td {
    padding-left: 10px;
}

.codics-admin-home-home table tr:nth-child(even) {
    background: #f8f8f8;
}

@media (max-width: 768px) {
    .codics-admin-home-sjtj-card {
        width: 50%;
    }
}
</style>