<template>
    <div class="codics-admin-apply-body">
        <div class="codics-admin-apply-control">
            <!-- 添加表单 -->
            <el-dialog title="添加商品类型" :visible.sync="addProductDialogVisibility" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="分类名称" :label-width="formLabelWidth">
                        <el-input v-model="addForm.l" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="商品父类" :label-width="formLabelWidth">
                        <el-cascader :options="productTypeList" clearable @change="handleSubCat"></el-cascader>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addProductDialogVisibility = false">取 消</el-button>
                    <el-button type="primary" @click="addProductTypeFuc">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-admin-apply-control-input" :placeholder="'请输入' + searchType" v-model="input"
                clearable>
                <el-select style="width: 40px !important;" v-model="searchType" slot="prepend" placeholder="">
                    <el-option label="ID" value="ID"></el-option>
                    <el-option label="名称" value="名称"></el-option>
                    <el-option label="详情" value="详情"></el-option>
                    <el-option label="厂商" value="厂商"></el-option>
                </el-select>
            </el-input>
            <el-button class="codics-admin-apply-control-search" type="warning" icon="el-icon-search">搜索</el-button>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteApplysFuc">
                <el-button class="codics-admin-apply-control-search" slot="reference" type="danger"
                    icon="el-icon-delete">删除</el-button>
            </el-popconfirm>
            <el-dropdown split-button type="primary" @click="examineApplyFuc(null, 1);"
                @command="examineApplyFuc(null, 2)">
                批量审核
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>批量拒绝</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <el-table stripe class="codics-admin-apply-table" :data="applyList" border style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="uid" label="账号" width="180">
                <template slot-scope="scope">
                    <el-image
                        style="height: 35px;width: 35px;top: 2.5px;margin-right: 10px;position: relative;float: left;"
                        :src="(scope.row.himg == null || scope.row.himg == '') ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png' : scope.row.himg"
                        fit="fill">
                    </el-image>
                    <div
                        style="line-height: 42px;width: fit-content;position: relative;float: left;max-width: 75%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                        {{ scope.row.account.nickname }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="img" label="资料" width="180">
                <el-carousel slot-scope="scope" height="100px" width="150px" indicator-position="none">
                    <el-carousel-item v-for="(item, index) in scope.row.img.split(',')" :key="index">
                        <el-image style="width: 100%; height: 100%" :src="'https://cdn.codics.cn/' + item" fit="cover"
                            :preview-src-list="scope.row.img.split(',').map(item2 => 'https://cdn.codics.cn/' + item2)"></el-image>
                    </el-carousel-item>
                </el-carousel>
            </el-table-column>
            <el-table-column prop="com" label="公司" width="180">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="80">
            </el-table-column>
            <el-table-column prop="phone" label="电话" width="110">
            </el-table-column>
            <el-table-column prop="province" label="地址" width="180">
                <template slot-scope="scope">
                    {{scope.row.province + ' ' + scope.row.city + ' ' + scope.row.area + ' ' + scope.row.other}}
                </template>
            </el-table-column>
            <el-table-column prop="createtime" label="申请时间" width="110">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="80"
                :filters="[{ text: '待审核', value: 0 }, { text: '已通过', value: 1 }, { text: '已拒绝', value: 2 }]"
                :filter-method="stateFilter" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? 'info' : (scope.row.state == 1 ? 'success' : 'danger')"
                        disable-transitions>{{ scope.row.state == 0 ? '待审核' :
                (scope.row.state == 1 ? '已通过' : '已拒绝') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-dropdown v-show="scope.row.state == 0" split-button type="primary" size="mini"
                        @click="examineApplyFuc([scope.row.id], 1);" @command="examineApplyFuc([scope.row.id], 2)"
                        style="margin-right: 10px;">
                        通过申请
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click="examineApplyFuc([scope.row.id], 2);">拒绝申请</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm title="这是一段内容确定删除吗？" @confirm="deleteApplysFuc([scope.row.id]);">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-apply-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
            :page-size="dataSize" layout="total, sizes, prev, pager, next, jumper" :total="maxSize">
        </el-pagination>
    </div>
</template>

<script>
import { getProductTypeList, addProductType, listApplys, examineApply, deleteApplys } from '@/api';
export default {
    data() {
        return {
            addProductDialogVisibility: false,
            addForm: {
                l: '',
                p: 0,
            },
            formLabelWidth: '120px',
            searchType: '名称',
            currentPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            applyList: [],
            shshVisible: [],
            selectData: []
        }
    },
    mounted() {

        this.listApplysData();

    },
    methods: {
        async listApplysData(key) {

            let data = await listApplys({ pge: this.currentPage, psz: this.dataSize, 'key': key });

            if (data.code == 200) {

                this.applyList = data.data;

                this.maxSize = data.count;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.listApplysData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.listApplysData();
        },
        stateFilter(v, r, c) {

            return r.state === v;

        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addProductTypeFuc() {

            if (this.addForm.l == '') {

                this.$notify({
                    title: '警告',
                    message: '分类名称不能为空！',
                    type: 'warning'
                });

                return true;

            }

            let data = await addProductType(this.addForm);

            if (data.code == 200) {

                this.$message({
                    message: '添加商品分类成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async examineApplyFuc(ids, state) {

            if (ids == null) {

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.uid;

                });

            }

            if (ids.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要审核的数据！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await examineApply({ 'ids': ids, state: state });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: '数据审核成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }

        },
        async deleteApplysFuc(t) {
            if (t == null) {
                if (this.selectData.length == 0) {

                    this.$message({
                        showClose: true,
                        message: '请先选中要删除的数据！',
                        type: 'warning'
                    });

                    return true;

                }
                t = [];
                this.selectData.forEach(item => {

                    t[t.length] = item.id;

                });
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await deleteApplys({ ids: t });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据删除成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }

        },
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-apply-body {
    height: fit-content;
    width: 100%;
    min-height: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-apply-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-admin-apply-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-apply-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-apply-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-apply-table-page {
    position: relative;
    float: right;
    right: 0;
}
</style>