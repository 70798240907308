<template>
    <div class="codics-shop-body">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'ShopIndex',
}
</script>

<style>
/* 商城主页 */
.codics-shop-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    box-sizing: border-box;
    overflow: hidden;
}
</style>