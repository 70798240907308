<template>
    <div class="codics-admin-lunbo-body">
        <div class="codics-admin-lunbo-control">
            <el-button class="codics-admin-lunbo-control-search" type="success" icon="el-icon-plus"
                @click="addLunboVisible = true;">添加</el-button>
            <!-- 添加表单 -->
            <el-dialog title="添加轮播" :visible.sync="addLunboVisible" width="80%">
                <el-form :model="addForm">
                    <el-form-item label="轮播标题" :label-width="formLabelWidth">
                        <el-input v-model="addForm.title" autocomplete="off" placeholder="请输入轮播标题"></el-input>
                    </el-form-item>
                    <el-form-item label="轮播内容" :label-width="formLabelWidth">
                        <el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入轮播内容"
                            v-model="addForm.code"></el-input>
                    </el-form-item>
                    <el-form-item label="轮播状态" :label-width="formLabelWidth">
                        <el-select v-model="addForm.state" placeholder="请选择">
                            <el-option label="上架" :value=0></el-option>
                            <el-option label="下架" :value=1></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addLunboVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addLunboFuc">确 定</el-button>
                </div>
            </el-dialog>
            <el-input class="codics-admin-lunbo-control-input" :placeholder="'请输入' + searchType" v-model="searchTxt"
                clearable>
                <el-select style="width: 40px !important;" v-model="searchType" slot="prepend" placeholder="">
                    <el-option label="ID" value="ID"></el-option>
                    <el-option label="名称" value="名称"></el-option>
                    <el-option label="详情" value="详情"></el-option>
                    <el-option label="厂商" value="厂商"></el-option>
                </el-select>
            </el-input>
            <el-button class="codics-admin-lunbo-control-search" type="warning" icon="el-icon-search">搜索</el-button>
            <el-popconfirm :title="'您确定要删除当前选中的' + selectData.length + '条数据吗？'" @confirm="deleteLunbosFuc();">
                <el-button class="codics-admin-lunbo-control-search" slot="reference" type="danger"
                    icon="el-icon-plus">删除</el-button>
            </el-popconfirm>
        </div>
        <el-table stripe class="codics-admin-lunbo-table" :data="lunboData" border style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="title" label="标题" width="180">
            </el-table-column>
            <el-table-column prop="code" label="内容" width="250" class-name="codics-admin-lunbo-table-nr">
            </el-table-column>
            <el-table-column prop="state" label="状态" width="70"
                :filters="[{ text: '上架', value: 0 }, { text: '下架', value: 1 }]" :filter-method="stateFilter"
                filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.state == 0 ? 'success' : 'warning'" disable-transitions>{{ scope.row.state
                    == 0 ? '上架' : '下架' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="right" label="操作" min-width="150">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        style="margin-right: 10px;">编辑</el-button>
                    <el-popconfirm title="您确定要删除当前数据吗？" @confirm="deleteLunbosFuc([scope.row.id])">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="codics-admin-lunbo-table-page" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]"
            :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="maxSize">
        </el-pagination>
        <el-dialog title="修改轮播" :visible.sync="editLunboVisible" width="80%">
            <el-form :model="addForm">
                <el-form-item label="轮播标题" :label-width="formLabelWidth">
                    <el-input v-model="addForm.title" autocomplete="off" placeholder="请输入轮播标题"></el-input>
                </el-form-item>
                <el-form-item label="轮播内容" :label-width="formLabelWidth">
                    <el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="请输入轮播内容"
                        v-model="addForm.code"></el-input>
                </el-form-item>
                <el-form-item label="轮播状态" :label-width="formLabelWidth">
                    <el-select v-model="addForm.state" placeholder="请选择">
                        <el-option label="上架" :value=0></el-option>
                        <el-option label="下架" :value=1></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addLunboVisible = false">取 消</el-button>
                <el-button type="primary" @click="editLunboFuc();">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getProductTypeList, addProductType, addLunbo, deleteLunbos, updateLunbo, listLunbo } from '@/api';
export default {
    data() {
        return {
            addLunboVisible: false,
            editLunboVisible: false,
            addForm: {
                title: '',
                code: '',
                state: 0
            },
            formLabelWidth: '120px',
            searchType: '名称',
            searchTxt:'',
            currentPage: 1,
            tableData: [],
            multipleSelection: [],
            maxSize: 0,
            dataSize: 10,
            lunboData: [],
            selectData: [],
            nowIndex: null
        }
    },
    mounted() {

        this.listLunboFuc();

    },
    methods: {
        stateFilter(v, r, c) {

            return r.state === v;

        },
        async listLunboFuc(key) {

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await listLunbo({ pge: this.currentPage, psz: this.dataSize, 'key': key });

            loading.close();

            if (data.code == 200) {

                this.lunboData = data.data;

            } else {

                this.$message.error(data.message);

            }

        },
        handleSizeChange(val) {
            this.dataSize = val;
            this.getUserListData(this.currentPage, val);
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserListData(val, this.dataSize);
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        sexFilter(v, r, c) {

            return r.sex === v;

        },
        handleSubCat(value) {
            // 获取value值
            this.addForm.p = value.length == 0 ? 0 : value[value.length - 1];
            // 获取label 值
            // console.log(this.$refs["refSubCat"].getCheckedNodes()[0].label)
        },
        async addLunboFuc() {

            if (this.addForm.name == '' || this.addForm.code == '') {

                this.$notify({
                    title: '警告',
                    message: '轮播标题及内容不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await addLunbo(this.addForm);

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '添加主页轮播成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

        },
        async deleteLunbosFuc(ids) {

            if (ids == null) {

                ids = [];

                this.selectData.forEach(item => {

                    ids[ids.length] = item.id;

                });

            }

            if (ids.length == 0) {

                this.$message({
                    showClose: true,
                    message: '请先选中要删除的数据！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let data = await deleteLunbos({ 'ids': ids });
            loading.close();
            if (data.code == 200) {
                this.$message({
                    message: data.data + '条数据成功！',
                    type: 'success'
                });
                setTimeout(() => {

                    window.location.reload();

                }, 300);
            } else {
                this.$message.error(data.message);
            }
        },
        handleEdit(idx, row) {
            this.nowIndex = row.id;
            this.addForm.title = row.title;
            this.addForm.code = row.code;
            this.addForm.state = row.state;
            this.editLunboVisible = true;
        },
        async editLunboFuc() {

            if (this.addForm.name == '' || this.addForm.code == '' || this.nowIndex==null) {

                this.$notify({
                    title: '警告',
                    message: '选中的数据以及轮播标题及内容不能为空！',
                    type: 'warning'
                });

                return true;

            }

            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            let data = await updateLunbo({id:this.nowIndex,...this.addForm});

            loading.close();

            if (data.code == 200) {

                this.$message({
                    message: '修改主页轮播成功！',
                    type: 'success'
                });

                setTimeout(() => {

                    window.location.reload();

                }, 300);

            } else {

                this.$message.error(data.message);

            }

            this.nowIndex = null;

        },
    }
}
</script>

<style>
/* 后台管理端主页 */
.codics-admin-lunbo-body {
    height: 100%;
    width: 100%;
    position: relative;
    float: left;
    background: #FFF;
    box-sizing: border-box;
    padding: 20px;
}

/* 后台管理页面主页 */
.codics-admin-lunbo-control {
    height: fit-content;
    width: 100%;
    position: relative;
    float: left;
}

/* 后台管理页面主页控制栏 */
.codics-admin-lunbo-control-input {
    width: 200px !important;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
}

/* 后台管理页面控制栏搜索按钮 */
.codics-admin-lunbo-control-search {
    position: relative;
    float: left;
    margin-left: 0px !important;
    margin-right: 20px !important;
}

/* 表格控件 */
.codics-admin-lunbo-table {
    height: fit-content;
    width: 100% !important;
    position: relative;
    float: left;
    margin-bottom: 20px;
}

/* 表格分页控件 */
.codics-admin-lunbo-table-page {
    position: relative;
    float: right;
    right: 0;
}

.codics-admin-lunbo-table-nr div {
    white-space: nowrap !important;
    /* 防止文本换行 */
    overflow: hidden !important;
    /* 隐藏超出容器的内容 */
    text-overflow: ellipsis !important;
}
</style>