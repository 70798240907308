<template>
  <div class="codics-firm-body">
    <div class="codics-firm-header">
      <el-image class="codics-firm-header-icon"
        :src="(firmInfo.icon == '' || firmInfo.icon == null) ? logo : firmInfo.icon" fit="fit"
        @click="changeMenuBar"></el-image>
      <el-breadcrumb class="codics-firm-header-path" separator="/">
        <el-breadcrumb-item v-for="(item, index) in breadList" v-show="item.name != ''" :key="index"
          :to="index == breadList.length - 1 ? '' : item.path">{{
          item.name }}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-menu :default-active="activeIndex" class="codics-firm-top-bar-menu el-menu-demo" mode="horizontal"
        @select="handleSelect" active-text-color="#212220" router>
        <el-submenu index="1">
          <template slot="title">菜单</template>
          <MenuTree :menuList="menuList"></MenuTree>
        </el-submenu>
      </el-menu>
      <el-dropdown v-if="userInfo != null" class="codics-firm-header-himg" @command="handleCommand">
        <el-avatar style="margin-top: 13px; cursor: pointer" :size="34"
          src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="grzx">个人中心</el-dropdown-item> -->
          <!-- <el-dropdown-item command="xtsz">系统设置</el-dropdown-item> -->
          <el-dropdown-item command="tcdl">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-badge v-if="userInfo != null" :value="unRead" class="item codics-firm-header-mess">
        <i class="el-icon-message-solid" @click="messageDialog = true"></i>
      </el-badge>
      <!-- <el-input class="codics-firm-header-search" placeholder="请输入搜索内容..." suffix-icon="el-icon-search"
        v-model="searchTxt"></el-input> -->
    </div>
    <div class="codics-firm-left-and-main-box">
      <div class="codics-firm-left-bar" :style="{ width: leftWidth }">
        <el-col class="codics-firm-left-bar-menu" :span="12">
          <el-menu :default-active="nowIndex" class="el-menu-vertical-demo" style="border: none !important"
            @select="handleSelect" text-color="#212220" active-text-color="#E6A23C" router>
            <!-- @close="handleClose" -->
            <MenuTree :menuList="menuList"></MenuTree>
          </el-menu>
        </el-col>
      </div>
      <div class="codics-firm-main-and-bottom" :style="{ width: 'calc(100% - ' + leftWidth + ')' }">
        <div class="codics-firm-body-main">
          <router-view @check-mem="checkMem"></router-view>
        </div>
        <div class="codics-firm-bottom-bar">
          Copyright&nbsp;©&nbsp;2024&nbsp;华远创智&nbsp;
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index/" rel="external nofollow noreferrer"
            style="color: #333">鲁ICP备2023021315号-3</a><a id="adminBottomTxt">&nbsp;&nbsp;本站已稳定运行了 <a id="aaa"></a> 天</a>
        </div>
      </div>
    </div>
    <el-drawer class="codics-firm-mess" title="我的消息" :visible.sync="messageDialog" :direction="messDirection"
      :before-close="handleClose" :size="messDirection == 'btt' ? 'calc(100% - 60px)' : '30%'">
      <!-- <div class="codics-firm-mess-control" :style="{ width: messDirection == 'btt' ? '100%' : '30%' }">
        <el-link type="warning" style="
            text-align: center;
            padding: 0 20px 0 20px;
            position: relative;
            float: left;
          " :underline="false">查看更多</el-link>
        <el-link type="warning" style="
            text-align: center;
            padding: 0 20px 0 20px;
            position: relative;
            float: right;
          " :underline="false">消息设置</el-link>
      </div> -->
      <div v-infinite-scroll="loadMess" style="height: calc(100% - 50px); overflow: auto">
        <div class="codics-firm-mess-box" v-for="(item, index) in messData" :key="index">
          <div class="codics-firm-mess-card">
            <div class="codics-firm-mess-top-bar">
              <el-avatar v-show="item.account != null" shape="square" :size="26" style="
                  top: 50%;
                  transform: translateY(-50%);
                  position: relative;
                  float: left;
                  margin-right: 10px;
                " :src="item.account == null || item.account.himg == null
          ? 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'
          : item.account.himg
          "></el-avatar>
              <div class="codics-firm-mess-top-nick">
                {{ item.account == null ? "系统消息" : item.account.nickname }}
              </div>
              <el-dropdown style="
                  height: 50px;
                  width: 50px;
                  position: relative;
                  float: right;
                  cursor: pointer;
                ">
                <div class="codics-firm-mess-top-more">
                  <i class="el-icon-more codics-firm-mess-top-more-icon" style="position: absolute; margin: auto"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>删除消息</el-dropdown-item>
                  <el-dropdown-item>查看详情</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="codics-firm-mess-body" v-html="item.content"></div>
            <div class="codics-firm-mess-top-bar codics-firm-mess-bottom-bar">
              <div class="codics-firm-mess-bottom-time">
                2024-03-12 11:11:11
              </div>
              <el-button type="text" class="codics-firm-mess-detail">查看详情<i
                  class="el-icon-arrow-right el-icon--right"></i></el-button>
            </div>
          </div>
        </div>
        <div style="
            height: 50px;
            width: 100%;
            position: relative;
            float: left;
            line-height: 50px;
            text-align: center;
            color: #aaa;
            font-size: 15px;
          ">
          没有更多消息了~
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { jumpTo } from "@/utils/myutils";
import MenuTree from "@/components/MenuTree";
import { getMyMess, adminInfo, listMenu } from "@/api";
import { getFirmInfo } from "@/api";
export default {
  data() {
    return {
      breadList: [],
      activeIndex: "0",
      logo: "https://cdn.codics.cn/huayuan/img/PicsArt_03-25-05.35.37.png",
      leftWidth: "200px",
      nowIndex: "1",
      messData: [],
      messPage: 0,
      htmlData:
        '<div style="height:100px;width:100%;text-align:center;line-height:100px;">99.99</div>',
      menuList: [],
      //   {
      //     id: "101",
      //     parentid: "0",
      //     name: "员工管理",
      //     icon: "el-icon-user-solid",
      //     children: [
      //       {
      //         id: "103",
      //         parentid: "101",
      //         name: "员工管理",
      //         icon: "",
      //         remark: "firm/staff",
      //       },
      //       {
      //         id: "102",
      //         parentid: "101",
      //         name: "角色管理",
      //         icon: "",
      //         remark: "firm/role",
      //       },
      //     ]
      //   },
      //   {
      //     id: "201",
      //     parentid: "0",
      //     name: "商品管理",
      //     icon: "el-icon-s-goods",
      //     children: [
      //       {
      //         id: "202",
      //         parentid: "201",
      //         name: "商品管理",
      //         icon: "",
      //         remark: "firm/product",
      //       },
      //       {
      //         id: "221",
      //         parentid: "201",
      //         name: "品牌管理",
      //         icon: "",
      //         remark: "firm/productbrand",
      //       },
      //       {
      //         id: "231",
      //         parentid: "201",
      //         name: "卡密管理",
      //         icon: "",
      //         remark: "firm/cdk",
      //       },
      //       {
      //         id: "241",
      //         parentid: "201",
      //         name: "订单管理",
      //         icon: "",
      //         remark: "firm/exorder",
      //       },
      //       {
      //         id: "251",
      //         parentid: "201",
      //         name: "领用记录",
      //         icon: "",
      //         remark: "firm/receive",
      //       },
      //       // {
      //       //   id: "251",
      //       //   parentid: "201",
      //       //   name: "抽奖管理",
      //       //   icon: "",
      //       //   remark: "firm/lottery",
      //       // },
      //     ],
      //   },
      //   {
      //     id: "301",
      //     parentid: "0",
      //     name: "系统设置",
      //     icon: "el-icon-s-tools",
      //     remark: "firm/set"
      //   }
      // ],
      menuData: [],
      messCount: 0,
      unRead: 0,
      messageDialog: false,
      screenWidth: 0,
      messDirection: false,
      adminInfoData: [],
      searchTxt: "",
      firmInfo: {}
    };
  },
  mounted() {
    this.breadList = this.$route.matched;
    this.getFirmInfoFuc();
    var urodz = new Date("02/20/2024");
    var now = new Date();
    var ile = now.getTime() - urodz.getTime();
    var dni = Math.floor(ile / (1000 * 60 * 60 * 24));
    document.getElementById("aaa").innerHTML = dni;
    //未登录直接跳转
    setTimeout(() => {
      if (this.userInfo == null) {
        jumpTo(this, "登录", { url: "后台" }, {});
      }
    }, 100);
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    this.loadMess();
    this.listMenuFuc();

  },
  methods: {
    async listMenuFuc() {

      var t = await this.userInfo?.user?.ram?.mid.split(',').map(Number);

      let data = await listMenu({});

      if (data.code == 200) {

        if (this.userInfo?.user?.uar?.uid != this.userInfo?.user?.uar?.sid) {

          this.menuList = await this.initMenu(data.data, t);

        } else {

          this.menuList = await this.initMenu2(data.data, t);


        }

      } else {

        this.$message.error(data.message);

      }

    },
    async getFirmInfoFuc() {

      if (this.userInfo == null) {

        this.$message.error("请先登录！");

        return true;

      }

      let data = await getFirmInfo({ id: this.userInfo.user.uar.sid });

      if (data.code == 200) {

        data.data.icon = data.data.icon;

        this.firmInfo = data.data;

      } else {

        this.$message.error(data.message);

      }

    },
    changeMenuBar() {
      this.leftWidth = this.leftWidth == "200px" ? "0px" : "200px";
    },
    handleCommand(command) {
      // 接收头像菜单点击事件

      switch (command) {
        case "grzx":
          jumpTo(this, "个人中心", {}, {});

          break;

        case "tcdl": // 退出登录
          this.$store.dispatch("logout");

          jumpTo(this, "登录", { url: "admin" }, {});

          break;
      }
    },
    handleSelect(key, keyPath) {
      switch (keyPath.length) {
        case 1:
          // console.log(this.menuList[keyPath[0]].remark);

          break;

        case 2:
          // console.log(this.menuList[keyPath[0]].children);

          // console.log(keyPath[1]);

          break;
      }
    },
    async loadMess() {
      this.messPage++;

      let data = await getMyMess({ pge: this.messPage, psz: 10 });

      if (data.code == 200) {
        if (data.count != this.messData.length) {
          Array.prototype.push.apply(this.messData, data.data);

          this.messCount = data.Count;

          this.unRead = data.unread;
        }
      } else {
        this.$message.error(data.message);
      }
    },
    handleClose(done) {
      // this.messPage = 0;
      // this.messCount = 0;
      // this.messData = [];
      done();
    },
    getMatched() {
      this.breadList = this.$route.matched;
    },
    async initMenu(d, t) {

      for (var i = d.length - 1; i >= 0; i--) {

        if (d[i].children != null) {

          let newT = await this.initMenu(d[i].children, t);

          if (newT == null || newT.length == 0) {

            let idx = d.indexOf(d[i]);

            if (idx > -1) {

              d.splice(idx, 1);

            }

          } else {

            d[i].children = newT;

          }

        } else {

          if (d[i].remark == '') {

            let idx = d.indexOf(d[i]);

            if (idx > -1) {

              d.splice(idx, 1);

            }

          } else {

            t = t == null ? [] : t;

            if (!t.includes(d[i].id)) {

              let idx = d.indexOf(d[i]);

              if (idx > -1) {

                d.splice(idx, 1);

              }

            }

          }

        }

        if (d[i]) {

          d[i].id = d[i].id.toString();

          d[i].parentid = d[i].parentid.toString();

        }

      }

      return d

    },
    async initMenu2(d, t) {

      for (var i = d.length - 1; i >= 0; i--) {

        if (d[i].children != null) {

          let newT = await this.initMenu2(d[i].children, t);

          d[i].children = newT;

        } else {

          if (d[i].remark == '') {

            let idx = d.indexOf(d[i]);

            if (idx > -1) {

              d.splice(idx, 1);

            }

          }

        }

        d[i].id = d[i].id.toString();

        d[i].parentid = d[i].parentid.toString();

      }

      return d

    },
    checkMem() {
      let pageId = this.$route.matched[this.$route.matched.length - 1].meta.pageId;
      if (pageId != null && pageId!=-1 && this.userInfo?.user?.uar?.rid != -1 && !this.userInfo?.user?.ram?.mid.split(',').map(Number).includes(pageId)) {

        this.$message({
          message: '帐号没有访问当前页面的权限！',
          type: 'warning',
          showClose: true
        });

        jumpTo(this, "商户首页", {}, {});

      }
    }
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  components: {
    MenuTree,
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        if (val <= 768) {
          this.messDirection = "btt";
        } else {
          this.messDirection = "rtl";
        }
      },
      immediate: true,
      deep: true,
    },
    userInfo(to, from) {
      this.listMenuFuc();
    },
    $route(to, from) {
      this.breadList = this.$route.matched;
    },
  },
};
</script>

<style>
/* 管理端网页内容 */
.codics-firm-body {
  height: 100%;
  width: 100%;
  position: relative;
  float: left;
  box-sizing: border-box;
  overflow: hidden;
}

/* 管理端网页顶栏 */
.codics-firm-header {
  height: 60px;
  width: 100%;
  position: relative;
  float: left;
  background: #fff;
  height: 60px;
  padding-right: 0px !important;
}

/* 管理端顶栏LOGO */
.codics-firm-header-icon {
  height: 100%;
  width: 125px;
  position: relative;
  float: left;
  cursor: pointer;
  user-select: none;
  left: 20px;
}

/* 管理端顶栏路径面包屑 */
.codics-firm-header-path {
  height: 100%;
  width: fit-content;
  position: relative;
  float: left;
  line-height: 60px !important;
  text-align: center;
  left: 60px;
}

/* 管理端顶栏导航菜单 */
.codics-firm-top-bar-menu {
  height: 100%;
  width: fit-content;
  position: relative;
  float: left;
  left: 20px;
  display: none;
  font-weight: bold;
}

/* 管理端顶栏搜索框 */
.codics-firm-header-search {
  width: 200px !important;
  position: relative;
  float: right;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 30px;
}

/* 管理端顶栏用户头像 */
.codics-firm-header-himg {
  height: 100%;
  width: 60px;
  position: relative;
  float: right;
  cursor: pointer;
}

/* 管理端顶栏消息提示按钮 */
.codics-firm-header-mess {
  position: relative;
  float: right;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 30px;
  cursor: pointer;
}

/* 管理端其他内容盒子 */
.codics-firm-left-and-main-box {
  height: calc(100% - 60px);
  width: 100%;
  position: relative;
  float: left;
}

/* 管理端侧栏 */
.codics-firm-left-bar {
  height: 100%;
  width: 200px;
  position: relative;
  float: left;
  transition: 0.4s;
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
}

/* 管理端侧栏菜单 */
.codics-firm-left-bar-menu {
  width: 100% !important;
  font-size: 14px;
  border: none !important;
  user-select: none;
}

/* 管理端主体跟底栏部分 */
.codics-firm-main-and-bottom {
  height: 100%;
  width: 100%;
  position: relative;
  float: left;
  transition: 0.4s;
}

/* 管理端网页主体部分 */
.codics-firm-body-main {
  height: calc(100% - 60px);
  width: 100%;
  position: relative;
  float: left;
  box-sizing: border-box;
  padding: 10px !important;
  background: #f8f8f8 !important;
  overflow-y: scroll;
}

/* 管理端底栏 */
.codics-firm-bottom-bar {
  text-align: right;
  line-height: 60px;
  padding-right: 20px;
  font-size: 14px;
  background: #fff !important;
}

/* 消息盒子 */
.codics-firm-mess header {
  margin-bottom: 20px !important;
}

/* 管理端信息控制栏 */
.codics-firm-mess-control {
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  line-height: 50px;
}

/* 消息盒子 */
.codics-firm-mess-box {
  height: fit-content;
  width: 100%;
  position: relative;
  float: left;
  box-sizing: border-box;
  padding: 10px 20px;
}

/* 消息盒子卡片 */
.codics-firm-mess-card {
  height: fit-content;
  width: 100%;
  position: relative;
  float: left;
  background: #f8f8f8;
}

/* 消息盒子卡片顶栏 */
.codics-firm-mess-top-bar {
  height: 50px;
  width: 100%;
  position: relative;
  float: left;
  box-sizing: border-box;
  padding-left: 10px;
  border-bottom: 1px solid #eee;
}

/* 消息盒子顶栏昵称 */
.codics-firm-mess-top-nick {
  height: 100%;
  width: fit-content;
  position: relative;
  float: left;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  color: #333;
}

/* 消息盒子顶栏更多按钮 */
.codics-firm-mess-top-more {
  height: 100%;
  width: 50px;
  position: relative;
  float: right;
}

.codics-firm-mess-top-more-icon {
  height: fit-content;
  width: fit-content;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* 消息盒子消息控件 */
.codics-firm-mess-body {
  height: fit-content;
  width: 100%;
  position: relative;
  float: left;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  color: #888;
}

/* 消息盒子底栏 */
.codics-firm-mess-bottom-bar {
  border-bottom: none;
  border-top: 1px solid #eee;
}

/* 消息盒子底栏消息时间 */
.codics-firm-mess-bottom-time {
  height: 100%;
  width: fit-content;
  position: relative;
  float: left;
  line-height: 50px;
  text-align: center;
  font-size: 15px;
  color: #333;
}

/* 消息盒子底栏详情按钮 */
.codics-firm-mess-detail {
  position: relative;
  float: right;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

@media (max-width: 768px) {
  .codics-firm-header-path {
    display: none;
  }

  .codics-firm-top-bar-menu {
    display: block !important;
  }

  .codics-firm-left-bar {
    width: 0 !important;
  }

  .codics-firm-main-and-bottom {
    width: 100% !important;
  }

  .el-submenu__title {
    border-width: 0px !important;
    border-color: #fff !important;
  }

  #adminBottomTxt {
    display: none !important;
  }
}
</style>